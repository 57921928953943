import { Button } from "primereact/button";
import { Component } from "react";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import VwContadorDocumento from 'lirida-back-service/Servicos/VwContadorDocumento/VwContadorDocumento';

export default class OrdemServico extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        pessoaUsuario: new Pessoa(),
        listaDocumento: [],
        listaVwContadorDocumento: [],
        carregando: false,
        pessoa: new Pessoa(),
        listaPessoa: [],
        conteudo: '',
        dataCadastroInicial: undefined,
        dataCadastroFinal: undefined,
        dataEventoInicial: undefined,
        dataEventoFinal: undefined,
        dataAceiteClienteInicial: undefined,
        dataAceiteClienteFinal: undefined,
        dataEnvioPropostaInicial: undefined,
        dataEnvioPropostaFinal: undefined,
        pago: undefined,
        codigoOs: null,
        vwContadorDocumento: new VwContadorDocumento(),
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilParceiro;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaPessoa = retorno.objeto;

        retorno = await this.documentoService.listarContadorDocumento(this.constante.seqUsuario, this.constante.seqTipoDocumentoOrdemServico);
        let listaVwContadorDocumento = retorno.objeto;

        this.setState({
            listaPessoa: listaPessoa,
            listaVwContadorDocumento: listaVwContadorDocumento,
        });
        
    }

    async listar() {
        console.log("entrou");
        let listaDocumento = [];
        let listaPesquisa = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoOrdemServico;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        if (this.state.pessoa._seqPessoa) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_usuario_vendedor';
            pesquisa.conteudo = this.state.pessoa._seqPessoa;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.conteudo) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.nome';
            pesquisa.conteudo = this.state.conteudo;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.codigoOs) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.codigo';
            pesquisa.conteudo = this.state.codigoOs;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'NUMERO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataAceiteClienteInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd2';
            pesquisa.conteudo = this.state.dataAceiteClienteInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataAceiteClienteFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd2';
            pesquisa.conteudo = this.state.dataAceiteClienteFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEnvioPropostaInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd1';
            pesquisa.conteudo = this.state.dataEnvioPropostaInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEnvioPropostaFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd1';
            pesquisa.conteudo = this.state.dataEnvioPropostaFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.pago === 'S') {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.pago';
            pesquisa.conteudo = this.state.pago
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.pago === 'N') {
            pesquisa = new Pesquisa();
            pesquisa.campo = ' AND documento.pago is null';
            pesquisa.conteudo = this.state.pago
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'MANUAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.vwContadorDocumento._seqTipoDocumentoProcesso) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_tipo_documento_processo';
            pesquisa.conteudo = this.state.vwContadorDocumento._seqTipoDocumentoProcesso
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }
        // 

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumento = retorno.objeto;
        // 

        for (let i = 0; i < listaDocumento.length; i++) {
            listaDocumento[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].dataCadastro);
            listaDocumento[i].dataProgramada = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].dataProgramada);
            listaDocumento[i].tagd1 = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].tagd1);
            listaDocumento[i].tagd2 = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].tagd2);
        }

        this.setState({
            carregando: false,
            listaDocumento: listaDocumento,
        })
    }

    async exportarExcel() {
        let lista = [];
        let listaDocumento = this.state.listaDocumento;

        for (let i = 0; i < listaDocumento.length; i++) {
            let item = {};

            item["Ordem de Serviço"] = listaDocumento[i]._codigo;
            item["Número Contrato"] = listaDocumento[i].tags0;
            item["Parceiro"] = listaDocumento[i]._vendedorNome;
            item["Nome do Cliente"] = listaDocumento[i]._pessoaNome;
            item["Email do Cliente"] = listaDocumento[i]._pessoaEmail;
            item["Data do Evento"] = listaDocumento[i].dataProgramada;
            item["Data de Envio para Receber Propostas"] = listaDocumento[i].tagd1;
            item["Processo"] = listaDocumento[i]._tipoDocumentoProcessoNome;
            item["Valor da Ordem de Serviço"] = listaDocumento[i].valorTotal;
            item["Data de Cadastro"] = listaDocumento[i].dataCadastro;

            lista.push(item);
        }
        this.util.exportarExcel(lista, "Lista de Ordens de Serviço ( OneWork )")
    }

    render() {
        return <div>
            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Ordens de Serviço
                    </h1>
                </div>


                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: 'var(--primary-color)' }} /> Filtros
                        </h3>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 20 }}>
                            <div style={{ width: "100%" }}>
                                <label>Parceiro</label><br />
                                <Dropdown
                                    filter
                                    style={{ width: "100%" }}
                                    options={[
                                        { ...new Pessoa(), nome: 'Todos' },
                                        ...this.state.listaPessoa
                                    ]}
                                    optionLabel="nome"
                                    onChange={pEvento => this.setState({
                                        pessoa: pEvento.value
                                    })}
                                    value={this.state.pessoa} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Nome do Cliente</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudo: pEvento.target.value
                                    })}
                                    value={this.state.conteudo} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>N° da OS</label><br />
                                <InputText
                                    type="number"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        codigoOs: pEvento.target.value
                                    })}
                                    value={this.state.codigoOs} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Pago</label><br />
                                <Dropdown
                                    style={{ width: "100%" }}
                                    options={[
                                        { label: 'Todos', value: '' },
                                        { label: 'Pago', value: 'S' },
                                        { label: 'Não pago', value: 'N' },
                                    ]}
                                    onChange={pEvento => this.setState({
                                        pago: pEvento.value
                                    })}
                                    value={this.state.pago} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroInicial: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroFinal: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do evento inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEventoInicial: pEvento.value,
                                    })}
                                    value={this.state.dataEventoInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do evento final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEventoFinal: pEvento.value,
                                    })}
                                    value={this.state.dataEventoFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do aceite do cliente inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataAceiteClienteInicial: pEvento.value,
                                    })}
                                    value={this.state.dataAceiteClienteInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do aceite do cliente final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataAceiteClienteFinal: pEvento.value,
                                    })}
                                    value={this.state.dataAceiteClienteFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do envio da proposta inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEnvioPropostaInicial: pEvento.value,
                                    })}
                                    value={this.state.dataEnvioPropostaInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do envio da proposta final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEnvioPropostaFinal: pEvento.value,
                                    })}
                                    value={this.state.dataEnvioPropostaFinal} />
                            </div>

                            <div style={{ width: "100%" }}>
                                <label>Processos</label><br />
                                <Dropdown
                                    options={[
                                        { ...new VwContadorDocumento(), _tipoDocumentoProcessoNome: 'Todos' },
                                        ...this.state.listaVwContadorDocumento
                                    ]}
                                    optionLabel="_tipoDocumentoProcessoNome"
                                    onChange={pEvento => this.setState({
                                        vwContadorDocumento: pEvento.value
                                    })}
                                    value={this.state.vwContadorDocumento}
                                    style={{ width: '100%', }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            label="Buscar"
                            onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    value={this.state.listaDocumento}
                    header={
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <Button
                                type="button"
                                icon="pi pi-file-excel"
                                onClick={() => this.exportarExcel()}
                                className="p-button-success mr-2"
                                data-pr-tooltip="XLS" />
                        </div>
                    }>
                    <Column
                        header="Ordem de serviço"
                        field="_codigo"
                        sortable />
                    <Column
                        sortable
                        header="Número contrato"
                        body={pDocumento => <a
                            href={"/administrativo/ordem_de_servico_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank"
                            rel="noopener noreferrer">
                            {pDocumento.tags0}
                        </a>} />
                    <Column
                        sortable
                        header="Parceiro"
                        field="_vendedorNome" />
                    <Column
                        sortable
                        header="Cliente"
                        body={pDocumento => <a
                            href={"/administrativo/ordem_de_servico_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank"
                            rel="noopener noreferrer">
                            {pDocumento._pessoaNome}
                        </a>} />
                    <Column
                        header="Email"
                        field="_pessoaEmail"
                        sortable />
                    <Column
                        header="Data de cadastro"
                        field="dataCadastro"
                        sortable
                        body={pDocumento => <p>
                            {pDocumento.dataCadastro?.toLocaleString("pt-br")}
                        </p>} />
                    <Column
                        header="Data do evento"
                        field="dataProgramada"
                        sortable
                        body={pDocumento => <p>
                            {pDocumento.dataProgramada?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        header="Horário do evento"
                        field="tags1"
                        sortable />
                    <Column
                        header="Data de envio da proposta"
                        field="tagd1"
                        sortable
                        body={pDocumento => <p>
                            {pDocumento.tagd1?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        header="Data de aceite do cliente"
                        field="tagd2"
                        sortable
                        body={pDocumento => <p>
                            {pDocumento.tagd2?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        header="Processo"
                        field="_tipoDocumentoProcessoNome"
                        sortable />
                    <Column
                        header="Valor OS"
                        field="valorTotal"
                        body={pDocumento => <p>
                            {this.util.formatarValor(pDocumento.valorTotal)}
                        </p>}
                    />
                    <Column
                        header="Valor líder"
                        field="tagn1"
                        body={pDocumento => <p>
                            {this.util.formatarValor(pDocumento.tagn1)}
                        </p>}
                    />
                    <Column
                        header="Editar"
                        body={pDocumento => <Button
                            onClick={() => window.open('/parceiro/ordem_de_servico_detalhe?id=' + pDocumento._seqDocumento)}
                            icon="pi pi-pencil" />}
                    />
                </DataTable>
            </main>
        </div>
    }
}