import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Financeiro from "lirida-back-service/Servicos/Financeiro/Financeiro";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Component, createRef } from "react";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';

export default class Faturamento extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        listaPessoa: [],
        listaDocumento: [],
        listaDocumentoItem: [],
        pessoa: new Pessoa(),
        dataEventoInicial: undefined,
        dataEventoFinal: undefined,
        carregando: false,
        gerarCobrancaCarregando: false,
        valorTotalOrdemServico: 0,
        valorTotalLider: 0,
        valorTotalPagar: 0,
        checkbox: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilParceiro;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaPessoa = retorno.objeto;

        this.setState({
            listaPessoa: listaPessoa,
        });
    }

    async listar() {
        let listaDocumento = [];
        let listaPesquisa = [];
        let valorTotalOrdemServico = 0;
        let valorTotalLider = 0;
        let valorTotalPagar = 0;

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoOrdemServico;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND documento.seq_tipo_documento_processo in ('" + this.constante.seqTipoDocumentoProcessoDigitada + "', '" + this.constante.seqTipoDocumentoProcessoRecebendoProposta + "', '" + this.constante.seqTipoDocumentoProcessoAguardandoAceiteCliente + "', '" + this.constante.seqTipoDocumentoProcessoAguardandoDiaEvento + "', '" + this.constante.seqTipoDocumentoProcessoFinalizada + "') ";
        pesquisa.conteudo = this.constante.seqTipoDocumentoProcessoArquivados;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND documento.valor_total > 0 ";
        pesquisa.conteudo = this.constante.seqTipoDocumentoProcessoArquivados;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        if (this.state.pessoa._seqPessoa) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_usuario_vendedor';
            pesquisa.conteudo = this.state.pessoa._seqPessoa;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumento = retorno.objeto;
        

        for (let i = 0; i < listaDocumento.length; i++) {
            listaDocumento[i].__marcado = true;
            valorTotalOrdemServico = valorTotalOrdemServico + listaDocumento[i].valorLiquido;
            valorTotalLider = valorTotalLider + listaDocumento[i].tagn1;
            valorTotalPagar = valorTotalPagar + listaDocumento[i].valorTotal;
        }

        this.setState({
            checkbox: true,
            carregando: false,
            listaDocumento: listaDocumento,
            valorTotalOrdemServico: valorTotalOrdemServico,
            valorTotalLider: valorTotalLider,
            valorTotalPagar: valorTotalPagar,
        })
    }

    async gerarCobranca() {
        const listaFinanceiroReceber = [];
        let listaDocumento = this.state.listaDocumento;

        listaDocumento = listaDocumento.filter(item => item.__marcado);

        this.setState({ gerarCobrancaCarregando: true });

        for (let i = 0; i < listaDocumento.length; i++) {
            const listaPesquisa = [
                {
                    campo: "documento.seq_usuario",
                    conteudo: this.constante.seqUsuario,
                    operacao: "",
                    tipo: "TEXTO"
                },
                {
                    campo: "documento.seq_documento_origem",
                    conteudo: listaDocumento[i]._seqDocumento,
                    operacao: "AND",
                    tipo: "TEXTO"
                },
                {
                    campo: "documento.status",
                    conteudo: 3,
                    operacao: "AND",
                    tipo: "NUMERO"
                },
            ];

            const retornoDocumentoProposta = await this.documentoService.listarComFiltro(listaPesquisa);
            const documentoProposta = retornoDocumentoProposta.objeto[0];

            if (!documentoProposta) {
                this.setState({ gerarCobrancaCarregando: false });
                this.toast.current.show({
                    severity: "error", summary: `A OS Nº ${listaDocumento[i]._codigo} está incosistente`
                });
                return;
            }
        }


        for (let i = 0; i < listaDocumento.length; i++) {
            listaDocumento[i].seqFormaPagamento = this.constante.seqFormaPagamentoBoleto;
            listaDocumento[i].seqContaBancaria = this.constante.seqContaBancariaPrincipal;
            listaDocumento[i].seqContaFinanceira = this.constante.seqContaFinanceiraReceita;
            listaDocumento[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoFinalizada;
            listaDocumento[i].seqPessoaPagador = listaDocumento[i].seqUsuarioVendedor;
            listaDocumento[i].seqPessoaRecebedor = this.constante.seqPessoaAdministrador;
            listaDocumento[i].tagd0 = this.util.formatarObjetoDataParaDataBanco(new Date());
            listaDocumento[i].observacao = 'Ordem de Serviço Nº ' +
                listaDocumento[i]._codigo +
                ' - Contrato Nº: ' +
                listaDocumento[i].tags0 +
                ' - Cliente: ' +
                listaDocumento[i]._pessoaNome +
                ' - Data: ' +
                this.util.formatarData(listaDocumento[i].dataProgramada) +
                ' - Horário: ' +
                listaDocumento[i].tags1 +
                ' - Valor: ' +
                this.util.formatarValor(listaDocumento[i].valorTotal) +
                ' - Quantidade total de profissionais: ' +
                listaDocumento[i].tagn0;

            let retorno = await this.documentoService.salvar(listaDocumento[i]);
            listaDocumento[i] = retorno.objeto;

            retorno = await this.documentoService.gerarMovimentoFinanceiro(listaDocumento[i]);
            const financeiroReceberGerado = retorno.objeto;

            financeiroReceberGerado.tags0 = listaDocumento[i]._vendedorNome;
            financeiroReceberGerado.tags1 = listaDocumento[i]._codigo;

            retorno = await this.financeiroService.salvar(financeiroReceberGerado);
            const financeiroReceberSalvo = retorno.objeto;

            listaFinanceiroReceber.push(financeiroReceberSalvo);
        }

        if (listaFinanceiroReceber.length === 0) {
            this.setState({ gerarCobrancaCarregando: false });
            this.toast.current.show({
                severity: 'error', summary: 'Nenhum documento selecionado!'
            });
            return;
        }

        let retornoCobranca = await this.financeiroService.gerarLoteCobranca(listaFinanceiroReceber);
        let cobranca = retornoCobranca.objeto;

        for (let i = 0; i < listaDocumento.length; i++) {
            const documento = listaDocumento[i];
            let valorFinPagTotal = 0;

            const listaPesquisa = [
                {
                    campo: "documento.seq_usuario",
                    conteudo: this.constante.seqUsuario,
                    operacao: "",
                    tipo: "TEXTO"
                },
                {
                    campo: "documento.seq_documento_origem",
                    conteudo: documento._seqDocumento,
                    operacao: "AND",
                    tipo: "TEXTO"
                },
                {
                    campo: "documento.status",
                    conteudo: 3,
                    operacao: "AND",
                    tipo: "NUMERO"
                },
            ];

            const retornoDocumentoProposta = await this.documentoService.listarComFiltro(listaPesquisa);
            const documentoProposta = retornoDocumentoProposta.objeto[0];

            documentoProposta.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoFinalizada;
            documentoProposta.status = 5
            await this.documentoService.salvar(documentoProposta)

            let financeiroLider = new Financeiro();

            financeiroLider.seqUsuario = this.constante.seqUsuario;
            financeiroLider.dataProgramado = this.util.formatarObjetoDataParaDataBanco(new Date());
            financeiroLider.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
            financeiroLider.seqContaBancaria = this.constante.seqContaBancariaPrincipal;
            financeiroLider.seqContaFinanceira = this.constante.seqContaFinanceiraPagamentoFornecedor;
            financeiroLider.seqPessoa = documentoProposta.seqPessoa;
            financeiroLider.seqDocumento = documento._seqDocumento;
            financeiroLider.seqPessoaPagador = this.constante.seqPessoaAdministrador;
            financeiroLider.seqPessoaRecebedor = documentoProposta.seqPessoa;
            financeiroLider.valorProgramado = documento.tagn1;
            financeiroLider.valorLiquido = documento.tagn1;
            financeiroLider.tagn0 = documento._codigo;
            financeiroLider.tags0 = documento._vendedorNome;
            financeiroLider.tags1 = 'Valor Líder';
            financeiroLider.tags2 = documento.tags1;
            financeiroLider.tags3 = documento.seqUsuarioVendedor;
            financeiroLider.tagd0 = documento.dataProgramada;

            await this.financeiroService.salvar(financeiroLider);

            let financeiroCross = new Financeiro();

            financeiroCross.seqUsuario = this.constante.seqUsuario;
            financeiroCross.dataProgramado = this.util.formatarObjetoDataParaDataBanco(new Date());
            financeiroCross.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
            financeiroCross.seqContaBancaria = this.constante.seqContaBancariaPrincipal;
            financeiroCross.seqContaFinanceira = this.constante.seqContaFinanceiraPagamentoFornecedor;
            financeiroCross.seqPessoa = this.constante.seqPessoaFornecedor;
            financeiroCross.seqDocumento = documento._seqDocumento;
            financeiroCross.seqPessoaPagador = this.constante.seqPessoaAdministrador;
            financeiroCross.seqPessoaRecebedor = this.constante.seqPessoaFornecedor;
            financeiroCross.tagn0 = documento._codigo;
            financeiroCross.tags0 = documento._vendedorNome;
            financeiroCross.tags1 = 'Taxa Manutenção Sistema';
            financeiroCross.tags2 = documento.tags1;
            financeiroCross.tags3 = documento.seqUsuarioVendedor;
            financeiroCross.tagd0 = documento.dataProgramada;

            let qtdeProfissionais = 1;

            const retornoDocumentoItemProposta = await this.documentoService.listarDocumentoItem(documentoProposta);
            const listaDocumentoItemProposta = retornoDocumentoItemProposta.objeto;

            for (let j = 0; j < listaDocumentoItemProposta.length; j++) {
                const documentoItemProposta = listaDocumentoItemProposta[j];

                const retornoDocumentoItemRelacionamentoProposta = await this.documentoService.listarRelacionamentoItem(documentoItemProposta);
                const listaDocumentoItemRelacionamentoProposta = retornoDocumentoItemRelacionamentoProposta.objeto;

                console.log(listaDocumentoItemRelacionamentoProposta)
                const listaPessoa = [];

                for (let k = 0; k < listaDocumentoItemRelacionamentoProposta.length; k++) {
                    const documentoItemRelacionamentoProposta = listaDocumentoItemRelacionamentoProposta[k];

                    const retornoPessoa = await this.pessoaService.buscarPorSeqPessoa(documentoItemRelacionamentoProposta.seqPessoaRelacionada)
                    const pessoa = retornoPessoa.objeto;

                    listaPessoa.push(pessoa);
                }

                qtdeProfissionais = qtdeProfissionais + listaPessoa.length;

                for (let k = 0; k < listaPessoa.length; k++) {
                    const pessoa = listaPessoa[k];

                    // APENAS VALIDAR CONTA QUANDO OS PROFISSIONAIS FOREM PAGOS VIA SISTEMA
                    // if (pessoa?.contaS2pCodigo) {
                    const qntdDiasPagamento = 5;

                    const dataProgramada = new Date();
                    dataProgramada.setDate(dataProgramada.getDate() + qntdDiasPagamento);

                    const financeiro = new Financeiro();
                    financeiro.seqUsuario = this.constante.seqUsuario;
                    financeiro.dataProgramado = this.util.formatarObjetoDataParaDataBanco(dataProgramada);
                    financeiro.observacao = `OS Nº ${documento._codigo} - Função: ${documentoItemProposta._itemNome}`;
                    financeiro.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
                    financeiro.seqContaBancaria = this.constante.seqContaBancariaPrincipal;
                    financeiro.seqContaFinanceira = this.constante.seqContaFinanceiraPagamentoFornecedor;
                    financeiro.seqPessoa = pessoa._seqPessoa;
                    financeiro.seqDocumento = documento._seqDocumento;
                    financeiro.seqPessoaPagador = this.constante.seqPessoaAdministrador;
                    financeiro.seqPessoaRecebedor = pessoa._seqPessoa;
                    financeiro.valorProgramado = documentoItemProposta.precoTabelaMinimo;
                    financeiro.valorLiquido = documentoItemProposta.precoTabelaMinimo;
                    financeiro.tags0 = documento._vendedorNome;
                    financeiro.tags1 = documentoItemProposta._itemNome;
                    financeiro.tags2 = documento.tags1;
                    financeiro.tags3 = documento.seqUsuarioVendedor;
                    financeiro.tags4 = pessoa.tipoChavePix;
                    financeiro.tags5 = pessoa.chavePix;
                    financeiro.tagn0 = documento._codigo;
                    financeiro.tagd0 = documento.dataProgramada;

                    const retornoFinanceiroSalvo = await this.financeiroService.salvar(financeiro);
                    const financeiroSalvo = retornoFinanceiroSalvo.objeto;

                    

                    valorFinPagTotal = valorFinPagTotal + financeiroSalvo.valorProgramado;
                    // }
                }
            }

            for (let j = 0; j < listaFinanceiroReceber.length; j++) {
                const financeiroReceber = listaFinanceiroReceber[j];

                if (financeiroReceber.seqDocumento === listaDocumento[i]._seqDocumento) {
                    financeiroReceber.tagn0 = valorFinPagTotal;

                    const retorno = await this.financeiroService.salvar(financeiroReceber);

                    console.log('finPagTotal =>', retorno)
                }
            }

            financeiroCross.valorProgramado = qtdeProfissionais * 2.5;
            financeiroCross.valorLiquido = qtdeProfissionais * 2.5;

            await this.financeiroService.salvar(financeiroCross);
        }

        window.open('/parceiro/cobranca_detalhe?id=' + cobranca._seqCobranca);

        this.setState({
            gerarCobrancaCarregando: false
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Faturamento
                    </h1>
                </div>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: 'var(--primary-color)' }} /> Filtros
                        </h3>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 20 }}>
                        <div style={{ width: "100%" }}>
                            <label>Parceiro</label><br />
                            <Dropdown
                                filter
                                style={{ width: "100%" }}
                                options={[
                                    { ...new Pessoa(), nome: 'Todos' },
                                    ...this.state.listaPessoa
                                ]}
                                optionLabel="nome"
                                onChange={pEvento => this.setState({
                                    pessoa: pEvento.value
                                })}
                                value={this.state.pessoa} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <label>Data do evento inicial</label><br />
                            <Calendar
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    dataEventoInicial: pEvento.value,
                                })}
                                value={this.state.dataEventoInicial} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <label>Data do evento final</label><br />
                            <Calendar
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    dataEventoFinal: pEvento.value,
                                })}
                                value={this.state.dataEventoFinal} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            label="Buscar"
                            onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    value={this.state.listaDocumento}
                    header={<div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <p>Valor total das Ordens de Serviço: <span style={{ fontWeight: 'bold' }}>{this.util.formatarValor(this.state.valorTotalOrdemServico)}</span></p>
                            <p>Valor total do Líder: <span style={{ fontWeight: 'bold' }}>{this.util.formatarValor(this.state.valorTotalLider)}</span></p>
                            <p>Valor total à pagar: <span style={{ fontWeight: 'bold' }}>{this.util.formatarValor(this.state.valorTotalPagar)}</span></p>
                            {/* <Button
                                type="button"
                                icon="pi pi-file-excel"
                                onClick={() => this.exportExcel()}
                                className="p-button-success mr-2"
                                data-pr-tooltip="XLS" /> */}
                        </div>
                        <Button
                            style={{ marginTop: 20 }}
                            disabled={this.state.gerarCobrancaCarregando || this.state.listaDocumento.length === 0}
                            loading={this.state.gerarCobrancaCarregando}
                            label="Gerar cobrança"
                            onClick={() => this.gerarCobranca()} />
                    </div>
                    }>
                    <Column
                        header={<Checkbox
                            checked={this.state.checkbox}
                            onChange={e => {
                                let listaDocumento = this.state.listaDocumento;

                                this.setState({});

                                for (let i = 0; i < listaDocumento.length; i++) {
                                    listaDocumento[i].__marcado = e.checked;
                                }

                                this.setState({ checkbox: e.checked });
                            }} />}
                        body={pDocumento =>
                            <Checkbox
                                checked={pDocumento.__marcado}
                                onChange={e => {
                                    pDocumento.__marcado = e.checked;

                                    this.setState({});
                                }} />}
                    />
                    <Column
                        header="Ordem de serviço"
                        field="_codigo"
                    />
                    <Column
                        header="Número contrato"
                        body={pDocumento => <a
                            href={"/administrativo/ordem_de_servico_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank"
                            rel="noopener noreferrer">
                            {pDocumento.tags0}
                        </a>} />
                    <Column

                        header="Parceiro"
                        field="_vendedorNome" />
                    <Column

                        header="Cliente"
                        body={pDocumento => <a
                            href={"/administrativo/ordem_de_servico_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank"
                            rel="noopener noreferrer">
                            {pDocumento._pessoaNome}
                        </a>} />
                    <Column
                        header="Email"
                        field="_pessoaEmail"
                    />
                    <Column
                        header="Data de cadastro"
                        field="dataCadastro"

                        body={pDocumento => <p>
                            {this.util.formatarData(pDocumento.dataCadastro)}
                        </p>} />
                    <Column
                        header="Data do evento"
                        field="dataProgramada"

                        body={pDocumento => <p>
                            {this.util.formatarData(pDocumento.dataProgramada)}
                        </p>} />
                    <Column
                        header="Horário do evento"
                        field="tags1"
                    />
                    <Column
                        header="Data de envio da proposta"
                        field="tagd1"

                        body={pDocumento => <p>
                            {this.util.formatarData(pDocumento.tagd1)}
                        </p>} />
                    <Column
                        header="Data de aceite do cliente"
                        field="tagd2"

                        body={pDocumento => <p>
                            {this.util.formatarData(pDocumento.tagd2)}
                        </p>} />
                    <Column
                        header="Processo"
                        field="_tipoDocumentoProcessoNome"
                    />
                    <Column
                        header="Valor OS"
                        field="valorTotal"
                        body={pDocumento => <p>
                            {this.util.formatarValor(pDocumento.valorLiquido)}
                        </p>}
                    />
                    <Column
                        header="Valor líder"
                        field="tagn1"
                        body={pDocumento => <p>
                            {this.util.formatarValor(pDocumento.tagn1)}
                        </p>}
                    />
                    <Column
                        header="Valor a pagar"
                        body={pDocumento => <p>
                            {this.util.formatarValor(pDocumento.valorTotal)}
                        </p>}
                    />
                </DataTable>
            </main>
        </div>
    }
}