import { Component } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Constante from "../utils/Constante";
import Util from "../utils/Util";
import logoBranca from '../images/logo-branca.png';

export default class HeaderSistemaAdmin extends Component {
    constante = new Constante();
    util = new Util();

    state = {
        modalFinanceiroVisivel: false,
        dialogSairVisivel: false
    }

    async sair() {
        this.util.removerLocalStorage("PESSOA_USUARIO");
        window.location.href = '/login';
    }

    render() {
        return <header style={{ height: 60, backgroundColor: 'var(--primary-color)', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
            <Dialog header="Sair" visible={this.state.dialogSairVisivel} onHide={() => this.setState({ dialogSairVisivel: false })} style={{ width: '50%' }}>
                <p>Deseja sair da sua conta?</p>

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20 }}>
                    <Button label="Não" onClick={() => this.setState({ dialogSairVisivel: false })} />
                    <Button label="Sim" onClick={() => this.sair()} />
                </div>
            </Dialog>

            <img
            alt=""
                src={logoBranca}
                style={{ width: 100, height: 60, objectFit: 'contain' }} />

            <nav style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Ordens de Serviço"
                    onClick={() => window.location.href = '/administrativo/ordem_de_servico'} />
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Parceiros"
                    onClick={() => window.location.href = '/administrativo/parceiro'} />
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Profissionais"
                    onClick={() => window.location.href = '/administrativo/profissional'} />
                <div style={{ position: 'relative' }}>
                    <Button
                        style={{ color: 'white', padding: 0 }}
                        className="p-button-link"
                        label="Financeiro"
                        onClick={() => this.setState({ modalFinanceiroVisivel: !this.state.modalFinanceiroVisivel })} />
                    {this.state.modalFinanceiroVisivel &&
                        <div style={{ width: 150, padding: 10, backgroundColor: 'white', position: 'absolute', boxShadow: '0 2px 10px rgba(0,0,0,0.5)' }}>
                            <Button
                                style={{ padding: 0 }}
                                className="p-button-link"
                                label="Contas à pagar"
                                onClick={() => window.location.href = '/administrativo/contas_a_pagar'} />
                            <Button
                                style={{ padding: 0, marginTop: 10 }}
                                className="p-button-link"
                                label="Contas à receber"
                                onClick={() => window.location.href = '/administrativo/contas_a_receber'} />
                        </div>}
                </div>
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Faturamento"
                    onClick={() => window.location.href = '/administrativo/faturamento'} />
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Cobrança"
                    onClick={() => window.location.href = '/administrativo/cobranca'} />
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Perfil"
                    onClick={() => window.location.href = '/administrativo/perfil'} />
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Sair"
                    onClick={() => this.setState({
                        dialogSairVisivel: true
                    })} />
            </nav>
        </header>
    }
}