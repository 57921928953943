import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import { Dialog } from "primereact/dialog";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import { Rating } from "primereact/rating";

export default class ParceiroDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        urlBase: '',
        pessoa: new Pessoa(),
        endereco: new Endereco(),
        transformarProfissionalCarregando: false,
        listaFinanceiro: [],
        listaPessoaRelacionamento: [],
        listaPessoaRelacionamentoLider: [],
        listaDocumentoItem: [],
        dialogTrasformarProfissional: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaEndereco = [];
        let listaPesquisa = [];
        let listaPessoaRelacionamento = [];
        let listaPessoaRelacionamentoLider = [];
        let listaDocumentoItem = [];
        let endereco = new Endereco();

        let parametro = this.util.buscarParametrosUrl();
        let urlBase = await this.util.buscarUrlBaseArquivo();

        let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro.id, this.constante.seqUsuario);
        let pessoa = retorno.objeto;

        let listaFinanceiro = await this.listarExtrato(pessoa)

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                endereco = listaEndereco[i];
            }
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
        pesquisa.conteudo = pessoa._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoProfissional;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoaRelacionamento = retorno.objeto;

        for (let i = 0; i < listaPessoaRelacionamento.length; i++) {
            listaPessoaRelacionamento[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaPessoaRelacionamento[i].dataCadastro);
        }

        listaPesquisa = [];

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoProfissional;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa_relacionamento.seq_pessoa_relacionada';
        pesquisa.conteudo = pessoa._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoaRelacionamentoLider = retorno.objeto;

        for (let i = 0; i < listaPessoaRelacionamentoLider.length; i++) {
            listaPessoaRelacionamentoLider[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaPessoaRelacionamentoLider[i].dataCadastro);
        }

        listaPesquisa = [];

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento_item.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento_item_relacionamento.seq_tipo_relacionamento';
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoProfissional;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento_item_relacionamento.seq_pessoa_relacionada';
        pesquisa.conteudo = pessoa._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        retorno = await this.documentoService.listarItemComFiltro(listaPesquisa);
        listaDocumentoItem = retorno.objeto;

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            let retorno = await this.documentoService.listarRelacionamentoItem(listaDocumentoItem[i]);
            let listaDocumentoItemRelacionamento = retorno.objeto;

            listaDocumentoItem[i].dataProgramada = this.util.formatarDataBancoParaObjetoData(listaDocumentoItem[i].dataProgramada);

            for (let j = 0; j < listaDocumentoItemRelacionamento.length; j++) {
                if (listaDocumentoItemRelacionamento[j]._pessoaRelacionadaNome === pessoa.nome) {
                    listaDocumentoItem[i]._qtdeEstrelas = listaDocumentoItemRelacionamento[j].tagn0;
                    listaDocumentoItem[i]._comentario = listaDocumentoItemRelacionamento[j].tags0;
                }
            }
        }

        this.setState({
            pessoa: pessoa,
            endereco: endereco,
            urlBase: urlBase,
            listaFinanceiro: listaFinanceiro,
            listaPessoaRelacionamento: listaPessoaRelacionamento,
            listaPessoaRelacionamentoLider: listaPessoaRelacionamentoLider,
            listaDocumentoItem: listaDocumentoItem,
        });
    }

    async transformarProfissional() {
        let pessoa = this.state.pessoa;

        this.setState({ transformarProfissionalCarregando: true });

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilProfissional) {
            pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilLider;
        } else if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilLider) {
            pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilProfissional;
        }

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        this.toast.current.show({
            severity: 'success', summary: "Informações armazenadas com sucesso!"
        })

        this.setState({
            pessoa: pessoa,
            transformarProfissionalCarregando: false,
            dialogTrasformarProfissional: false,
        });
    }

    async listarExtrato(pessoa) {

        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.seq_pessoa_recebedor';
        pesquisa.conteudo = pessoa._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.financeiroService.listarComFiltro(listaPesquisa);
        let listaFinanceiro = retorno.objeto;

        

        this.setState({
            listaFinanceiro: listaFinanceiro,
            carregando: false
        })

        return listaFinanceiro
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <Dialog
                visible={this.state.dialogTrasformarProfissional}
                header="Transformar Profissional"
                onHide={() => this.setState({
                    dialogTrasformarProfissional: false,
                })}>
                {this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilProfissional &&
                    <h3 style={{ fontSize: 20, fontWeight: 'normal' }}>Deseja realmente Transformar este profissional em Líder?</h3>}

                {this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilLider &&
                    <h3 style={{ fontSize: 20, fontWeight: 'normal' }}>Deseja realmente Revogar a liderança deste Líder?</h3>}

                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'right', gap: 20, marginTop: 20 }}>
                    <Button
                        label="Sim"
                        onClick={() => this.transformarProfissional()} />
                    <Button
                        label="Não"
                        onClick={() => this.setState({
                            dialogTrasformarProfissional: false,
                        })} />
                </div>
            </Dialog>

            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Detalhe do Profissional
                    </h1>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: 'var(--primary-color)' }} /> Dados Pessoais
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <img alt=""
                        style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                        src={this.state.urlBase + this.state.pessoa.foto} />

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label><br />
                            <InputText
                                disabled
                                style={{ width: '100%' }}
                                value={this.state.pessoa.nome} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                disabled
                                style={{ width: '100%' }}
                                options={[
                                    { label: 'Pessoa Física', value: 'CPF' },
                                    { label: 'Pessoa Jurídica', value: 'CNPJ' },
                                ]}
                                value={this.state.pessoa.tipoDocumento} />
                        </div>
                        {this.state.pessoa.tipoDocumento === "CPF" &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%' }}
                                    value={this.state.pessoa.documento} />
                            </div>}
                        {this.state.pessoa.tipoDocumento === "CNPJ" &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    disabled
                                    style={{ width: '100%' }}
                                    value={this.state.pessoa.documento} />
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                disabled
                                style={{ width: '100%' }}
                                value={this.state.pessoa.emailPlataformaLirida} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                disabled
                                style={{ width: '100%' }}
                                value={this.state.pessoa.telefonePlataformaLirida} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-id-card" style={{ color: 'var(--primary-color)' }} /> Dados bancarios
                </h3>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>

                    <div style={{ width: '100%' }}>
                        <label>Banco</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.pessoa._contaNomeBanco || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Agência</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.pessoa.contaAgencia || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Digito agência</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.pessoa.contaDigitoAgencia || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Numero da conta</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.pessoa.contaNumeroConta || ''} />
                    </div>

                    <div style={{ width: '100%' }}>
                        <label>Digito Conta</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.pessoa.contaDigitoConta || ''} />
                    </div>

                    <div style={{ width: '100%' }}>
                        <label>Tipo chave pix</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.pessoa.tipoChavePix || ''} />
                    </div>

                    <div style={{ width: '100%' }}>
                        <label>Chave pix</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.pessoa.chavePix || ''} />
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: 'var(--primary-color)' }} /> Endereço
                </h3>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ width: '100%' }}>
                        <label>CEP</label><br />
                        <div className="p-inputgroup">
                            <InputText
                                disabled
                                value={this.state.endereco.cep || ''}
                                type="tel"
                                style={{ width: '100%', }}
                            />
                            <Button
                                disabled
                                icon="pi pi-search"
                                type="button"
                            />
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Logradouro</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.endereco.logradouro || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Número</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.endereco.numero || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Complemento</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.endereco.complemento || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Bairro</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.endereco.bairro || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Cidade</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.endereco.cidade || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Estado</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.endereco.estado || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>UF</label><br />
                        <InputText
                            disabled
                            style={{ width: "100%" }}
                            value={this.state.endereco.uf || ''} />
                    </div>
                </div>

                <TabView style={{ marginTop: 20 }}>
                    <TabPanel header="Eventos">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoItem}>
                            <Column
                                header="Número da OS"
                                field="tags6"
                                sortable />
                            <Column
                                header="Número do Contrato"
                                field="tags0"
                                sortable />
                            <Column
                                header="Parceiro"
                                field="tags4"
                                sortable />
                            <Column
                                header="Data do Evento"
                                field="dataProgramada"
                                sortable
                                body={pDocumentoRelacionamentoItem => <p>
                                    {pDocumentoRelacionamentoItem.dataProgramada?.toLocaleDateString("pt-br")}
                                </p>} />
                            <Column
                                header="Horário do Evento"
                                field="tags1"
                                sortable />
                            <Column
                                header="Função"
                                field="_itemNome"
                                sortable />
                            <Column
                                header="Valor"
                                field="precoTabelaMinimo"
                                sortable
                                body={pDocumentoRelacionamentoItem => <p>
                                    {this.util.formatarValor(pDocumentoRelacionamentoItem.precoTabelaMinimo)}
                                </p>} />
                            <Column
                                header="Líder"
                                field="_pessoaNome"
                                sortable />
                            <Column
                                header="Estrelas"
                                field="_qtdeEstrelas"
                                body={pDocumentoRelacionamentoItem => <Rating readOnly cancel={false} stars={5} value={pDocumentoRelacionamentoItem._qtdeEstrelas} />} />
                                <Column
                                    header="Comentário"
                                    field="_comentario"
                                    sortable />
                        </DataTable>
                    </TabPanel>
                    {this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilLider &&
                        <TabPanel header="Equipe">
                            <DataTable
                                showGridlines
                                removableSort
                                value={this.state.listaPessoaRelacionamento}
                                selectionMode="single"
                                onSelectionChange={pEvento => window.open("/administrativo/profissional_detalhe?id=" + pEvento.value._seqPessoaRelacionada)}>
                                <Column
                                    header="Foto"
                                    field="_pessoaRelacionadaFoto"
                                    sortable
                                    body={pPessoaRelacionamento => <img alt=""
                                        style={{ width: 80, height: 80, borderRadius: 500, objectFit: 'contain' }}
                                        src={this.state.urlBase + pPessoaRelacionamento._pessoaRelacionadaFoto} />} />
                                <Column
                                    header="Nome"
                                    field="_pessoaRelacionadaNome"
                                    sortable />
                                <Column
                                    header="Data do vínculo"
                                    field="dataCadastro"
                                    sortable
                                    body={pPessoaRelacionamento => <p>
                                        {pPessoaRelacionamento.dataCadastro?.toLocaleDateString('pt-br')}
                                    </p>} />
                            </DataTable>
                        </TabPanel>}
                    <TabPanel header="Líderes">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaPessoaRelacionamentoLider}
                            selectionMode="single"
                            onSelectionChange={pEvento => window.open("/administrativo/profissional_detalhe?id=" + pEvento.value._seqPessoa)}>
                            <Column
                                header="Foto"
                                field="foto"
                                sortable
                                body={pPessoaRelacionamento => <img alt=""
                                    style={{ width: 80, height: 80, borderRadius: 500, objectFit: 'contain' }}
                                    src={this.state.urlBase + pPessoaRelacionamento.foto} />} />
                            <Column
                                header="Nome"
                                field="nome"
                                sortable />
                            <Column
                                header="Data do vínculo"
                                field="dataCadastro"
                                sortable
                                body={pPessoaRelacionamento => <p>
                                    {pPessoaRelacionamento.dataCadastro?.toLocaleDateString("pt-br")}
                                </p>} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Perfil">
                        <Button
                            style={{ backgroundColor: this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilLider ? 'red' : 'var(--primary-color)' }}
                            disabled={this.state.transformarProfissionalCarregando}
                            loading={this.state.transformarProfissionalCarregando}
                            label={
                                this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilProfissional ?
                                    "Transformar em líder" :
                                    this.state.pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilLider ?
                                        "Revogar liderança" : ''}
                            onClick={() => this.setState({
                                dialogTrasformarProfissional: true
                            })} />
                    </TabPanel>
                    <TabPanel header="Extrato bancário">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaFinanceiro}
                            selectionMode="single"
                        >

                            <Column
                                header="Nome"
                                field="_pesNome"
                                sortable />
                            <Column
                                header="OS"
                                field="tagn0"
                                sortable />
                            <Column
                                header="Casa de evento"
                                field="tags0"
                                sortable />

                            <Column
                                header="Função"
                                field="tags1"
                                sortable />

                            <Column
                                header="Valor"
                                body={item => this.util.formatarValor(item.valorLiquido)}
                                sortable />

                            <Column
                                header="Data de Cadastro"
                                body={item => this.util.formatarData(item.dataCadastro)}
                                sortable />

                            <Column
                                header="Data do evento"
                                body={item => this.util.formatarData(item.tagd0)}
                                sortable />

                            <Column
                                header="Data Programada"
                                body={item => this.util.formatarData(item.dataProgramado)}
                                sortable />

                            <Column
                                sortable
                                header="Pago"
                                field="qtdeBaixa"
                                body={item => item.qtdeBaixa > 0 ? 'Pago' : 'Não pago'}
                            />
                        </DataTable>
                    </TabPanel>
                </TabView>
            </main>
        </div>
    }
}