import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Component, createRef } from "react";
import logo from '../images/logo.png';
import Constante from "../utils/Constante";
import Util from "../utils/Util";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

export default class ImprimirFichaProfissional extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        documento: new Documento(),
        documentoProposta: new Documento(),
        listaDocumentoItem: [],
        listaDocumentoProposta: [],
        total_proposta: 0,
        total_itens: 0,
        diferenca: true,
        urlBase: undefined,
        ap: false,
    }

    componentDidMount() {
        this.iniciar();
    }


    async iniciar() {
        let listaPesquisa = [];
        let listaDocumentoItem = [];
        let parametros = this.util.buscarParametrosUrl();
        let urlBase = await this.util.buscarUrlBaseArquivo();



        await new Promise(resolve => this.setState({
            urlBase: urlBase,
        }, resolve));

        let retorno = await this.documentoService.buscar(this.constante.seqUsuario, parametros.id);
        let documento = retorno.objeto;

        if (parametros.ap === "S") {
            if (documento.status < 3) {
            // if (documento.status) {
                this.setState({
                    ap: parametros.ap
                })
            }
            else {
                this.setState({
                    ap: false
                })
            }

        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento_item.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento_item_relacionamento.seq_tipo_relacionamento';
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoProfissional;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND documento_item_relacionamento.seq_documento_item IN ( SELECT seq_documento_item FROM documento_item WHERE seq_documento = '" + documento._seqDocumento + "') ";
        pesquisa.conteudo = documento._seqDocumento;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        

        retorno = await this.documentoService.listarItemComFiltro(listaPesquisa);
        listaDocumentoItem = retorno.objeto;

        listaDocumentoItem.sort((a, b) => {
            let nomeA = a._itemNomeVitrine.toUpperCase();
            let nomeB = b._itemNomeVitrine.toUpperCase();

            if (nomeA < nomeB) {
                return -1;
            }

            if (nomeA > nomeB) {
                return 1;
            }

            return 0;
        });

        for (const documentoItemRelacionamento of listaDocumentoItem) {
            const retorno = await this.pessoaService.buscarPorSeqPessoa(documentoItemRelacionamento._seqPessoaRelacionada);
            documentoItemRelacionamento._pessoaDocumento = retorno.objeto.documento;
        }
        
        //Bruno Fellip: ajustes para apresentar valor na tela de imprimir nota || Data: 05/09/2024 
        let contagem_temporaria = 0
        for (let index = 0; index < listaDocumentoItem.length; index++) {
            
            contagem_temporaria = contagem_temporaria + listaDocumentoItem[index].precoTabelaMaximo
        }
        this.setState({
            documento: documento,
            listaDocumentoItem: listaDocumentoItem,
            total_proposta: documento.valorTotal,
            total_itens: contagem_temporaria,
        });
        console.log(parseFloat(documento.valorTotal) , parseFloat(parseFloat(contagem_temporaria).toFixed(2)));
        console.log(parseFloat(documento.valorTotal) > parseFloat(contagem_temporaria));
        
        if (parseFloat(documento.valorTotal) > parseFloat(parseFloat(contagem_temporaria).toFixed(2))) {
            this.setState({ diferenca: false })
            
        }
        

    }
    /// edição
    async aprovar() {

        let documento = this.state.documento;
        
        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento_origem';
        pesquisa.conteudo = documento.seqDocumentoOrigem;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoProposta;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = ' AND documento.status in (2, 3, 4, 5)';
        pesquisa.conteudo = 2;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        let listaDocumentoProposta = retorno.objeto;

        let listaPesquisa_10 = [];
        let pesquisa_10 = new Pesquisa();
        pesquisa_10.campo = 'documento_item.seq_usuario';
        pesquisa_10.conteudo = this.constante.seqUsuario;
        pesquisa_10.operacao = '';
        pesquisa_10.tipo = 'TEXTO';
        listaPesquisa_10.push(pesquisa_10);

        pesquisa_10 = new Pesquisa();
        pesquisa_10.campo = 'documento_item_relacionamento.seq_tipo_relacionamento';
        pesquisa_10.conteudo = this.constante.seqTipoRelacionamentoProfissional;
        pesquisa_10.operacao = 'AND';
        pesquisa_10.tipo = 'TEXTO';
        listaPesquisa_10.push(pesquisa_10);

        pesquisa_10 = new Pesquisa();
        pesquisa_10.campo = " AND documento_item_relacionamento.seq_documento_item IN ( SELECT seq_documento_item FROM documento_item WHERE seq_documento = '" + documento._seqDocumento + "') ";
        pesquisa_10.conteudo = documento.seqDocumentoOrigem;
        pesquisa_10.operacao = 'AND';
        pesquisa_10.tipo = 'MANUAL';
        listaPesquisa_10.push(pesquisa_10);

        retorno = await this.documentoService.listarItemComFiltro(listaPesquisa_10);
        let listaDocumentoItem = retorno.objeto;

        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAguardandoAceiteCliente;
        let documentoProposta = this.state.documentoProposta;

        documento.status = 3;
        
        let retornop = await this.documentoService.salvar(documento);
        documentoProposta = retornop.objeto;
        console.log(documentoProposta);
        
        

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            listaDocumentoItem[i].status = 3;
            listaDocumentoItem[i].tags4 = this.state.documento._vendedorNome;
            listaDocumentoItem[i].tags1 = this.state.documento.tags1;
            listaDocumentoItem[i].tags6 = this.state.documento._codigo;
            listaDocumentoItem[i].dataProgramada = documento.dataProgramada;

            await this.documentoService.salvarItem(listaDocumentoItem[i]);
        }

        for (let i = 0; i < listaDocumentoProposta.length; i++) {
            if (listaDocumentoProposta[i]._seqDocumento !== documento._seqDocumento) {
                listaDocumentoProposta[i].status = 4;
                listaDocumentoProposta[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoCancelada;

                let response = await this.documentoService.salvar(listaDocumentoProposta[i]);
                
            }
        }
//seqDocumentoOrigem
        let documento_original = await this.documentoService.buscar(documento.seqUsuario, documento.seqDocumentoOrigem)
        documento_original.objeto.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAguardandoAceiteCliente;
        let documento_final_save = await this.documentoService.salvar(documento_original.objeto);

        this.setState({
            ap: false
        })
        this.toast.current.show({
            severity: 'success', summary: 'OS  aprovada com sucesso!'
        });
    }

    /// fim da edição

    render() {
        return <div style={{ padding: 10 }}>

            <Toast ref={this.toast} />

            <div style={{ display: 'flex', alignItems: 'center', gap: 10, border: '1px solid black', padding: 10 }}>
                <img alt=""
                    src={logo}
                    style={{ width: 150, objectFit: 'contain' }} />

                <div>
                    <small>OneWork Soluções Inteligentes</small><br />
                    <small>Endereco: Avenida Eusébio de Queiroz, 1450, Tamatanduba, Eusébio - CE</small><br />
                    <small>CNPJ: 45.669.894/0001-45</small>
                </div>
            </div>
            <div style={{ border: '1px solid black', borderTop: 'none', padding: 10 }}>
                <p style={{ fontSize: 12 }}>Dados do Evento</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Nº da Ordem de Serviço</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags6}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Casa de Festa</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags4}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Data</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarData(this.state.documento.dataProgramada)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Hora</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags1}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Quantidade de Profissionais</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.listaDocumentoItem.length}
                        </p>
                    </div>
                </div>
            </div>

            <DataTable
                style={{ marginTop: 20 }}
                showGridlines
                value={this.state.listaDocumentoItem}>
                <Column
                    header="Foto"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="_pessoaRelacionadaFoto"
                    body={pDocumentoItem => <img alt=""
                        src={this.state.urlBase + pDocumentoItem._pessoaRelacionadaFoto}
                        style={{ width: 40, height: 40, borderRadius: 500, objectFit: 'contain' }} />} />
                <Column
                    header="Profissional"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="_pessoaRelacionadaNome"
                    body={pDocumentoItem => <p style={{ fontSize: 10, }}>
                        {pDocumentoItem._pessoaRelacionadaNome}
                    </p>} />
                <Column
                    header="Documento"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="_pessoaDocumento"
                    body={pDocumentoItem => <p style={{ fontSize: 10, }}>
                        {pDocumentoItem._pessoaDocumento}
                    </p>} />
                <Column
                    header="Função"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="_itemNomeVitrine"
                    body={pDocumentoItem => <p style={{ fontSize: 10, }}>
                        {pDocumentoItem._itemNomeVitrine}
                    </p>} />
                    {this.state.ap ? (
                        <Column
                            header="Valor"
                            headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                            field="price"
                            body={pDocumentoItem => (
                                <p style={{ fontSize: 10 }}>
                                    R$ {this.util.formatarValor(pDocumentoItem.precoTabelaMaximo)}
                                </p>
                            )}
                        />
                    ) : null}
                                
                <Column
                    header="Rúbrica"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="" />
            </DataTable>
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'right', marginTop: 20, gap: "150px" }}>
                {this.state.ap ? (
                    this.state.diferenca ? (
                        <div style={{ border: '1px solid black', padding: 10 }}>
                            <p>Valor total: R$ {this.util.formatarValor(this.state.documento.valorTotal)}</p>
                        </div>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                                <p style={{color:"red" , marginTop: 10}}>Nem todos os colaboradores foram escalados</p>  
                            <div style={{ border: '1px solid black', padding: 10 }}>
                                <p style={{color:"red"}}>Valor total: R$ {this.util.formatarValor(this.state.documento.valorTotal)}</p>
                            </div>
                        </div>
                        )
                ) : null}
                <Button
                    visible={this.state.ap}
                    // visible={true}
                    // loading=
                    // disabled=
                    label="Aprovar OS"
                    onClick={() => this.aprovar()}
                />

            </div>
        </div>
    }
}