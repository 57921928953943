import { Component } from "react";
import logoBranca from '../images/logo-branca.png';

export default class FooterHotsite extends Component {
    render() {
        return <>
            <div className="h-footer">
                <footer className="h-footer-container">
                    <section>
                        <div>
                            <a href="/#sobre">Sobre</a>
                            <a href="/#funcionalidades">Funcionalidades</a>
                            <a href="/#depoimentos">Depoimentos</a>
                            {/* <a href="/#plataformas">Plataformas</a> */}
                            <a href="/#perguntas-frequentes">Perguntas frequentes</a>
                        </div>
                        <div>
                            <a href="/politica_de_privacidade" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/politica_de_privacidade" }}>Política de privacidade</a>
                            <a href="/termos_de_uso" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/termos_de_uso" }}>Termos de uso</a>
                        </div>
                        <div>
                            <a href="https://www.facebook.com/onework_oficial" target="_blank" rel="noreferrer">Facebook</a>
                            <a href="https://www.instagram.com/oneworkoficial/" target="_blank" rel="noreferrer">Instagram</a>
                        </div>
                    </section>

                    <img  src={logoBranca} alt="logo rodapé" />

                    <small style={{ color: 'white' }}>v1.0</small>
                </footer>
            </div>
        </>
    }
}