import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
// import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Component } from "react";
import logo from '../images/logo.png';
import Constante from "../utils/Constante";
import Util from "../utils/Util";

export default class ImprimirFichaFuncao extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);

    state = {
        documento: new Documento(),
        listaDocumentoItem: [],
        urlBase: undefined,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let lista = [];
        let listaDocumentoItem = [];
        let parametros = this.util.buscarParametrosUrl();
        let urlBase = await this.util.buscarUrlBaseArquivo();

        await new Promise(resolve => this.setState({
            urlBase: urlBase,
        }, resolve));

        let retorno = await this.documentoService.buscar(this.constante.seqUsuario, parametros.id);
        let documento = retorno.objeto;

        retorno = await this.documentoService.listarDocumentoItem(documento);
        listaDocumentoItem = retorno.objeto;

        listaDocumentoItem.sort((a, b) => {
            let nomeA = a._itemNomeVitrine.toUpperCase();
            let nomeB = b._itemNomeVitrine.toUpperCase();

            if (nomeA < nomeB) {
                return -1;
            }

            if (nomeA > nomeB) {
                return 1;
            }

            return 0;
        });

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            for (let j = 0; j < listaDocumentoItem[i].quantidade; j++) {
                lista.push(listaDocumentoItem[i]);
            }
        }

        this.setState({
            documento: documento,
            listaDocumentoItem: lista,
        });
    }

    render() {
        return <div style={{ padding: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, border: '1px solid black', padding: 10 }}>
                <img alt=""
                    src={logo}
                    style={{ width: 150, objectFit: 'contain' }} />

                <div>
                    <small>OneWork Soluções Inteligentes</small><br />
                    <small>Endereco: Avenida Eusébio de Queiroz, 1450, Tamatanduba, Eusébio - CE</small><br />
                    <small>CNPJ: 45.669.894/0001-45</small>
                </div>
            </div>
            <div style={{ border: '1px solid black', borderTop: 'none', padding: 10 }}>
                <p style={{ fontSize: 12 }}>Dados do Evento</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Nº da Ordem de Serviço</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento._codigo}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Casa de Festa</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags4}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Data</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarData(this.state.documento.dataProgramada)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Hora</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags1}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Quantidade de Profissionais</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.listaDocumentoItem.length}
                        </p>
                    </div>
                </div>
            </div>

            <DataTable
                style={{ marginTop: 20 }}
                showGridlines
                value={this.state.listaDocumentoItem}>
                <Column
                    header="Função"
                    headerStyle={{ width: '20%', fontSize: 10, fontWeight: 'bold' }}
                    field="_itemNomeVitrine"
                    body={pDocumentoItem => <p style={{ fontSize: 10, }}>
                        {pDocumentoItem._itemNomeVitrine}
                    </p>} />
                <Column
                    header="Nome"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="" />
                <Column
                    header="Rubrica"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="" />
            </DataTable>
        </div>
    }
}