import { Button } from "primereact/button";
import { Component, createRef } from "react";
import HeaderSistema from "../../components/HeaderSistema";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import VwContadorDocumento from 'lirida-back-service/Servicos/VwContadorDocumento/VwContadorDocumento';
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import { Toast } from "primereact/toast";

export default class OrdemServico extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    cepService = new CepService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        listaDocumento: [],
        listaVwContadorDocumento: [],
        carregando: false,
        conteudo: '',
        conteudoEmail: '',
        dataCadastroInicial: undefined,
        dataCadastroFinal: undefined,
        dataEventoInicial: undefined,
        dataEventoFinal: undefined,
        dataAceiteClienteInicial: undefined,
        dataAceiteClienteFinal: undefined,
        dataEnvioPropostaInicial: undefined,
        dataEnvioPropostaFinal: undefined,
        dataPagamentoInicial: undefined,
        dataPagamentoFinal: undefined,
        dataVencimentoInicial: undefined,
        dataVencimentoFinal: undefined,
        pago: undefined,
        vwContadorDocumento: new VwContadorDocumento(),
        dialogNovaOrdemServico: false,
        indexStepAtivo: 0,
        pessoa: new Pessoa(),
        enderecoCobranca: new Endereco(),
        enderecoEntrega: new Endereco(),
        documento: new Documento(),
        inputError: {
            nome: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
            enderecoCobrancaCep: false,
            enderecoCobrancaLogradouro: false,
            enderecoCobrancaNumero: false,
            enderecoCobrancaBairro: false,
            enderecoCobrancaCidade: false,
            enderecoCobrancaEstado: false,
            enderecoCobrancaUf: false,
            numeroContrato: false,
            horaEvento: false,
            dataEvento: false,
            numeroConvidado: false,
            observacaoEvento: false,
            observacaoLider: false,
            enderecoEntregaCep: false,
            enderecoEntregaLogradouro: false,
            enderecoEntregaNumero: false,
            enderecoEntregaBairro: false,
            enderecoEntregaCidade: false,
            enderecoEntregaEstado: false,
            enderecoEntregaUf: false,
        },
        salvarCarregando: false,
        enderecoCobrancaCarregando: false,
        enderecoEntregaCarregando: false,
        usarMeuEnderecoCarregando: false,
        usarInformacoesDaCasaCarregando: false,
        checkboxTodos: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        let retorno = await this.documentoService.listarContadorDocumento(this.constante.seqUsuario, this.constante.seqTipoDocumentoOrdemServico);
        let listaVwContadorDocumento = retorno.objeto;

        this.setState({
            listaVwContadorDocumento: listaVwContadorDocumento,
            pessoaUsuario: pessoaUsuario,
        })
    }

    async listar() {
        console.log("entrou");
        let listaDocumento = [];
        let listaPesquisa = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoOrdemServico;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario_vendedor';
        pesquisa.conteudo = this.state.pessoaUsuario._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        if (this.state.conteudo) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.nome';
            pesquisa.conteudo = this.state.conteudo;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.conteudoEmail) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.email_plataforma_lirida';
            pesquisa.conteudo = this.state.conteudoEmail;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEventoFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.state.dataEventoFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataPagamentoInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_pagamento';
            pesquisa.conteudo = this.state.dataPagamentoInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataPagamentoFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_pagamento';
            pesquisa.conteudo = this.state.dataPagamentoFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataVencimentoInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_vencimento_pagamento';
            pesquisa.conteudo = this.state.dataVencimentoInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataVencimentoFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.data_vencimento_pagamento';
            pesquisa.conteudo = this.state.dataVencimentoFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataAceiteClienteInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd2';
            pesquisa.conteudo = this.state.dataAceiteClienteInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataAceiteClienteFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd2';
            pesquisa.conteudo = this.state.dataAceiteClienteFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEnvioPropostaInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd1';
            pesquisa.conteudo = this.state.dataEnvioPropostaInicial.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataEnvioPropostaFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.tagd1';
            pesquisa.conteudo = this.state.dataEnvioPropostaFinal.toLocaleDateString('pt-br') + 'T:00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.pago === 'S') {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.pago';
            pesquisa.conteudo = this.state.pago
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.pago === 'N') {
            pesquisa = new Pesquisa();
            pesquisa.campo = ' AND documento.pago is null';
            pesquisa.conteudo = this.state.pago
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'MANUAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.vwContadorDocumento._seqTipoDocumentoProcesso) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_tipo_documento_processo';
            pesquisa.conteudo = this.state.vwContadorDocumento._seqTipoDocumentoProcesso
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumento = retorno.objeto;

        

        for (let i = 0; i < listaDocumento.length; i++) {
            listaDocumento[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].dataCadastro);
            listaDocumento[i].dataProgramada = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].dataProgramada);
            listaDocumento[i].tagd1 = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].tagd1);
            listaDocumento[i].tagd2 = this.util.formatarDataBancoParaObjetoData(listaDocumento[i].tagd2);
        }

        this.setState({
            carregando: false,
            listaDocumento: listaDocumento,
        })
    }

    async salvar() {
        let pessoa = this.state.pessoa;
        let documento = this.state.documento;
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (this.state.indexStepAtivo === 0) {
            if (!pessoa.nome) {
                inputError.nome = true;
            }

            if (!pessoa.tipoDocumento) {
                inputError.tipoDocumento = true;
            }

            if (!pessoa.documento) {
                inputError.documento = true;
            } else if (pessoa.tipoDocumento === 'CPF' && pessoa.documento.length < 14) {
                inputError.documento = true;
            } else if (pessoa.tipoDocumento === 'CNPJ' && pessoa.documento.length < 18) {
                inputError.documento = true;
            } else if (!await this.util.validarDocumento(pessoa.documento)) {
                inputError.documento = true;
            }

            if (!pessoa.emailPlataformaLirida) {
                inputError.email = true;
            }

            if (!this.util.validarEmail(pessoa.emailPlataformaLirida)) {
                inputError.email = true;
            }

            if (!pessoa.telefonePlataformaLirida) {
                inputError.telefone = true;
            }

            if (!enderecoCobranca.cep) {
                inputError.enderecoCobrancaCep = true;
            }

            if (!this.util.validarCep(enderecoCobranca.cep)) {
                inputError.enderecoCobrancaCep = true;
            }

            if (!enderecoCobranca.logradouro) {
                inputError.enderecoCobrancaLogradouro = true;
            }

            if (!enderecoCobranca.numero) {
                inputError.enderecoCobrancaNumero = true;
            }

            if (!enderecoCobranca.bairro) {
                inputError.enderecoCobrancaBairro = true;
            }

            if (!enderecoCobranca.cidade) {
                inputError.enderecoCobrancaCidade = true;
            }

            if (!enderecoCobranca.estado) {
                inputError.enderecoCobrancaEstado = true;
            }

            if (!enderecoCobranca.uf) {
                inputError.enderecoCobrancaUf = true;
            }
        }

        if (this.state.indexStepAtivo === 1) {
            if (!documento.tags0) {
                inputError.numeroContrato = true;
            }

            if (!documento.dataProgramada) {
                inputError.dataEvento = true;
            }

            if (!documento.tags1) {
                inputError.horaEvento = true;
            }

            if (!documento.tags2) {
                inputError.numeroConvidado = true;
            }

            // if (!documento.tags5) {
            //     inputError.observacaoEvento = true;
            // }

            // if (!documento.tags) {
            //     inputError.observacaoEvento = true;
            // }
        }

        if (this.state.indexStepAtivo === 2) {
            if (!enderecoEntrega.cep) {
                inputError.enderecoEntregaCep = true;
            }

            if (!this.util.validarCep(enderecoEntrega.cep)) {
                inputError.enderecoEntregaCep = true;
            }

            if (!enderecoEntrega.logradouro) {
                inputError.enderecoEntregaLogradouro = true;
            }

            if (!enderecoEntrega.numero) {
                inputError.enderecoEntregaNumero = true;
            }

            if (!enderecoEntrega.bairro) {
                inputError.enderecoEntregaBairro = true;
            }

            if (!enderecoEntrega.cidade) {
                inputError.enderecoEntregaCidade = true;
            }

            if (!enderecoEntrega.estado) {
                inputError.enderecoEntregaEstado = true;
            }

            if (!enderecoEntrega.uf) {
                inputError.enderecoEntregaUf = true;
            }
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({
            inputError: inputError
        });

        if (!inputsOK) {
            return;
        }

        if (this.state.indexStepAtivo < 2) {
            this.setState({
                indexStepAtivo: this.state.indexStepAtivo + 1
            });
            return;
        }

        this.setState({ salvarCarregando: true });

        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilCliente;

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        enderecoCobranca.seqUsuario = this.constante.seqUsuario;
        enderecoCobranca.seqPessoa = pessoa._seqPessoa;

        retorno = await this.pessoaService.salvarEndereco(enderecoCobranca);
        enderecoCobranca = retorno.objeto;

        enderecoEntrega.seqUsuario = this.constante.seqUsuario;
        enderecoEntrega.seqPessoa = pessoa._seqPessoa;

        retorno = await this.pessoaService.salvarEndereco(enderecoEntrega);
        enderecoEntrega = retorno.objeto;

        documento.seqUsuario = this.constante.seqUsuario;
        documento.seqTipoDocumento = this.constante.seqTipoDocumentoOrdemServico;
        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoDigitada;
        documento.seqPessoa = pessoa._seqPessoa;
        documento.seqUsuarioVendedor = this.state.pessoaUsuario._seqPessoa;
        documento.seqPessoaPagador = this.state.pessoaUsuario._seqPessoa;
        documento.seqPessoaRecebedor = this.constante.seqPessoaAdministrador;
        documento.seqFormaPagamento = this.constante.seqFormaPagamentoBoleto;
        documento.dataProgramada = documento.dataProgramada.toLocaleDateString("pt-br").split("/").join("-") + 'T00:00:00';

        retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        window.open("/parceiro/ordem_de_servico_detalhe?id=" + documento._seqDocumento);

        this.setState({
            salvarCarregando: false,
            pessoa: pessoa,
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            documento: documento,
            dialogNovaOrdemServico: false,
        })
    }

    async buscarCep(pEndereco) {
        let endereco = new Endereco();
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;
        let inputError = this.state.inputError;
        let inputsOK = true;

        endereco = pEndereco;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            this.setState({
                enderecoCobrancaCarregando: true
            });

            if (!endereco.cep) {
                inputError.enderecoCobrancaCep = true;
            }

            if (!this.util.validarCep(endereco.cep)) {
                inputError.enderecoCobrancaCep = true;
            }
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            this.setState({
                enderecoEntregaCarregando: true
            });

            if (!endereco.cep) {
                inputError.enderecoEntregaCep = true;
            }

            if (!this.util.validarCep(endereco.cep)) {
                inputError.enderecoEntregaCep = true;
            }
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            this.setState({ enderecoCobrancaCarregando: true });
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            this.setState({ enderecoEntregaCarregando: true });
        }

        let retorno = await this.cepService.buscarCep(endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            enderecoCobranca = endereco;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            enderecoEntrega = endereco;
        }

        this.setState({
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            enderecoCobrancaCarregando: false,
            enderecoEntregaCarregando: false,
        });
    }

    novaOrdemServico() {
        let enderecoCobranca = new Endereco();
        let enderecoEntrega = new Endereco();

        enderecoCobranca.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;
        enderecoEntrega.seqTipoEndereco = this.constante.seqTipoEnderecoEntrega;

        this.setState({
            dialogNovaOrdemServico: true,
            indexStepAtivo: 0,
            pessoa: new Pessoa(),
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            documento: new Documento(),
            inputError: {
                nome: false,
                tipoDocumento: false,
                documento: false,
                email: false,
                telefone: false,
                enderecoCobrancaCep: false,
                enderecoCobrancaLogradouro: false,
                enderecoCobrancaNumero: false,
                enderecoCobrancaBairro: false,
                enderecoCobrancaCidade: false,
                enderecoCobrancaEstado: false,
                enderecoCobrancaUf: false,
                numeroContrato: false,
                horaEvento: false,
                dataEvento: false,
                numeroConvidado: false,
                observacaoEvento: false,
                enderecoEntregaCep: false,
                enderecoEntregaLogradouro: false,
                enderecoEntregaNumero: false,
                enderecoEntregaBairro: false,
                enderecoEntregaCidade: false,
                enderecoEntregaEstado: false,
                enderecoEntregaUf: false,
            },
        })
    }

    async usarMeuEndereco() {
        let listaEndereco = [];
        let enderecoEntrega = this.state.enderecoEntrega;

        this.setState({ usarMeuEnderecoCarregando: true });

        let retorno = await this.pessoaService.listarEndereco(this.state.pessoaUsuario);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                enderecoEntrega = listaEndereco[i];
            }
        }

        delete enderecoEntrega._seqEndereco;
        enderecoEntrega.seqTipoEndereco = this.constante.seqTipoEnderecoEntrega;

        this.setState({
            usarMeuEnderecoCarregando: false,
            enderecoEntrega: enderecoEntrega,
        })
    }

    async exportarExcel() {
        let lista = [];
        let listaDocumento = this.state.listaDocumento;

        for (let i = 0; i < listaDocumento.length; i++) {
            let item = {};

            item["Ordem de Serviço"] = listaDocumento[i]._codigo;
            item["Número Contrato"] = listaDocumento[i].tags0;
            item["Parceiro"] = listaDocumento[i]._vendedorNome;
            item["Nome do Cliente"] = listaDocumento[i]._pessoaNome;
            item["Email do Cliente"] = listaDocumento[i]._pessoaEmail;
            item["Data do Evento"] = listaDocumento[i].dataProgramada;
            item["Data de Envio para Receber Propostas"] = listaDocumento[i].tagd1;
            item["Processo"] = listaDocumento[i]._tipoDocumentoProcessoNome;
            item["Valor da Ordem de Serviço"] = listaDocumento[i].valorTotal;
            item["Data de Cadastro"] = listaDocumento[i].dataCadastro;

            lista.push(item);
        }

        this.util.exportarExcel(lista, "Lista de Ordens de Serviço ( OneWork )")
    }

    async usarInformacoesDaCasa() {
        let pessoaUsuario = this.state.pessoaUsuario;
        let pessoa = this.state.pessoa;
        let listaEndereco = [];
        let enderecoCobranca = this.state.enderecoCobranca;

        this.setState({ usarInformacoesDaCasaCarregando: true });

        pessoa.nome = pessoaUsuario.nome;
        pessoa.tipoDocumento = pessoaUsuario.tipoDocumento;
        pessoa.documento = pessoaUsuario.documento;
        pessoa.emailPlataformaLirida = pessoaUsuario.emailPlataformaLirida;
        pessoa.telefonePlataformaLirida = pessoaUsuario.telefonePlataformaLirida;

        let retorno = await this.pessoaService.listarEndereco(pessoaUsuario);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                enderecoCobranca = listaEndereco[i];
            }
        }

        delete enderecoCobranca._seqEndereco;
        delete enderecoCobranca.seqPessoa;

        this.setState({
            pessoa: pessoa,
            enderecoCobranca: enderecoCobranca,
            usarInformacoesDaCasaCarregando: false
        });
    }

    render() {
        return <div>
            <Dialog
                header="Nova Ordem de Serviço"
                visible={this.state.dialogNovaOrdemServico}
                onHide={() => this.setState({
                    dialogNovaOrdemServico: false,
                })}>
                <Steps
                    readOnly
                    model={[
                        { label: 'Informações do cliente' },
                        { label: 'Informações do evento' },
                        { label: 'Local do evento' },
                    ]}
                    activeIndex={this.state.indexStepAtivo} />

                <form onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(); }}>
                    {this.state.indexStepAtivo === 0 &&
                        <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5 }}>
                            <h4 style={{ fontSize: 16, }}>
                                <span style={{ color: 'var(--primary-color)' }}>•</span> Dados pessoais
                            </h4>

                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                                <div style={{ width: '100%' }}>
                                    <label>Nome</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                nome: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.pessoa.nome || ''} />
                                    {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                                </div>
                                <div style={{ width: '100%' && 'red' }}>
                                    <label>Tipo de pessoa</label><br />
                                    <Dropdown
                                        options={[
                                            { label: 'Pessoa física', value: 'CPF' },
                                            { label: 'Pessoa jurídica', value: 'CNPJ' },
                                        ]}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                tipoDocumento: pEvento.value
                                            }
                                        })}
                                        style={{ width: "100%", borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                        value={this.state.pessoa.tipoDocumento || ''} />
                                    {this.state.inputError.tipoDocumento && <small style={{ color: 'red' }}>Tipo de pessoa inválido</small>}
                                </div>
                                {this.state.pessoa.tipoDocumento === 'CPF' &&
                                    <div style={{ width: '100%' }}>
                                        <label>CPF</label><br />
                                        <InputText
                                            style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                                            onChange={pEvento => this.setState({
                                                pessoa: {
                                                    ...this.state.pessoa,
                                                    documento: this.util.formatarCPF(pEvento.target.value)
                                                }
                                            })}
                                            value={this.state.pessoa.documento || ''} />
                                        {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                                    </div>}
                                {this.state.pessoa.tipoDocumento === 'CNPJ' &&
                                    <div style={{ width: '100%' }}>
                                        <label>CNPJ</label><br />
                                        <InputText
                                            style={{ width: "100%", borderColor: this.state.inputError.documento && 'red' }}
                                            onChange={pEvento => this.setState({
                                                pessoa: {
                                                    ...this.state.pessoa,
                                                    documento: this.util.formatarCNPJ(pEvento.target.value)
                                                }
                                            })}
                                            value={this.state.pessoa.documento || ''} />
                                        {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                                    </div>}
                                <div style={{ width: '100%' }}>
                                    <label>Email</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.email && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                emailPlataformaLirida: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.pessoa.emailPlataformaLirida || ''} />
                                    {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Telefone</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.telefone && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                            }
                                        })}
                                        value={this.state.pessoa.telefonePlataformaLirida || ''} />
                                    {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                                </div>
                            </div>

                            <h4 style={{ fontSize: 16, marginTop: 10, }}>
                                <span style={{ color: 'var(--primary-color)' }}>•</span> Endereço
                            </h4>

                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                                <div style={{ width: '100%' }}>
                                    <label>CEP</label><br />
                                    <div className="p-inputgroup">
                                        <InputText
                                            value={this.state.enderecoCobranca.cep || ''}
                                            type="tel"
                                            style={{ width: '100%', borderColor: this.state.inputError.enderecoCobrancaCep && 'red' }}
                                            onChange={pEvento => this.setState({
                                                enderecoCobranca: {
                                                    ...this.state.enderecoCobranca,
                                                    cep: this.util.formatarCEP(pEvento.target.value)
                                                }
                                            })}
                                        />
                                        <Button
                                            disabled={this.state.enderecoCobrancaCarregando}
                                            loading={this.state.enderecoCobrancaCarregando}
                                            icon="pi pi-search"
                                            type="button"
                                            onClick={() => this.buscarCep(this.state.enderecoCobranca)}
                                        />
                                    </div>
                                    {this.state.inputError.enderecoCobrancaCep && <small style={{ color: 'red' }}>Cep inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Logradouro</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaLogradouro && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                logradouro: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.logradouro || ''} />
                                    {this.state.inputError.enderecoCobrancaLogradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Número</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaNumero && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                numero: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.numero || ''} />
                                    {this.state.inputError.enderecoCobrancaNumero && <small style={{ color: 'red' }}>Número inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Complemento</label><br />
                                    <InputText
                                        style={{ width: "100%" }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                complemento: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.complemento || ''} />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Bairro</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaBairro && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                bairro: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.bairro || ''} />
                                    {this.state.inputError.enderecoCobrancaBairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Cidade</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaCidade && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                cidade: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.cidade || ''} />
                                    {this.state.inputError.enderecoCobrancaCidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>Estado</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaEstado && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                estado: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.estado || ''} />
                                    {this.state.inputError.enderecoCobrancaEstado && <small style={{ color: 'red' }}>Estado inválido</small>}
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label>UF</label><br />
                                    <InputText
                                        style={{ width: "100%", borderColor: this.state.inputError.enderecoCobrancaUf && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoCobranca: {
                                                ...this.state.enderecoCobranca,
                                                uf: pEvento.target.value
                                            }
                                        })}
                                        value={this.state.enderecoCobranca.uf || ''} />
                                    {this.state.inputError.enderecoCobrancaUf && <small style={{ color: 'red' }}>Uf inválido</small>}
                                </div>
                            </div>
                        </div>}

                    {this.state.indexStepAtivo === 1 && <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, }}>
                            <div style={{ width: '100%' }}>
                                <label>Número do contrato</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.numeroContrato && 'red' }}
                                    onChange={pEvento => this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            tags0: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.documento.tags0 || ''} />
                                {this.state.inputError.numeroContrato && <small style={{ color: 'red' }}>Número do contrato inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Data do evento</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    inputStyle={{ borderColor: this.state.inputError.dataEvento && 'red' }}
                                    style={{ width: "100%" }}
                                    onChange={pEvento => {
                                        this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                dataProgramada: pEvento.value
                                            }
                                        })
                                    }}
                                    value={this.state.documento.dataProgramada} />
                                {this.state.inputError.dataEvento && <small style={{ color: 'red' }}>Data do evento inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Horário do evento</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.horaEvento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            tags1: this.util.formatarHora(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.documento.tags1 || ''} />
                                {this.state.inputError.horaEvento && <small style={{ color: 'red' }}>Horário do evento inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Número de convidados</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.numeroConvidado && 'red' }}
                                    onChange={pEvento => this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            tags2: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.documento.tags2 || ''} />
                                {this.state.inputError.numeroConvidado && <small style={{ color: 'red' }}>Número de convidados inválido</small>}
                            </div>
                        </div>

                        <div style={{ width: '100%', marginTop: 10 }}>
                            <label>Observação do Evento</label><br />
                            <InputText
                                style={{ width: "100%", borderColor: this.state.inputError.observacaoEvento && 'red' }}
                                onChange={pEvento => this.setState({
                                    documento: {
                                        ...this.state.documento,
                                        tags5: pEvento.target.value
                                    }
                                })}
                                value={this.state.documento.tags5 || ''} />
                            {this.state.inputError.observacaoEvento && <small style={{ color: 'red' }}>Observação do Evento inválida</small>}
                        </div>
                        <div style={{ width: '100%', marginTop: 10 }}>
                            <label>Observação para o Líder ({this.state.documento.tags7?.length || '0'}/100)</label><br />
                            <InputText
                                maxLength={100}
                                style={{ width: "100%", borderColor: this.state.inputError.observacaoLider && 'red' }}
                                onChange={pEvento => this.setState({
                                    documento: {
                                        ...this.state.documento,
                                        tags7: pEvento.target.value
                                    }
                                })}
                                value={this.state.documento.tags7 || ''} />
                            {this.state.inputError.observacaoLider && <small style={{ color: 'red' }}>Observação para o Líder inválida</small>}
                        </div>
                    </div>}

                    {this.state.indexStepAtivo === 2 &&
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                            <div style={{ width: '100%' }}>
                                <label>CEP</label><br />
                                <div className="p-inputgroup">
                                    <InputText
                                        value={this.state.enderecoEntrega.cep || ''}
                                        type="tel"
                                        style={{ width: '100%', borderColor: this.state.inputError.enderecoEntregaCep && 'red' }}
                                        onChange={pEvento => this.setState({
                                            enderecoEntrega: {
                                                ...this.state.enderecoEntrega,
                                                cep: this.util.formatarCEP(pEvento.target.value)
                                            }
                                        })}
                                    />
                                    <Button
                                        disabled={this.state.enderecoEntregaCarregando}
                                        loading={this.state.enderecoEntregaCarregando}
                                        icon="pi pi-search"
                                        type="button"
                                        onClick={() => this.buscarCep(this.state.enderecoEntrega)}
                                    />
                                </div>
                                {this.state.inputError.enderecoEntregaCep && <small style={{ color: 'red' }}>Cep inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Logradouro</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaLogradouro && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            logradouro: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.logradouro || ''} />
                                {this.state.inputError.enderecoEntregaLogradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Número</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaNumero && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            numero: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.numero || ''} />
                                {this.state.inputError.enderecoEntregaNumero && <small style={{ color: 'red' }}>Número inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Complemento</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            complemento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.complemento || ''} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Bairro</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaBairro && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            bairro: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.bairro || ''} />
                                {this.state.inputError.enderecoEntregaBairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Cidade</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaCidade && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            cidade: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.cidade || ''} />
                                {this.state.inputError.enderecoEntregaCidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Estado</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaEstado && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            estado: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.estado || ''} />
                                {this.state.inputError.enderecoEntregaEstado && <small style={{ color: 'red' }}>Estado inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>UF</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.enderecoEntregaUf && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            uf: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.enderecoEntrega.uf || ''} />
                                {this.state.inputError.enderecoEntregaUf && <small style={{ color: 'red' }}>Uf inválido</small>}
                            </div>
                        </div>}

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                        <Button
                            disabled={this.state.indexStepAtivo === 0}
                            label="Voltar"
                            type="button"
                            onClick={() => this.setState({ indexStepAtivo: this.state.indexStepAtivo - 1 })} />

                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            {this.state.indexStepAtivo === 0 &&
                                <Button
                                    disabled={this.state.usarInformacoesDaCasaCarregando}
                                    loading={this.state.usarInformacoesDaCasaCarregando}
                                    label="Usar informações da casa"
                                    type="button"
                                    onClick={() => this.usarInformacoesDaCasa()} />}
                            {this.state.indexStepAtivo === 2 &&
                                <Button
                                    disabled={this.state.usarMeuEnderecoCarregando}
                                    loading={this.state.usarMeuEnderecoCarregando}
                                    label="Usar meu endereço"
                                    type="button"
                                    onClick={() => this.usarMeuEndereco()} />}
                            <Button
                                disabled={this.state.salvarCarregando}
                                loading={this.state.salvarCarregando}
                                label={this.state.indexStepAtivo < 2 ? "Avançar" : "Salvar"}
                                type="submit" />
                        </div>
                    </div>
                </form >
            </Dialog >

            <Toast ref={this.toast} />

            <HeaderSistema />

            <main style={{ padding: 20 }}>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h1 style={{ fontSize: 20 }}>
                            Ordens de Serviço
                        </h1>

                        <Button
                            label="Nova Ordem de Serviço"
                            onClick={() => this.novaOrdemServico()} />
                    </div>
                </div>


                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: 'var(--primary-color)' }} /> Filtros
                        </h3>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 20 }}>
                            <div style={{ width: "100%" }}>
                                <label>Nome do Cliente</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudo: pEvento.target.value
                                    })}
                                    value={this.state.conteudo} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Email do Cliente</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudoEmail: pEvento.target.value
                                    })}
                                    value={this.state.conteudoEmail} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroInicial: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroFinal: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do evento inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEventoInicial: pEvento.value,
                                    })}
                                    value={this.state.dataEventoInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do evento final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEventoFinal: pEvento.value,
                                    })}
                                    value={this.state.dataEventoFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do aceite do cliente inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataAceiteClienteInicial: pEvento.value,
                                    })}
                                    value={this.state.dataAceiteClienteInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do aceite do cliente final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataAceiteClienteFinal: pEvento.value,
                                    })}
                                    value={this.state.dataAceiteClienteFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do envio da proposta inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEnvioPropostaInicial: pEvento.value,
                                    })}
                                    value={this.state.dataEnvioPropostaInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do envio da proposta final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataEnvioPropostaFinal: pEvento.value,
                                    })}
                                    value={this.state.dataEnvioPropostaFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do pagamento inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataPagamentoInicial: pEvento.value,
                                    })}
                                    value={this.state.dataPagamentoInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do pagamento final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataPagamentoFinal: pEvento.value,
                                    })}
                                    value={this.state.dataPagamentoFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do vencimento inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataVencimentoInicial: pEvento.value,
                                    })}
                                    value={this.state.dataVencimentoInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data do vencimento final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataVencimentoFinal: pEvento.value,
                                    })}
                                    value={this.state.dataVencimentoFinal} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Pago</label><br />
                                <Dropdown
                                    filter
                                    style={{ width: "100%" }}
                                    options={[
                                        { label: 'Todos', value: '' },
                                        { label: 'Pago', value: 'S' },
                                        { label: 'Não pago', value: 'N' },
                                    ]}
                                    onChange={pEvento => this.setState({
                                        pago: pEvento.value
                                    })}
                                    value={this.state.pago} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Processos</label><br />
                                <Dropdown
                                    filter
                                    options={[
                                        { ...new VwContadorDocumento(), _tipoDocumentoProcessoNome: 'Todos' },
                                        ...this.state.listaVwContadorDocumento
                                    ]}
                                    optionLabel="_tipoDocumentoProcessoNome"
                                    onChange={pEvento => this.setState({
                                        vwContadorDocumento: pEvento.value
                                    })}
                                    value={this.state.vwContadorDocumento}
                                    style={{ width: '100%', }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            label="Buscar"
                            onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    value={this.state.listaDocumento}
                    header={
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <Button
                                icon="pi pi-file-excel"
                                onClick={() => this.exportarExcel()}
                                className="p-button-success mr-2"
                                data-pr-tooltip="XLS" />
                        </div>
                    }
                    onSelectionChange={pEvento => window.open('/parceiro/ordem_de_servico_detalhe?id=' + pEvento.value._seqDocumento)}>
                    <Column
                        sortable
                        header="Ordem de serviço"
                        field="_codigo" />
                    <Column
                        sortable
                        header="Número contrato"
                        body={pDocumento => <a
                            href={"/parceiro/ordem_de_servico_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank"
                            rel="noopener noreferrer">
                            {pDocumento.tags0}
                        </a>} />
                    <Column
                        sortable
                        header="Parceiro"
                        field="_vendedorNome" />
                    <Column
                        sortable
                        header="Cliente"
                        body={pDocumento => <a
                            href={"/parceiro/ordem_de_servico_detalhe?id=" + pDocumento._seqDocumento}
                            target="_blank"
                            rel="noopener noreferrer">
                            {pDocumento._pessoaNome}
                        </a>} />
                    <Column
                        sortable
                        header="Email"
                        field="_pessoaEmail" />
                    <Column
                        sortable
                        header="Data de cadastro"
                        field="dataCadastro"
                        body={pDocumento => <p>
                            {pDocumento.dataCadastro?.toLocaleString("pt-br")}
                        </p>} />
                    <Column
                        sortable
                        header="Data do evento"
                        field="dataProgramada"
                        body={pDocumento => <p>
                            {pDocumento.dataProgramada?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        sortable
                        header="Data de envio da proposta"
                        field="tagd1"
                        body={pDocumento => <p>
                            {pDocumento.tagd1?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        sortable
                        header="Data de aceite do cliente"
                        field="tagd2"
                        body={pDocumento => <p>
                            {pDocumento.tagd2?.toLocaleDateString("pt-br")}
                        </p>} />
                    <Column
                        sortable
                        header="Processo"
                        field="_tipoDocumentoProcessoNome" />
                    <Column
                        sortable
                        header="Visualizar"
                        body={pDocumento => <Button
                            icon="pi pi-eye"
                            onClick={() => window.open("/parceiro/ordem_de_servico_detalhe?id=" + pDocumento._seqDocumento)} />} />
                    <Column
                        header="Valor OS"
                        field="valorTotal"
                        body={pDocumento => <p>
                            {this.util.formatarValor(pDocumento.valorTotal)}
                        </p>}
                    />
                </DataTable>
            </main>
        </div >
    }
}