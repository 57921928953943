import { Component, createRef } from "react";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Toast } from "primereact/toast";

export default class OrdemServicoDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    toast = createRef();

    state = {
        urlBase: '',
        documento: new Documento(),
        documentoProposta: new Documento(),
        pessoa: new Pessoa(),
        enderecoCobranca: new Endereco(),
        enderecoEntrega: new Endereco(),
        listaDocumentoRelacionamento: [],
        listaDocumentoItem: [],
        listaDocumentoProposta: [],
        listaDocumentoItemProposta: [],
        listaDocumentoItemRelacionamentoProposta: [],
        listaArquivo: [],
        dialogDocumentoPropostaDetalheVisivel: false,
        dialogDocumentoItemPropostaDetalheVisivel: false,
        arquivarOrdemDeServicoCarregando: false
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let documento = new Documento();
        let pessoa = new Pessoa();
        let enderecoCobranca = new Endereco();
        let enderecoEntrega = new Endereco();
        let listaDocumentoProposta = [];
        let listaEndereco = [];
        let listaPesquisa = [];
        let parametro = this.util.buscarParametrosUrl();
        let urlBase = await this.util.buscarUrlBaseArquivo();

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento';
        pesquisa.conteudo = parametro.id;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        documento = retorno.objeto[0];

        retorno = await this.documentoService.listarDocumentoItem(documento);
        let listaDocumentoItem = retorno.objeto;

        retorno = await this.pessoaService.buscarPorSeqPessoa(documento.seqPessoa, this.constante.seqUsuario);
        pessoa = retorno.objeto;

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                enderecoCobranca = listaEndereco[i];
            }
        }

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
                enderecoEntrega = listaEndereco[i];
            }
        }

        listaPesquisa = [];

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento_origem';
        pesquisa.conteudo = documento._seqDocumento;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoProposta;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = ' AND documento.status in (2, 3, 4, 5)';
        pesquisa.conteudo = 2;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumentoProposta = retorno.objeto;

        retorno = await this.documentoService.listarArquivo(documento);
        let listaArquivo = retorno.objeto;

        retorno = await this.documentoService.listarRelacionamento(documento);
        let listaDocumentoRelacionamento = retorno.objeto;

        

        this.setState({
            urlBase: urlBase,
            documento: documento,
            listaDocumentoProposta: listaDocumentoProposta,
            pessoa: pessoa,
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            listaDocumentoItem: listaDocumentoItem,
            listaDocumentoRelacionamento: listaDocumentoRelacionamento,
            listaArquivo: listaArquivo,
        })
    }

    async selecionarProposta(pDocumentoProposta) {
        let retorno = await this.documentoService.listarDocumentoItem(pDocumentoProposta);
        let listaDocumentoItemProposta = retorno.objeto;

        this.setState({
            documentoProposta: pDocumentoProposta,
            listaDocumentoItemProposta: listaDocumentoItemProposta,
            dialogDocumentoPropostaDetalheVisivel: true,
        });
    }

    async selecionarItemProposta(pDocumentoItemProposta) {
        let retorno = await this.documentoService.listarRelacionamentoItem(pDocumentoItemProposta);
        let listaDocumentoItemRelacionamentoProposta = retorno.objeto;

        this.setState({
            listaDocumentoItemRelacionamentoProposta: listaDocumentoItemRelacionamentoProposta,
            dialogDocumentoItemPropostaDetalheVisivel: true,
        });
    }

    async arquivarOrdemDeServico() {
        let listaPesquisa = [];
        let listaDocumentoProposta = [];
        let documento = this.state.documento;

        this.setState({ arquivarOrdemDeServicoCarregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = ''
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoProposta;
        pesquisa.operacao = 'AND'
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento_origem';
        pesquisa.conteudo = documento._seqDocumento;
        pesquisa.operacao = 'AND'
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumentoProposta = retorno.objeto;

        for (let i = 0; i < listaDocumentoProposta.length; i++) {
            let listaDocumentoItemProposta = [];

            listaDocumentoProposta[i].status = 99;

            await this.documentoService.salvar(listaDocumentoProposta[i]);

            let retorno = await this.documentoService.listarDocumentoItem(listaDocumentoProposta[i]);
            listaDocumentoItemProposta = retorno.objeto;

            for (let k = 0; k < listaDocumentoItemProposta.length; k++) {
                listaDocumentoItemProposta[k].status = 99;

                await this.documentoService.salvarItem(listaDocumentoProposta[i]);
            }
        }

        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoArquivados;

        retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        });

        this.setState({
            documento: documento,
            arquivarOrdemDeServicoCarregando: false
        })
    }

    render() {
        return <div style={{ paddingBottom: 100 }}>
            <Toast ref={this.toast} />

            <Dialog
                header="Detalhe da proposta"
                visible={this.state.dialogDocumentoPropostaDetalheVisivel}
                onHide={() => this.setState({
                    dialogDocumentoPropostaDetalheVisivel: false,
                })}>
                <div style={{ padding: 20, display: 'flex', justifyContent: 'right', gap: 20 }}>
                    <Button
                        disabled={this.state.documentoProposta.status !== 3}
                        label="Imprimir ficha das funções"
                        onClick={() => window.open('/imprimir_ficha_funcao?id=' + this.state.documentoProposta._seqDocumento)} />
                    <Button
                        disabled={this.state.documentoProposta.status !== 3}
                        label="Imprimir ficha dos profissionais"
                        onClick={() => window.open('/imprimir_ficha_profissional?id=' + this.state.documentoProposta._seqDocumento)} />
                </div>
                <DataTable
                    showGridlines
                    removableSort
                    responsiveLayout="scroll"
                    value={this.state.listaDocumentoItemProposta}>
                    <Column
                        header="Nome"
                        field="_itemNome" />
                    <Column
                        header="Solicitado"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {pDocumentoItemProposta.quantidade}
                        </p>} />
                    <Column
                        header="Selecionado"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {pDocumentoItemProposta.quantidadeSaldo}
                        </p>} />
                    <Column
                        header="Valor unitário"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {this.util.formatarValor(pDocumentoItemProposta.precoTabelaMaximo)}
                        </p>} />
                    <Column
                        header="Valor total do item"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {this.util.formatarValor(pDocumentoItemProposta.quantidade * pDocumentoItemProposta.precoTabelaMaximo)}
                        </p>} />
                    <Column
                        header="Visualizar"
                        body={DocumentoItemProposta =>
                            <Button
                                className="p-button-rounded"
                                icon="pi pi-eye"
                                onClick={() => this.selecionarItemProposta(DocumentoItemProposta)} />} />
                </DataTable>
            </Dialog>

            <Dialog
                header="Profissionais"
                visible={this.state.dialogDocumentoItemPropostaDetalheVisivel}
                onHide={() => this.setState({
                    dialogDocumentoItemPropostaDetalheVisivel: false,
                })}>
                <DataTable
                    showGridlines
                    removableSort
                    responsiveLayout="scroll"
                    value={this.state.listaDocumentoItemRelacionamentoProposta}>
                    <Column
                        style={{ display: "flex", justifyContent: "center" }}
                        header="Foto"
                        body={pDocumentoItemRelacionamentoProposta =>
                            <img alt="" style={{ width: 50, height: 50, borderRadius: 500, objectFit: "initial" }}
                                src={this.state.urlBase + pDocumentoItemRelacionamentoProposta._pessoaRelacionadaFoto} />}
                    />
                    <Column
                        field="_pessoaRelacionadaNome"
                        header="Nome"
                    />
                    <Column
                        header="Check In"
                        body={pDocumentoItemRelacionamentoProposta => pDocumentoItemRelacionamentoProposta.tagd0 ? this.util.formatarData(pDocumentoItemRelacionamentoProposta.tagd0) + " às " + pDocumentoItemRelacionamentoProposta.tags0 : "Não informado"}
                    />
                    <Column
                        header="Check Out"
                        body={pDocumentoItemRelacionamentoProposta => pDocumentoItemRelacionamentoProposta.tagd1 ? this.util.formatarData(pDocumentoItemRelacionamentoProposta.tagd1) + " às " + pDocumentoItemRelacionamentoProposta.tags1 : "Não informado"}
                    />
                </DataTable>
            </Dialog>

            <Toast ref={this.toast} />

            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <div>
                        <h1 style={{ fontSize: 20 }}>
                            Detalhe da Ordem de Serviço
                        </h1>
                        <h2 style={{ fontSize: 20, fontWeight: 'normal', color: 'gray', marginTop: 5 }}>
                            Nº {this.state.documento._codigo}
                        </h2>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <Button
                            disabled={!this.state.documento._seqDocumento || this.state.arquivarOrdemDeServicoCarregando || this.state.documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoArquivados}
                            loading={this.state.arquivarOrdemDeServicoCarregando}
                            label="Arquivar Ordem de Serviço"
                            onClick={() => this.arquivarOrdemDeServico()} />
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: 'var(--primary-color)' }} /> Cliente
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <h4 style={{ fontSize: 16, }}>
                        <span style={{ color: 'var(--primary-color)' }}>•</span> Dados pessoais
                    </h4>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.pessoa.nome || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.pessoa.tipoDocumento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Documento</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.pessoa.documento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.pessoa.emailPlataformaLirida || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.pessoa.telefonePlataformaLirida || ''} />
                        </div>
                    </div>

                    <h4 style={{ fontSize: 16, marginTop: 10, }}>
                        <span style={{ color: 'var(--primary-color)' }}>•</span> Endereço
                    </h4>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>CEP</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.cep || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Logradouro</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.logradouro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.numero || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Complemento</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.complemento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Bairro</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.bairro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Cidade</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.cidade || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Estado</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.estado || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>UF</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoCobranca.uf || ''} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-book" style={{ color: 'var(--primary-color)' }} /> Descrição
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Parceiro</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            disabled
                            value={this.state.documento._vendedorNome || ''} />
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Número do contrato</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.documento.tags0 || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Data do evento</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.util.formatarData(this.state.documento.dataProgramada) || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Horário do evento</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.documento.tags1 || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número de convidados</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.documento.tags2 || ''} />
                        </div>
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Observação</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            disabled
                            value={this.state.documento.tags5 || ''} />
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: 'var(--primary-color)' }} /> Local de serviço
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>CEP</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.cep || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Logradouro</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.logradouro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.numero || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Complemento</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.complemento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Bairro</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.bairro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Cidade</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.cidade || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Estado</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.estado || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>UF</label><br />
                            <InputText
                                style={{ width: "100%" }}
                                disabled
                                value={this.state.enderecoEntrega.uf || ''} />
                        </div>
                    </div>
                </div>

                <TabView style={{ marginTop: 20 }}>
                    <TabPanel header="Itens">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoItem}>
                            <Column
                                header="Nome"
                                field="_itemNome" />
                            <Column
                                header="Quantidade"
                                body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                                    {pDocumentoItem.quantidade}
                                </p>} />
                            <Column
                                header="Valor unitário"
                                body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                                    {this.util.formatarValor(pDocumentoItem.precoTabelaMaximo)}
                                </p>} />
                            <Column
                                header="Valor total do item"
                                body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                                    {this.util.formatarValor(pDocumentoItem.quantidade * pDocumentoItem.precoTabelaMaximo)}
                                </p>} />
                        </DataTable>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: 20, marginTop: 10 }}>
                            <p style={{ fontSize: 20, textAlign: 'right' }}>
                                Quantidade total de profissionais: <span style={{ fontWeight: 'bold', }}>{this.state.documento.tagn0}</span>
                            </p>
                            <p style={{ fontSize: 20, textAlign: 'right' }}>
                                Valor total: <span style={{ fontWeight: 'bold', }}>{this.util.formatarValor(this.state.documento.valorLiquido)}</span>
                            </p>
                        </div>
                    </TabPanel>
                    <TabPanel header="Propostas">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoProposta}>
                            <Column
                                header="Foto"
                                body={pDocumentoProposta =>
                                    <img alt=""
                                        src={this.state.urlBase + pDocumentoProposta._pessoaFoto}
                                        style={{ width: 50, height: 50, borderRadius: 500, objectFit: 'contain' }} />} />
                            <Column
                                header="Nome"
                                field="_pessoaNome" />
                            <Column
                                header="Email"
                                field="_pessoaEmail" />
                            <Column
                                header="Status"
                                body={pDocumentoProposta =>
                                    pDocumentoProposta.status === 2 ?
                                        <p style={{ color: 'orange' }}>Análise</p> :
                                        pDocumentoProposta.status === 3 ?
                                            <p style={{ color: 'green' }}>Aprovado</p> :
                                            pDocumentoProposta.status === 4 ?
                                                <p style={{ color: 'red' }}>Reprovado</p> :
                                                pDocumentoProposta.status === 5 ?
                                                    <p style={{ color: 'var(--primary-color)' }}>Finalizado</p> : ''
                                } />
                            <Column
                                header="Visualizar"
                                body={pDocumentoProposta =>
                                    <Button
                                        className="p-button-rounded"
                                        icon="pi pi-eye"
                                        onClick={() => this.selecionarProposta(pDocumentoProposta)} />} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Arquivos">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaArquivo}>
                            <Column
                                field="arquivoOriginal"
                                header="Arquivo"
                                body={pArquivo =>
                                    <a
                                        href={this.state.urlBase + pArquivo.arquivo}
                                        target='_blank'
                                        rel="noreferrer">
                                        {pArquivo.arquivoOriginal}
                                    </a>} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Relacionamento">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoRelacionamento}>
                            <Column
                                header="Nome"
                                field="_pessoaRelacionadaNome" />
                            <Column
                                header="Tipo de relacionamento"
                                field="_tipoRelacionamentoNome" />
                            <Column
                                header="Data de cadastro"
                                field="dataCadastro"
                                body={pDocumentoRelacionamento => <p>
                                    {this.util.formatarData(pDocumentoRelacionamento.dataCadastro)}
                                </p>} />
                        </DataTable>
                    </TabPanel>
                </TabView>
            </main>
        </div>
    }
}