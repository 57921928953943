import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Component } from "react";
import Constante from "../utils/Constante";
import Util from "../utils/Util";

export default class AceiteCliente extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        pessoa: new Pessoa(),
        documento: new Documento(),
        enderecoEntrega: new Endereco(),
        listaDocumentoItem: [],
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let documento = new Documento();
        let pessoa = new Pessoa();
        let enderecoEntrega = new Endereco();
        let listaEndereco = [];
        let urlBase = await this.util.buscarUrlBaseArquivo();
        let parametro = this.util.buscarParametrosUrl();

        let retorno = await this.documentoService.buscar(this.constante.seqUsuario, parametro.id);
        documento = retorno.objeto;

        retorno = await this.pessoaService.buscarPorSeqPessoa(documento.seqPessoa, this.constante.seqUsuario);
        pessoa = retorno.objeto;

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if(listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
                enderecoEntrega = listaEndereco[i];
            }
        }

        this.setState({
            urlBase: urlBase,
            pessoa: pessoa,
            documento: documento,
            enderecoEntrega: enderecoEntrega,
        });
    }

    render() {
        return <div>
            
        </div>
    }
}