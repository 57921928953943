import { Component } from "react";
import logo from '../../images/logo.png';
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Cobranca from "lirida-back-service/Servicos/Cobranca/Cobranca";
import QRCode from "react-qr-code";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Barcode from "react-barcode";
import logoSantander from '../../images/santander_logo.jpg';
import logoBradesco from '../../images/bradesco_logo.png';
import logoBancoBrasil from '../../images/banco_do_brasil_logo.png';

export default class CobrancaDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    financeiroService = new FinanceiroService(this.constante.token, this.constante.urlBase);
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        cobranca: new Cobranca(),
        pessoa: new Pessoa(),
        listaCobrancaFinanceiro: [],
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let parametro = this.util.buscarParametrosUrl();
        let cobranca = new Cobranca();
        let listaCobrancaFinanceiro = [];

        let retorno = await this.financeiroService.buscarCobrancaPorSeq(this.constante.seqUsuario, parametro.id);
        cobranca = retorno.objeto;

        

        retorno = await this.financeiroService.listarFinanceiroPorSeqCobranca(cobranca._seqCobranca);
        listaCobrancaFinanceiro = retorno.objeto;

        retorno = await this.pessoaService.buscarPorSeqPessoa(listaCobrancaFinanceiro[0]._seqPessoaPagador, this.constante.seqUsuario);
        let pessoa = retorno.objeto;

        let listaCobrancaFinanceiroSemFornecedor = [];
        let valorTotalCobranca = 0;

        for (let i = 0; i < listaCobrancaFinanceiro.length; i++) {
            if (listaCobrancaFinanceiro[i]._seqPessoaRecebedor !== this.constante.seqPessoaFornecedor) {
                listaCobrancaFinanceiroSemFornecedor.push(listaCobrancaFinanceiro[i]);
                valorTotalCobranca = valorTotalCobranca + listaCobrancaFinanceiro[i].valor;
            }
        }

        this.setState({
            cobranca: cobranca,
            pessoa: pessoa,
            listaCobrancaFinanceiro: listaCobrancaFinanceiroSemFornecedor,
            valorTotalCobranca: valorTotalCobranca,
        });
    }

    render() {
        return <div style={{ padding: 10, position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, border: '1px solid black', padding: 10 }}>
                <img alt=""
                    src={logo}
                    style={{ width: 150, objectFit: 'contain' }} />

                <div>
                    <small>OneWork Soluções Inteligentes</small><br />
                    <small>Endereco: Avenida Eusébio de Queiroz, 1450, Tamatanduba, Eusébio - CE</small><br />
                    <small>CNPJ: 45.669.894/0001-45</small>
                </div>
            </div>

            <div style={{ border: '1px solid black', borderTop: 'none', padding: 10 }}>
                <p style={{ fontSize: 12 }}>Dados do pagador</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Nome</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.pessoa.nome}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>{this.state.pessoa.tipoDocumento}</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.pessoa.documento}
                        </p>
                    </div>
                </div>

                <p style={{ fontSize: 12, marginTop: 20 }}>Dados do recebedor</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Beneficiário</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            OneWork Soluções Inteligentes
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Chave PIX</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            CNPJ: 45.669.894/0001-45
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Email do financeiro</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            financeiro@onework.app.br
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Telefone do suporte</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            (85) 9788-0013
                        </p>
                    </div>
                </div>

                <div style={{ borderBottom: '1px dashed black', margin: '10px 0' }}></div>

                <p style={{ fontSize: 12 }}>Dados da cobrança</p>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Data da geração</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarData(this.state.cobranca.dataCadastro)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Data de pagamento</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarData(this.state.cobranca.dataBaixa)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Status</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.cobranca.statusLirida}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Quantidade de Ordens de Serviço</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.listaCobrancaFinanceiro.length}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Valor das Ordens de Serviço</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarValor(this.state.valorTotalCobranca)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Taxa de transferência bancária</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarValor(this.state.cobranca.valor - this.state.valorTotalCobranca)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Valor à pagar</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarValor(this.state.cobranca.valor)}
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginTop: 175, }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <p style={{ fontSize: 12, }}>Pague com PIX</p>
                    <div style={{ border: '5px solid white' }}>
                        <QRCode value={this.state.cobranca.codigoPix || ''} size={100} />
                    </div>
                </div>
            </div>

            <table style={{ width: "100%", borderTop: '1px dashed black' }}>
                <tbody>
                    <tr style={{ display: "grid", gridTemplateColumns: "2fr 1fr 5fr", }}>
                        <td>
                            {this.state.cobranca.codigoBanco === '001' &&
                                <img alt=""
                                    src={logoBancoBrasil}
                                    style={{ width: '100%', height: 50, objectFit: 'contain', }} />}
                            {this.state.cobranca.codigoBanco === '237' &&
                                <img alt=""
                                    src={logoBradesco}
                                    style={{ width: '100%', height: 50, objectFit: 'contain', }} />}
                            {this.state.cobranca.codigoBanco === '033' &&
                                <img alt=""
                                    src={logoSantander}
                                    style={{ width: '100%', height: 50, objectFit: 'contain', }} />}
                        </td>
                        <td style={{ border: "1px solid black", borderTop: '0px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                            <p style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center", }}>
                                {this.state.cobranca.digitBank}
                            </p>
                        </td>
                        <td style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <p style={{ fontSize: "13px", fontWeight: "bold", textAlign: "right", }}>
                                {this.state.cobranca.linhaDigitavel}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ display: "grid", gridTemplateColumns: "5fr 2fr", }}>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Local de pagamento
                            </p>
                            <p style={{ fontSize: "9px", }}>
                                PAGÁVEL PREFERENCIALMENTE NA REDE BANCÁRIA
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Vencimento
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", textAlign: "right", }}>
                                {/* 29/01/2011 */}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ display: "grid", gridTemplateColumns: "5fr 2fr", }}>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Beneficiário
                            </p>
                            <div style={{ display: 'flex' }}>
                                <p style={{ fontSize: "9px", }}>
                                    OneWork Soluções Inteligentes
                                </p>
                                <p style={{ fontSize: "9px", }}>
                                    45.669.894/0001-45
                                </p>
                            </div>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Agência / Conta
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                {this.state.cobranca.agencia} / {this.state.cobranca.conta}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ display: "grid", gridTemplateColumns: "1.15fr 1.75fr .625fr .5fr .975fr 2fr", }}>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Data do documento
                            </p>
                            <p style={{ fontSize: "9px", }}>
                                {this.util.formatarData(this.state.cobranca.dataCadastro)}
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                No documento
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Espécie doc
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Aceite
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                N
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Data processamento
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                {this.util.formatarData(this.state.cobranca.dataCadastro)}
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Nosso Número
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                {this.state.cobranca.nossoNumero}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ display: "grid", gridTemplateColumns: "1.15fr 1fr .5fr 1.375fr .975fr 2fr", }}>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Uso do banco
                            </p>
                            <p style={{ fontSize: "9px", }}>
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Carteira
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                {/* 06 */}
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Espécie
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                R$
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Qtde Moeda
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                (X) Valor
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                            </p>
                        </td>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                (*) Valor documento
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", textAlign: "right", }}>
                                {this.util.formatarValor(this.state.cobranca.valor)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ display: "grid", gridTemplateColumns: "5fr 2fr", }}>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <p style={{ fontSize: "8px", }}>
                                Instruções
                            </p>
                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                O REAL BENEFICIÁRIO DO VALOR DESTE BOLETO É : OneWork Soluções Inteligentes, CNPJ:45.669.894/0001-45<br />
                                A Safe2Pay é a Empresa responsável pela geração do título bancário
                            </p>
                        </td>
                        <td>
                            <div style={{ border: "1px solid black", padding: "2px 5px", }}>
                                <p style={{ fontSize: "8px", }}>
                                    (*) Desconto / Abatimentos
                                </p>
                                <p style={{ fontSize: "9px", fontWeight: "bold", textAlign: "right", }}>
                                </p>
                            </div>
                            <div style={{ border: "1px solid black", padding: "2px 5px", }}>
                                <p style={{ fontSize: "8px", }}>
                                    (*) Outras deduções
                                </p>
                                <p style={{ fontSize: "9px", fontWeight: "bold", textAlign: "right", }}>
                                </p>
                            </div>
                            <div style={{ border: "1px solid black", padding: "2px 5px", }}>
                                <p style={{ fontSize: "8px", }}>
                                    (*) Mora / Multa
                                </p>
                                <p style={{ fontSize: "9px", fontWeight: "bold", textAlign: "right", }}>
                                </p>
                            </div>
                            <div style={{ border: "1px solid black", padding: "2px 5px", }}>
                                <p style={{ fontSize: "8px", }}>
                                    (*) Outros acréscimos
                                </p>
                                <p style={{ fontSize: "9px", fontWeight: "bold", textAlign: "right", }}>
                                </p>
                            </div>
                            <div style={{ border: "1px solid black", padding: "2px 5px", }}>
                                <p style={{ fontSize: "8px", }}>
                                    (*) Valor cobrado
                                </p>
                                <p style={{ fontSize: "9px", fontWeight: "bold", textAlign: "right", }}>
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ display: "grid", gridTemplateColumns: "1fr", }}>
                        <td style={{ border: "1px solid black", padding: "2px 5px", }}>
                            <table>
                                <thead>
                                    <th style={{ width: '17%' }}></th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style={{ fontSize: "8px", }}>
                                                Pagador
                                            </p>
                                        </td>
                                        <td>
                                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                                {this.state.pessoa.nome} - {this.state.pessoa.tipoDocumento}: {this.state.pessoa.documento}<br />
                                                {String(`${this.state.pessoa._endLogradouro}, ${this.state.pessoa._endNumero}, ${this.state.pessoa._endComplemento && this.state.pessoa._endComplemento + ','} ${this.state.pessoa._endBairro}, ${this.state.pessoa._endCidade} - ${this.state.pessoa._endUf} - CEP: ${this.state.pessoa._endCep}`).toUpperCase()}
                                            </p>
                                        </td>
                                    </tr>

                                    <br />

                                    <tr>
                                        <td>
                                            <p style={{ fontSize: "8px", }}>
                                                Sacador / Avalista
                                            </p>
                                        </td>
                                        <td>
                                            <p style={{ fontSize: "9px", fontWeight: "bold", }}>
                                                SAFE2PAY INSTITUIÇÃO DE PAGAMENTO LTDA - CNPJ 31.037.942/0001-78
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div style={{ marginTop: 20 }}>
                <Barcode
                    width={2}
                    height={50}
                    fontSize={0}
                    value={this.state.cobranca.codigoBarras} />
            </div>

            <h1 style={{ pageBreakBefore: 'always', fontSize: 20, marginTop: 20 }}>Listagem das Ordens de Serviço</h1>

            <DataTable
                style={{ fontSize: 10, fontWeight: 'bold', marginTop: 10, }}
                showGridlines
                removableSort
                value={this.state.listaCobrancaFinanceiro}>
                <Column
                    header="Ordem"
                    body={(pCobrancaFinanceiro, props) => <p>
                        {props.rowIndex + 1}
                    </p>} />
                <Column
                    field="observacao"
                    header="Observação"
                    body={pCobrancaFinanceiro => <p>
                        {pCobrancaFinanceiro._observacao}
                    </p>} />
                <Column
                    field="valor"
                    header="Valor"
                    body={pCobrancaFinanceiro => <p style={{ textAlign: 'right' }}>
                        {this.util.formatarValor(pCobrancaFinanceiro.valor)}
                    </p>} />
            </DataTable>
        </div >
    }
}