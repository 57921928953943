export default class Constante {
    // Api
    token = '123';
    urlBase = 'https://api.lirida.com.br/v1/api/';
    // urlBase = 'http://192.168.1.7:8080/v1/api/';

    // Plataforma
    seqUsuario = "f3038ff5-e455-4b2c-b941-1a18d546ea31";

    // Admin
    seqPessoaAdministrador = '50ffe614-a4a9-4bc8-b599-b356517b306f';

    // Fornecedor
    seqPessoaFornecedor = '42ec4f83-7112-4ae6-bd36-b087a2406982';
    valorTaxaFornecedor = 2.5;
    valorTaxaProfissional = 0;

    // Perfil
    seqPessoaPerfilParceiro = "c034e905-5e3a-4dcc-99b9-c65452650ebf";
    seqPessoaPerfilAdministrador = "ba5f0f31-ae5e-457e-93fb-f593a7964c6b";
    seqPessoaPerfilLider = "51752550-d50d-4642-a49c-b05252fca601";
    seqPessoaPerfilProfissional = "32adc2ff-e590-4d27-b2be-52db8d84bbcb";
    seqPessoaPerfilCliente = "672d98db-7f46-455c-bb30-3a9c7dd25229";

    // Tipo contato
    seqTipoContatoTelefone = "215e44df-7fb0-46ba-81ea-fc47984aa7ac";
    seqTipoContatoEmail = "787b95b2-d72b-46b4-8f94-9ec19f58b117";
    seqTipoContatowhatsApp = "40c9d9fa-df1f-49f1-8f90-59dc2001f093";
    seqTipoContatoInstagram = "84c45973-ac60-45f3-ac10-e5c0b8822537";
    seqTipoContatoFacebook = "3a9b7211-371f-4534-9c2f-a3cd44c9e866";
    seqTipoContatoSite = "d09ef7d1-675b-4047-adeb-ebda83102e7a";

    // Endereco
    seqTipoEnderecoCobranca = "b4062983-6166-4c91-8319-a25287b505b2";
    seqTipoEnderecoEntrega = "7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09";

    // Tipo documento
    seqTipoDocumentoOrdemServico = "76f8da5d-4c02-4179-a9f1-a8faf6b4ff09";
    seqTipoDocumentoProposta = "7834676c-a5bd-46ea-90dc-abbe3b7c0f6f"

    // Tipo relacionamento 
    seqTipoRelacionamentoResponsavel = "75b44fe4-995c-4531-a6fa-811d5cd7f044";
    seqTipoRelacionamentoLider = "7e97bdc6-d1d2-44f8-b591-59e40c9b395d";
    seqTipoRelacionamentoLiderVinculado = 'b41654c8-389b-47cb-b5ed-91031276bf5a';
    seqTipoRelacionamentoProfissional = 'f43d020d-0585-4f73-8c0a-52eb5f9ab142';

    // Tipo documento processo
    seqTipoDocumentoProcessoDigitada = "69f0a80f-6468-4961-9240-645577d2d6ce";
    seqTipoDocumentoProcessoRecebendoProposta = "d31a7be0-9c9d-4d7c-be53-95b4e8e8f21c";
    seqTipoDocumentoProcessoAguardandoAceiteCliente = "31bc64d2-1448-4d9d-85d5-ea80eaf1fb70";
    seqTipoDocumentoProcessoAguardandoDiaEvento = "cde1d165-a25d-4e32-a4d4-216c6c92d40b";
    seqTipoDocumentoProcessoFinalizada = "ed10cfef-5604-4355-abe7-1e6ce60db9d1";
    seqTipoDocumentoProcessoCancelada = '491a38c7-56ff-4f65-bdf6-de5a6fac28b0';
    // seqTipoDocumentoProcessoCancelada = '491a38c7-56ff-4f65-bdf6-de5a6fac28b0';
    seqTipoDocumentoProcessoArquivados = 'c7fef0b7-1940-4282-ac24-84d952f31cc9';

    // Item categoria
    seqItemCategoria = "deece6a3-0e47-4644-b2ed-e2d6f65c7d14";

    // Tabela de preço
    seqTabelaPrecoPrincipal = "39026d9b-1eb2-46fc-9dad-a08687b0ec8c";

    // Tipo arquivo
    seqTipoArquivoInformacaoAdicional = "ed30a166-69ea-41d9-b990-e2cc522d231d"

    // Tipo atendimento
    seqTipoAtendimentoInformacaoAdicional = "317be2e7-0329-4574-8cca-9911f46b8863"

    // Email
    seqEmailAcesso = "728f2513-b464-46cd-8815-8750a14d7212";
    seqEmailAceiteCliente = "5ec5a43c-1d20-43b7-81de-260f3b85807a";
    seqEmailAceiteProposta = "7b2640d4-c440-4d91-b59a-dca34197c13f";

    // Conta bancária
    seqContaBancariaPrincipal = "52180c3b-ab43-4576-a7c4-46a2ad374c53"

    // Conta financeira
    seqContaFinanceiraReceita = "45535d7c-2608-4a37-9aed-bac11d018e08"
    seqContaFinanceiraPagamentoFornecedor = "437a0f61-fa4d-4a3a-b58b-7f82c16fdbe1"

    // Contas a pagar
    quantidadeDiasVencimentoContasPagar = 10;

    // Condicao pagamento
    seqCondicaoPagamentoAVista = '3cd67494-a665-4f56-a8a4-e5041c0f348c';

    // Forma de pagamento
    seqFormaPagamentoPix = "4a2f63b0-7ddb-4f92-a3e3-904831642415"
    seqFormaPagamentoBoleto = "655b80ce-68a9-40b6-9454-1e22284a525f"
    seqFormaPagamentoCartaoCredito = "b478379c-b8d9-4724-a1a0-fdb76426d45e"

    // ID Listar lista
    idListaContaBancaria = 'CB'
    idListaContaFinanceira = 'CF'
    idListaFormaPagamento = 'FP'
}