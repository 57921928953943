import { Component } from "react";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import Constante from "../utils/Constante";
import Util from "../utils/Util";
import logoBranca from '../images/logo-branca.png';

export default class HeaderSistema extends Component {
    constante = new Constante();
    util = new Util();

    state = {
        modalFinanceiroVisivel: false,
        dialogSairVisivel: false
    }

    async sair() {
        this.util.removerLocalStorage("PESSOA_USUARIO");
        window.location.href = '/login';
    }

    render() {
        return <header style={{ height: 60, backgroundColor: 'var(--primary-color)', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
            <Dialog header="Sair" visible={this.state.dialogSairVisivel} onHide={() => this.setState({ dialogSairVisivel: false })}>
                <p>Deseja sair da sua conta?</p>

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20 }}>
                    <Button label="Não" onClick={() => this.setState({ dialogSairVisivel: false })} />
                    <Button label="Sim" onClick={() => this.sair()} />
                </div>

            </Dialog>
            <img
                alt=""
                src={logoBranca}
                style={{ width: 100, height: 60, objectFit: 'contain' }} />

            <nav style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Ordens de Serviço"
                    onClick={() => window.location.href = '/parceiro/ordem_de_servico'} />
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Perfil"
                    onClick={() => window.location.href = '/parceiro/perfil'} />
                <Button
                    style={{ color: 'white', padding: 0 }}
                    className="p-button-link"
                    label="Sair"
                    onClick={() => this.setState({
                        dialogSairVisivel: true
                    })} />
            </nav>
        </header>
    }
}