import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import TabelaPreco from "lirida-back-service/Servicos/TabelaPreco/TabelaPreco";
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";

export default class ParceiroDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.urlBase);
    cepService = new CepService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        urlBase: '',
        pessoa: new Pessoa(),
        pessoaAdministrador: new Pessoa(),
        endereco: new Endereco(),
        tabelaPreco: new TabelaPreco(),
        listaTabelaPreco: [],
        dialogVincularLiderVisivel: false,
        inputError: {
            nome: false,
            apelido: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
            tabelaPreco: false,
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            estado: false,
            uf: false,
        },
        inputErrorVincularLider: {
            email: false,
        },
        conteudo: '',
        vincularCarregando: false,
        deletarCarregando: false,
        listaPessoaRelacionamento: [],
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let tabelaPreco = new TabelaPreco();
        let listaTabelaPreco = [];
        let listaEndereco = [];
        let endereco = new Endereco();

        let parametro = this.util.buscarParametrosUrl();
        let urlBase = await this.util.buscarUrlBaseArquivo();

        let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro.id, this.constante.seqUsuario);
        let pessoa = retorno.objeto;

        retorno = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador, this.constante.seqUsuario);
        let pessoaAdministrador = retorno.objeto;

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                endereco = listaEndereco[i];
            }
        }

        retorno = await this.tabelaPrecoService.listar(this.constante.seqUsuario);
        listaTabelaPreco = retorno.objeto;

        for (let i = 0; i < listaTabelaPreco.length; i++) {
            if (listaTabelaPreco[i]._seqTabelaPreco === pessoa.seqTabelaPreco) {
                tabelaPreco = listaTabelaPreco[i];
            }
        }

        await this.listarRelacionamento(pessoa);

        console.log(pessoa)
        this.setState({
            pessoa: pessoa,
            endereco: endereco,
            urlBase: urlBase,
            listaTabelaPreco: listaTabelaPreco,
            tabelaPreco: tabelaPreco,
            pessoaAdministrador: pessoaAdministrador,
        });

    }

    async listarRelacionamento(pPessoa) {
        let listaPesquisa = [];
        let listaPessoaRelacionamento = this.state.listaPessoaRelacionamento;

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa_relacionamento.seq_pessoa';
        pesquisa.conteudo = pPessoa._seqPessoa;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa_relacionamento.seq_tipo_relacionamento';
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoLiderVinculado;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        
        listaPessoaRelacionamento = retorno.objeto;

        

        this.setState({
            listaPessoaRelacionamento: listaPessoaRelacionamento,
        })
    }

    async salvar() {
        let inputError = this.state.inputError;
        let inputsOK = true;
        let pessoa = this.state.pessoa;
        let pessoaAdministrador = this.state.pessoaAdministrador;
        

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.pessoa.nome) {
            inputError.nome = true;
        }

        if (!this.state.pessoa.apelido) {
            inputError.apelido = true;
        }

        if (!this.state.pessoa.documento) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(this.state.pessoa.documento)) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CPF' && this.state.pessoa.documento.length !== 14) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CNPJ' && this.state.pessoa.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!this.state.pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(this.state.pessoa.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!this.state.pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.util.validarTelefone(this.state.pessoa.telefonePlataformaLirida)) {
            inputError.telefone = true;
        }

        if (!this.state.endereco.cep) {
            inputError.cep = true;
        }

        if (!this.state.endereco.logradouro) {
            inputError.logradouro = true;
        }

        if (!this.state.endereco.numero) {
            inputError.numero = true;
        }

        if (!this.state.endereco.bairro) {
            inputError.bairro = true;
        }

        if (!this.state.endereco.cidade) {
            inputError.cidade = true;
        }

        if (!this.state.endereco.estado) {
            inputError.estado = true;
        }

        if (!this.state.endereco.uf) {
            inputError.uf = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({
            inputError: inputError
        });

        if (!inputsOK) {
            return;
        }

        this.setState({ carregando: true });

        pessoa.tipoTaxaBoleto = pessoaAdministrador.tipoTaxaBoleto;
        pessoa.tipoTaxaCredito = pessoaAdministrador.tipoTaxaCredito;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;
        

        retorno = await this.pessoaService.salvarEndereco(this.state.endereco);
        let endereco = retorno.objeto;

        this.toast.current.show({
            severity: 'success', summary: "Informações armazenadas com sucesso!"
        })

        this.setState({
            pessoa: pessoa,
            endereco: endereco,
            carregando: false,
        });
    }

    async buscarCep() {
        let endereco = this.state.endereco;

        let retorno = await this.cepService.buscarCep(this.state.endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        this.setState({ endereco: endereco });
    }

    async vincular() {
        let listaPesquisa = [];
        let pessoa = new Pessoa();
        let pessoaRelacionamento = new PessoaRelacionamento();
        let inputErrorVincularLider = this.state.inputErrorVincularLider;
        let inputsOK = true;

        Object.keys(inputErrorVincularLider).forEach(pKey => {
            inputErrorVincularLider[pKey] = false;
        });

        if (!this.state.conteudo) {
            inputErrorVincularLider.email = true;
        }

        if (!this.util.validarEmail(this.state.conteudo)) {
            inputErrorVincularLider.email = true;
        }

        Object.keys(inputErrorVincularLider).forEach(pKey => {
            if (inputErrorVincularLider[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({
            inputErrorVincularLider: inputErrorVincularLider
        });

        if (!inputsOK) {
            return;
        }

        this.setState({ vincularCarregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilLider;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        pessoa = retorno.objeto[0];

        if (!pessoa) {
            this.setState({ vincularCarregando: false });
            this.toast.current.show({
                severity: 'error', summary: 'Usuário não encontrado!'
            });
            return;
        }

        pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
        pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoLiderVinculado;
        pessoaRelacionamento.seqPessoa = this.state.pessoa._seqPessoa;
        pessoaRelacionamento.seqPessoaRelacionada = pessoa._seqPessoa;

        await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);

        await this.listarRelacionamento(this.state.pessoa);

        this.setState({
            vincularCarregando: false,
            dialogVincularLiderVisivel: false,
        });
    }

    async removerVinculo(pPessoaRelacionamento) {
        
        this.setState({
            deletarCarregando: true,
        });

        await this.pessoaService.deletarRelacionamento(pPessoaRelacionamento);

        await this.listarRelacionamento(this.state.pessoa);

        this.toast.current.show({
            severity: 'success', summary: 'Vínculo removido com sucesso!'
        })

        this.setState({
            deletarCarregando: false,
        });
    }


    render() {
        return <div>
            <Toast ref={this.toast} />

            <Dialog
                header="Vincular líder"
                visible={this.state.dialogVincularLiderVisivel}
                onHide={() => this.setState({ dialogVincularLiderVisivel: false })}>
                <form
                    onSubmit={pEvento => { pEvento.preventDefault(); this.vincular(); }}>
                    <div styke={{ width: '100%' }}>
                        <label>Email do líder</label>
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputErrorVincularLider.email && 'red' }}
                            onChange={pEvento => this.setState({
                                conteudo: pEvento.target.value,
                            })}
                            value={this.state.conteudo || ''} />
                        {this.state.inputErrorVincularLider.email && <small style={{ color: 'red' }}>Email inválido</small>}
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <Button
                            disabled={this.state.vincularCarregando}
                            loading={this.state.vincularCarregando}
                            type="submit"
                            label="Vincular" />
                    </div>
                </form>
            </Dialog>

            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Detalhe do Parceiro
                    </h1>

                    <Button
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        label="Salvar"
                        onClick={() => this.salvar()} />
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: 'var(--primary-color)' }} /> Dados Pessoais
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <img alt=""
                        style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                        src={this.state.urlBase + this.state.pessoa.foto} />

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Razão Social</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Nome Fantasia</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.apelido && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        apelido: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.apelido} />
                            {this.state.inputError.apelido && <small style={{ color: 'red' }}>Nome fantasia inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                style={{ width: '100%' }}
                                options={[
                                    { label: 'Pessoa Física', value: 'CPF' },
                                    { label: 'Pessoa Jurídica', value: 'CNPJ' },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoa.tipoDocumento} />
                        </div>
                        {this.state.pessoa.tipoDocumento === "CPF" &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        {this.state.pessoa.tipoDocumento === "CNPJ" &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        {/* //edição */}
                        <div style={{ width: '100%' }}>
                            <label>Email aprovador</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        tags1: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.tags1} />
                            {this.state.inputError.tags1 && <small style={{ color: 'red' }}>Email aprovador inválido</small>}
                        </div>
                        {/* // fim da edição */}
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tabela de Preços</label><br />
                            <Dropdown
                                style={{ width: '100%' }}
                                options={this.state.listaTabelaPreco}
                                optionLabel="nome"
                                onChange={pEvento => this.setState({
                                    tabelaPreco: pEvento.value,
                                    pessoa: {
                                        ...this.state.pessoa,
                                        seqTabelaPreco: pEvento.value._seqTabelaPreco
                                    }
                                })}
                                value={this.state.tabelaPreco} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: 'var(--primary-color)' }} /> Endereço
                </h3>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ width: '100%' }}>
                        <label>CEP</label><br />
                        <div className="p-inputgroup">
                            <InputText
                                onChange={pEvento => this.setState({
                                    endereco: {
                                        ...this.state.endereco,
                                        cep: this.util.formatarCEP(pEvento.target.value),
                                    }
                                })}
                                value={this.state.endereco.cep || ''}
                                type="tel"
                                style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                            />
                            <Button
                                icon="pi pi-search"
                                type="button"
                                onClick={() => this.buscarCep()}
                            />
                        </div>
                        {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Logradouro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    logradouro: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.logradouro || ''} />
                        {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Número</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    numero: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.numero || ''} />
                        {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Complemento</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    complemento: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.complemento || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Bairro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    bairro: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.bairro || ''} />
                        {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Cidade</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    cidade: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.cidade || ''} />
                        {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválida</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Estado</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.estado && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    estado: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.estado || ''} />
                        {this.state.inputError.estado && <small style={{ color: 'red' }}>Estado inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>UF</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    uf: pEvento.target.value,
                                }
                            })}
                            value={this.state.endereco.uf || ''} />
                        {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválida</small>}
                    </div>
                </div>

                <TabView style={{ marginTop: 20 }}>
                    <TabPanel header="Líderes">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaPessoaRelacionamento}
                            selectionMode="single"
                            onSelectionChange={pEvento => window.open("/administrativo/profissional_detalhe?id=" + pEvento.value._seqPessoaRelacionada)}
                            header={<div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                <Button
                                    label="Vincular líder"
                                    onClick={() => this.setState({
                                        dialogVincularLiderVisivel: true
                                    })} />
                            </div>}>
                            <Column
                                header="Foto"
                                field="_pessoaRelacionadaFoto"
                                sortable
                                body={pPessoaRelacionamento => <img alt=""
                                    style={{ width: 80, height: 80, borderRadius: 500, objectFit: 'contain' }}
                                    src={this.state.urlBase + pPessoaRelacionamento._pessoaRelacionadaFoto} />} />
                            <Column
                                header="Nome"
                                field="_pessoaRelacionadaNome"
                                sortable />
                            <Column
                                header="Data do vínculo"
                                field="dataCadastro"
                                sortable
                                body={pPessoaRelacionamento => <p>
                                    {this.util.formatarData(pPessoaRelacionamento.dataCadastro)}
                                </p>} />
                            <Column
                                header="Remover vínculo"
                                sortable
                                body={pPessoaRelacionamento => <Button
                                    loading={this.state.deletarCarregando}
                                    disabled={this.state.deletarCarregando}
                                    className="p-button-danger"
                                    icon="pi pi-trash"
                                    onClick={() => this.removerVinculo(pPessoaRelacionamento)} />} />
                        </DataTable>
                    </TabPanel>
                </TabView>

            </main>
        </div>
    }
}