import { Button } from "primereact/button";
import { Component } from "react";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

export default class Parceiro extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        pessoa: new Pessoa(),
        listaPessoa: [],
        carregando: false,
        conteudoNome: '',
        conteudoEmail: '',
        conteudoDocumento: '',
        seqPessoaPerfil: undefined,
        dataCadastroInicial: undefined,
        dataCadastroFinal: undefined,
    }

    async listar() {
        let listaPessoa = [];
        let listaPesquisa = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND pessoa.seq_pessoa_perfil in ('" + this.constante.seqPessoaPerfilProfissional + "', '" + this.constante.seqPessoaPerfilLider + "') ";
        pesquisa.conteudo = this.constante.seqPessoaPerfilProfissional;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        if (this.state.conteudoNome) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.nome';
            pesquisa.conteudo = this.state.conteudoNome;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.conteudoEmail) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.email_plataforma_lirida';
            pesquisa.conteudo = this.state.conteudoEmail;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.conteudoDocumento) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.documento';
            pesquisa.conteudo = this.state.conteudoDocumento;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.seqPessoaPerfil) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.seq_pessoa_perfil';
            pesquisa.conteudo = this.state.seqPessoaPerfil;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroInicial.toLocaleDateString('pt-br') + 'T00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroFinal.toLocaleDateString('pt-br') + 'T00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoa = retorno.objeto;

        console.log(listaPessoa[0]);

        for (let i = 0; i < listaPessoa.length; i++) {
            listaPessoa[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaPessoa[i].dataCadastro);
        }

        this.setState({
            carregando: false,
            listaPessoa: listaPessoa,
        })
    }

    async exportarExcel() {
        let lista = [];
        let listaPessoa = this.state.listaPessoa;

        for (let i = 0; i < listaPessoa.length; i++) {
            let item = {};

            item["Nome"] = listaPessoa[i].nome;
            item["Email"] = listaPessoa[i].emailPlataformaLirida;
            item["Telefone"] = listaPessoa[i].telefonePlataformaLirida;
            item["Documento"] = listaPessoa[i].documento;
            item["Perfil"] = listaPessoa[i]._perfilNome;
            item["Tipo da Chave PIX"] = listaPessoa[i].tipoChavePix;
            item["Chave PIX"] = listaPessoa[i].chavePix;
            item["Endereço"] = `${listaPessoa[i]._endLogradouro}, ${listaPessoa[i]._endNumero}, ${listaPessoa[i]._endComplemento && listaPessoa[i]._endComplemento + ', '}${listaPessoa[i]._endBairro} , ${listaPessoa[i]._endCidade}, ${listaPessoa[i]._endPais}, ${listaPessoa[i]._endUf}`
            item["Data de Cadastro"] = listaPessoa[i].dataCadastro;

            lista.push(item);
        }

        this.util.exportarExcel(lista, "Lista de Profissionais ( OneWork )");
    }


    render() {
        return <div>
            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Profissionais
                    </h1>
                </div>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: 'var(--primary-color)' }} /> Filtros
                        </h3>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 20 }}>
                            <div style={{ width: "100%" }}>
                                <label>Nome</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudoNome: pEvento.target.value
                                    })}
                                    value={this.state.conteudoNome} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Email</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudoEmail: pEvento.target.value
                                    })}
                                    value={this.state.conteudoEmail} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Documento</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudoDocumento: pEvento.target.value
                                    })}
                                    value={this.state.conteudoDocumento} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Perfil</label><br />
                                <Dropdown
                                    style={{ width: "100%" }}
                                    options={[
                                        { label: 'Todos', value: '' },
                                        { label: 'Profissional', value: this.constante.seqPessoaPerfilProfissional },
                                        { label: 'Lider', value: this.constante.seqPessoaPerfilLider },
                                    ]}
                                    onChange={pEvento => this.setState({
                                        seqPessoaPerfil: pEvento.value
                                    })}
                                    value={this.state.seqPessoaPerfil} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroInicial: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroFinal: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroFinal} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            label="Buscar"
                            onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    selectionMode="single"
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    value={this.state.listaPessoa}
                    header={
                        <div>
                            <Button
                                type="button"
                                icon="pi pi-file-excel"
                                onClick={() => this.exportarExcel()}
                                className="p-button-success mr-2"
                                data-pr-tooltip="XLS" />
                        </div>
                    }
                    onSelectionChange={pEvento => window.open('/administrativo/profissional_detalhe?id=' + pEvento.value._seqPessoa)}>
                    <Column
                        header="Profissional"
                        field="nome"
                        sortable />
                    <Column
                        header="Email"
                        field="emailPlataformaLirida"
                        sortable />
                    <Column
                        header="Telefone"
                        field="telefonePlataformaLirida"
                        sortable />
                    <Column
                        header="Documento"
                        field="documento"
                        sortable />
                    <Column
                        header="Perfil"
                        field="_perfilNome"
                        sortable />
                    <Column
                        header="Tipo da Chave PIX"
                        field="tipoChavePix"
                        sortable />
                    <Column
                        header="Chave PIX"
                        field="chavePix"
                        sortable />
                    <Column
                        header="Endereço"
                        field="chavePix"
                        sortable
                        body={pPessoa => (
                            <p>
                                {`${pPessoa._endLogradouro}, ${pPessoa._endNumero}, ${pPessoa._endComplemento && pPessoa._endComplemento + ', '}${pPessoa._endBairro} , ${pPessoa._endCidade}, ${pPessoa._endPais}, ${pPessoa._endUf}`}
                            </p>
                        )} />
                    <Column
                        header="Data de cadastro"
                        field="dataCadastro"
                        sortable
                        body={pPessoa => <p>
                            {pPessoa.dataCadastro?.toLocaleDateString("pt-br")}
                        </p>} />
                </DataTable>
            </main>
        </div >
    }
}