import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Hotsite from './pages/Hotsite';

import "primereact/resources/themes/mdc-light-deeppurple/theme.css";
import "primeicons/primeicons.css";

// import { PrimeReactProvider, addLocale, locale } from 'primereact/api';
import { PrimeReactProvider, addLocale } from 'primereact/api';

import Login from './pages/Login';
import OrdemServicoAdmin from './pages/administrativo/OrdemServico';
import OrdemServicoDetalheAdmin from './pages/administrativo/OrdemServicoDetalhe';
import ParceiroAdmin from './pages/administrativo/Parceiro';
import ParceiroDetalheAdmin from './pages/administrativo/ParceiroDetalhe';
import ProfissionalAdmin from './pages/administrativo/Profissional';
import ProfissionalDetalheAdmin from './pages/administrativo/ProfissionalDetalhe';
import ContasAPagarAdmin from './pages/administrativo/ContasAPagar';
import ContasAReceberAdmin from './pages/administrativo/ContasAReceber';
import PerfilAdmin from './pages/administrativo/Perfil';
import Fatura from './pages/Fatura';
import OrdemServico from './pages/parceiro/OrdemServico';
import Perfil from './pages/parceiro/Perfil';
import OrdemServicoDetalhe from './pages/parceiro/OrdemServicoDetalhe';
import CobrancaDetalhe from './pages/parceiro/CobrancaDetalhe';
import AceiteCliente from './pages/AceiteCliente';
import Faturamento from './pages/administrativo/Faturamento';
import CobrancaPg from './pages/administrativo/CobrancaPg';
// import ImprimirFicha from './pages/ImprimirFichaProfissional';
import RecuperarSenha from './pages/RecuperarSenha';
import ImprimirFichaFuncao from './pages/ImprimirFichaFuncao';
import ImprimirFichaProfissional from './pages/ImprimirFichaProfissional';
import Avaliacao from './pages/Avaliacao';

addLocale('br', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  today: 'Hoje',
  clear: 'Limpar'
});

class Rota extends Component {
  render() {
    return window.location.pathname === this.props.path && this.props.component
  }
}

// SEGURANÇA

// let listaRotaAdministrativo = [];
// let listaRotaParceiro = [];

// (async function iniciar() {
//   let util = new Util();

//   let pessoa = new Pessoa();
//   pessoa = await util.buscarLocalStorage("PESSOA_USUARIO");

//   
//   console.log(window.location.pathname)
// })();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<PrimeReactProvider value={{
  locale: 'br'
}}>
  <Rota path="/" component={<Hotsite />} />
  <Rota path="/login" component={<Login />} />
  <Rota path="/recuperar_senha" component={<RecuperarSenha />} />
  <Rota path="/fatura" component={<Fatura />} />
  <Rota path="/aceite_cliente" component={<AceiteCliente />} />
  <Rota path="/imprimir_ficha_funcao" component={<ImprimirFichaFuncao />} />
  <Rota path="/imprimir_ficha_profissional" component={<ImprimirFichaProfissional />} />
  <Rota path="/avaliacao" component={<Avaliacao />} />
  <Rota path="/administrativo/ordem_de_servico" component={<OrdemServicoAdmin />} />
  <Rota path="/administrativo/ordem_de_servico_detalhe" component={<OrdemServicoDetalheAdmin />} />
  <Rota path="/administrativo/parceiro" component={<ParceiroAdmin />} />
  <Rota path="/administrativo/parceiro_detalhe" component={<ParceiroDetalheAdmin />} />
  <Rota path="/administrativo/profissional" component={<ProfissionalAdmin />} />
  <Rota path="/administrativo/profissional_detalhe" component={<ProfissionalDetalheAdmin />} />
  <Rota path="/administrativo/contas_a_pagar" component={<ContasAPagarAdmin />} />
  <Rota path="/administrativo/contas_a_receber" component={<ContasAReceberAdmin />} />
  <Rota path="/administrativo/faturamento" component={<Faturamento />} />
  <Rota path="/administrativo/cobranca" component={<CobrancaPg />} />
  <Rota path="/administrativo/perfil" component={<PerfilAdmin />} />
  <Rota path="/parceiro/ordem_de_servico" component={<OrdemServico />} />
  <Rota path="/parceiro/ordem_de_servico_detalhe" component={<OrdemServicoDetalhe />} />
  <Rota path="/parceiro/cobranca_detalhe" component={<CobrancaDetalhe />} />
  <Rota path="/parceiro/perfil" component={<Perfil />} />
</PrimeReactProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
