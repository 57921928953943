import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
// import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Component, createRef } from "react";
import logo from '../images/logo.png';
import Constante from "../utils/Constante";
import Util from "../utils/Util";
import { Rating } from "primereact/rating";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

export default class Avaliacao extends Component {
    toast = createRef();
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        documento: new Documento(),
        listaDocumentoItemRelacionamento: [],
        urlBase: undefined,
        carregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let parametros = this.util.buscarParametrosUrl();
        let urlBase = await this.util.buscarUrlBaseArquivo();

        await new Promise(resolve => this.setState({
            urlBase: urlBase,
        }, resolve));

        let retorno;

        retorno = await this.documentoService.buscar(this.constante.seqUsuario, parametros.id);
        let documento = retorno.objeto;

        retorno = await this.documentoService.listarDocumentoItem(documento);
        let listaDocumentoItem = retorno.objeto;

        let listaDocumentoItemRelacionamento = [];

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            retorno = await this.documentoService.listarRelacionamentoItem(listaDocumentoItem[i]);
            listaDocumentoItemRelacionamento = [...listaDocumentoItemRelacionamento, ...retorno.objeto];
        }

        for (let i = 0; i < listaDocumentoItemRelacionamento.length; i++) {
            if(!listaDocumentoItemRelacionamento[i].tagn0) {
                listaDocumentoItemRelacionamento[i].tagn0 = 5;
            }
        }

        this.setState({
            documento: documento,
            listaDocumentoItemRelacionamento: listaDocumentoItemRelacionamento,
        });
    }

    avaliar(index, qtdeEstrelas) {
        const vListaDocumentoItemRelacionamento = [...this.state.listaDocumentoItemRelacionamento];

        vListaDocumentoItemRelacionamento[index].tagn0 = qtdeEstrelas;

        this.setState({
            listaDocumentoItemRelacionamento: vListaDocumentoItemRelacionamento
        })
    }

    async salvar() {
        this.setState({ carregando: true });

        const vListaDocumentoItemRelacionamento = [...this.state.listaDocumentoItemRelacionamento];

        for (let i = 0; i < vListaDocumentoItemRelacionamento.length; i++) {
            const documentoItemRelacionamento = vListaDocumentoItemRelacionamento[i];

            await this.documentoService.salvarRelacionamentoItem(documentoItemRelacionamento);
        }

        this.toast.current.show({
            severity: 'success', summary: 'Sucesso!', detail: 'Informações armazenadas com sucesso!'
        })

        this.setState({ carregando: false });
    }

    render() {
        return <div style={{ padding: 10 }}>
            <Toast ref={this.toast} />

            <div style={{ display: 'flex', alignItems: 'center', gap: 10, border: '1px solid black', padding: 10 }}>
                <img
                alt=""
                    src={logo}
                    style={{ width: 150, objectFit: 'contain' }} />

                <div>
                    <small>OneWork Soluções Inteligentes</small><br />
                    <small>Endereco: Avenida Eusébio de Queiroz, 1450, Tamatanduba, Eusébio - CE</small><br />
                    <small>CNPJ: 45.669.894/0001-45</small>
                </div>
            </div>
            <div style={{ border: '1px solid black', borderTop: 'none', padding: 10 }}>
                <p style={{ fontSize: 12 }}>Dados do Evento</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Nº da Ordem de Serviço</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags6}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Casa de Festa</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags4}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Data</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarData(this.state.documento.dataProgramada)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Hora</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags1}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Quantidade de Profissionais</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.listaDocumentoItemRelacionamento.length}
                        </p>
                    </div>
                </div>
            </div>

            <DataTable
                header={() => (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button label="Salvar" onClick={() => this.salvar()} loading={this.state.carregando} />
                    </div>
                )}
                style={{ marginTop: 20 }}
                showGridlines
                value={this.state.listaDocumentoItemRelacionamento}>
                <Column
                    header="Foto"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="_pessoaRelacionadaFoto"
                    body={pDocumentoItemRelacionamento => <img alt=""
                        src={this.state.urlBase + pDocumentoItemRelacionamento._pessoaRelacionadaFoto}
                        style={{ width: 40, height: 40, borderRadius: 500, objectFit: 'contain' }} />} />
                <Column
                    header="Profissional"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field="_pessoaRelacionadaNome"
                    body={pDocumentoItemRelacionamento => <p style={{ fontSize: 10, }}>
                        {pDocumentoItemRelacionamento._pessoaRelacionadaNome}
                    </p>} />
                <Column
                    header="Estrelas"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field=""
                    body={(pDocumentoItemRelacionamento, { rowIndex }) => <Rating stars={5} cancel={false} onChange={(e) => this.avaliar(rowIndex, e.value)} value={pDocumentoItemRelacionamento?.tagn0 || 0} />}
                />
                <Column
                    header="Comentário"
                    headerStyle={{ fontSize: 10, fontWeight: 'bold' }}
                    field=""
                    body={pDocumentoItemRelacionamento => <InputText style={{ width: '100%' }} onChange={e => pDocumentoItemRelacionamento.tags0 = e.target.value} defaultValue={pDocumentoItemRelacionamento.tags0 || ''} />} />
            </DataTable>
        </div>
    }
}