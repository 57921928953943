import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import UtilApi from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';


export default class Util {

    constante = new Constante();
    utilApi = new UtilApi(this.constante.token, this.constante.urlBase);

    async buscarUrlBaseArquivo() {
        let retorno = await this.utilApi.buscarUrlBaseArquivo();
        retorno = await retorno.json();
        return retorno;
    }

    buscarParametrosUrl() {
        if (!window.location.search) {
            return null;
        }

        let query = window.location.search.slice(1);
        let partes = query.split("&");
        let data = {};
        partes.forEach(function (parte) {
            let chaveValor = parte.split("=");
            let chave = chaveValor[0];
            let valor = chaveValor[1];
            data[chave] = valor;
        });

        return data;
    }

    enviarEmailAcesso(pPessoa) {
        this.utilApi.enviarEmail({
            seqEmail: this.constante.seqEmailAcesso,
            email: pPessoa.emailPlataformaLirida,
            parametro: {
                nome: pPessoa.nome,
                email: pPessoa.emailPlataformaLirida,
                senha: pPessoa.senha
            }
        });
    }

    enviarEmailAceiteCliente(pDocumento) {
        this.utilApi.enviarEmail({
            seqEmail: this.constante.seqEmailAceiteCliente,
            email: pDocumento._pessoaEmail,
            parametro: {
                cliente: pDocumento._pessoaNome,
                link: window.location.origin + '/aceite_cliente?id=' + pDocumento._seqDocumento,
                parceiro: pDocumento._vendedorNome,
            }
        });
    }

    async enviarEmailAprovacaoProposta(documento) {

        console.log(documento);
        this.retorno = await this.utilApi.enviarEmail({
            seqEmail: this.constante.seqEmailAceiteProposta,
            email: documento.email_aprovador,
            // email: "newtelesto@gmail.com",
            parametro: {
                unidade: documento._vendedorNome,
                numero_os: `${documento.tags6}`,
                seqDocumento: `onework.app.br/imprimir_ficha_profissional?id=${documento._seqDocumento}&ap=S`,
            }
        });
        

    }

    async listarLista(pSeqUsuario, pID) {
        this.retorno = await this.utilApi.listarLista(pSeqUsuario, pID);
        this.retorno = this.retorno.json();
        return this.retorno;
    }

    async buscarLocalStorage(pCampo) {
        let retorno = localStorage.getItem(pCampo);
        retorno = await JSON.parse(retorno);
        // 
        return retorno;
    }

    salvarLocalStorage(pCampo, pValor) {
        let valor = JSON.stringify(pValor);
        localStorage.setItem(pCampo, valor);
        console.log(pCampo + ' salvo localstorage com sucesso')
    }

    async removerLocalStorage(pCampo) {
        let retorno = localStorage.removeItem(pCampo);
        return retorno;
    }

    formatarTelefone(value) {
        let r = value.replace(/\D/g, '');
        r = r.replace(/^0/, '');

        if (r.length > 11) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else if (r.length > 7) {
            r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else if (value.trim() !== '') {
            r = r.replace(/^(\d*)/, '($1');
        }

        return r;
    }

    formatarHora(pHora) {
        let hora = pHora.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1:$2')
            .replace(/(:\d{2})\d+?$/, '$1')
        console.log(hora)
        return hora
    }

    async validarDocumento(pDocumento) {
        this.retorno = await this.utilApi.validarDocumento(pDocumento);
        this.retorno = await this.retorno.json();
        return this.retorno;
    }

    async pegarBase64(pArquivo) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsDataURL(pArquivo);
            reader.onload = () => {
                let base64 = reader.result;
                base64 = base64.split(",")[1];
                resolve(base64);
            };
            reader.onerror = error => reject(error);
        })
    }

    async listarBanco(pConteudo) {
        this.listaBanco = await this.utilApi.listarBanco(pConteudo);
        this.listaBanco = await this.listaBanco.json();
        return this.listaBanco;
    }

    formatarCEP(pTexto) {
        this.texto = pTexto;
        return this.texto.replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1')
    }

    formatarCPF(pTexto) {
        let t = pTexto;
        if (t.length <= 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
        }
    }

    formatarCNPJ(pTexto) {
        let t = pTexto;

        return t.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }

    formatarData(pData) {
        if (!pData) {
            return ''
        }

        this.data = String(pData);
        this.data = this.data.split('T')[0];
        this.data = this.data.replace('-', '/').replace('-', '/');
        return this.data;
    }

    // formatarDataComHora(pData) {
    //     if (!pData) {
    //         return ''
    //     }

    //     let data = String(pData)
    //     data = data.split('T')
    //     let hora = data[1]

    //     let listaData = data[0].split('-')

    //     data = new Date();
    //     data.setDate(listaData[0]);
    //     data.setMonth(listaData[1] - 1);
    //     data.setFullYear(listaData[2]);

    //     data = data.toLocaleString('pt-br')

    //     return `${data} - ${hora}`
    // }


    formatarValor(value) {
        if (value) {
            this.valorFormatado = Number(value).toLocaleString(
                'pt-BR',
                { style: 'currency', currency: 'BRL' }
            );
        } else {
            this.valorFormatado = Number(0).toLocaleString(
                'pt-BR',
                { style: 'currency', currency: 'BRL' }
            );
        }
        return this.valorFormatado;
    };

    validarEmail(pEmail) {
        let emailRegex = /\S+@\S+\.\S+/;

        return emailRegex.test(pEmail);
    }

    validarTelefone(pTelefone) {
        let telefoneRegex = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;

        return telefoneRegex.test(pTelefone);
    }

    validarCep(pCep) {
        return /^[0-9]{5}-[0-9]{3}$/.test(pCep);
    }

    // async pegarBase64(pArquivo) {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();

    //         reader.readAsDataURL(pArquivo);
    //         reader.onload = () => {
    //             let base64 = reader.result;
    //             base64 = base64.split(",")[1];
    //             resolve(base64);
    //         };
    //         reader.onerror = error => reject(error);
    //     })
    // }

    formatarDataBancoParaObjetoData(pData) {
        if (!pData) {
            return pData;
        }

        let listaData = pData.split('T')[0].split("-");

        let data = new Date();
        data.setDate(listaData[0]);
        data.setMonth(listaData[1] - 1);
        data.setFullYear(listaData[2]);

        return data;
    }

    formatarObjetoDataParaDataBanco(pData) {

        if (!pData) {
            return pData;
        }
        return pData.toLocaleDateString("pt-br").split("/").join("-") + 'T00:00:00';
    }

    exportarExcel(excelData, fileName) {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
}