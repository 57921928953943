import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";

class Banco {
    _codigo;
    _nome;
    _seqBanco;
}

export default class Perfil extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        urlBase: '',
        banco: new Banco(),
        listaBanco: [],
        carregando: false,
        enderecoCarregando: false,
        imagemCarregando: false,
        pessoaUsuario: new Pessoa(),
        endereco: new Endereco(),
        dadosBancariosAlterado: false,
        inputError: {
            nome: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            estado: false,
            uf: false,
            responsavelNome: false,
            responsavelDocumento: false,
            banco: false,
            agencia: false,
            digitoAgencia: false,
            conta: false,
            digitoConta: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaEndereco = [];
        let endereco = new Endereco();
        let banco = new Banco();

        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let urlBase = await this.util.buscarUrlBaseArquivo();
        let listaBanco = await this.util.listarBanco('');

        if (pessoaUsuario.contaCodigoBanco) {
            for (let i = 0; i < listaBanco.length; i++) {
                if (listaBanco[i]._codigo === pessoaUsuario.contaCodigoBanco) {
                    banco = listaBanco[i];
                }
            }
        }

        let retorno = await this.pessoaService.listarEndereco(pessoaUsuario);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                endereco = listaEndereco[i];
            }
        }

        this.setState({
            pessoaUsuario: pessoaUsuario,
            endereco: endereco,
            urlBase: urlBase,
            banco: banco,
            listaBanco: listaBanco
        });
    }

    async salvar() {
        let pessoaUsuario = this.state.pessoaUsuario;
        let endereco = this.state.endereco;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!pessoaUsuario.nome) {
            inputError.nome = true;
        }

        if (!pessoaUsuario.tipoDocumento) {
            inputError.tipoDocumento = true;
        }

        if (!pessoaUsuario.documento) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CPF' && pessoaUsuario.documento.length !== 14) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CNPJ' && pessoaUsuario.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(pessoaUsuario.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.util.validarTelefone(pessoaUsuario.telefonePlataformaLirida)) {
            inputError.telefone = true;
        }

        if (!endereco.cep) {
            inputError.cep = true;
        }

        if (!this.util.validarCep(endereco.cep)) {
            inputError.cep = true;
        }

        if (!endereco.logradouro) {
            inputError.logradouro = true;
        }

        if (!endereco.numero) {
            inputError.numero = true;
        }

        if (!endereco.bairro) {
            inputError.bairro = true;
        }

        if (!endereco.cidade) {
            inputError.cidade = true;
        }

        if (!endereco.estado) {
            inputError.estado = true;
        }

        if (!endereco.uf) {
            inputError.uf = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                console.log(pKey)
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ carregando: true });

        pessoaUsuario.seqUsuario = this.constante.seqUsuario;
        pessoaUsuario.seqPessoaPerfil = this.constante.seqPessoaPerfilParceiro;

        let retorno = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retorno.objeto;

        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = pessoaUsuario._seqPessoa;
        endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;

        retorno = await this.pessoaService.salvarEndereco(endereco);
        endereco = retorno.objeto;

        pessoaUsuario.seqEnderecoPrincipal = endereco._seqEndereco;

        if (this.state.dadosBancariosAlterado) {
            retorno = await this.pessoaService.salvarContaBanco(pessoaUsuario);
            pessoaUsuario = retorno.objeto;
        }

        retorno = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retorno.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        })

        this.setState({
            pessoaUsuario: pessoaUsuario,
            endereco: endereco,
            carregando: false,
        });
    }

    async buscarCep() {
        let endereco = this.state.endereco;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!endereco.cep) {
            inputError.cep = true;
        }

        if (!this.util.validarCep(endereco.cep)) {
            inputError.cep = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ enderecoCarregando: true });

        let retorno = await this.cepService.buscarCep(this.state.endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        this.setState({
            endereco: endereco,
            enderecoCarregando: false,
        });
    }

    async alterarImagem(pArquivo) {
        let pessoa = new Pessoa();
        let pessoaUsuario = this.state.pessoaUsuario;

        this.setState({ imagemCarregando: true });

        pessoa = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        pessoa.foto = await this.util.pegarBase64(pArquivo);

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        pessoaUsuario.foto = pessoa.foto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            imagemCarregando: false
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <HeaderSistemaAdmin />

            <main style={{ padding: 20, paddingBottom: 200 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Perfil
                    </h1>

                    <Button
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        label="Salvar"
                        onClick={() => this.salvar()} />
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: 'var(--primary-color)' }} /> Dados Pessoais
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <div>
                            <img alt=""
                                style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                                src={this.state.urlBase + this.state.pessoaUsuario.foto} />
                        </div>

                        <div>
                            <input
                                type="file" id="imagem_item" style={{ display: 'none' }}
                                onChange={pEvento => this.alterarImagem(pEvento.target.files[0])} />
                            {this.state.imagemCarregando ?
                                <Button
                                    disabled={this.state.imagemCarregando}
                                    loading={this.state.imagemCarregando}
                                    icon="pi pi-camera"
                                    label="Alterar imagem" />
                                :
                                <label
                                    htmlFor="imagem_item"
                                    style={{ padding: 10, borderRadius: 5, backgroundColor: 'var(--primary-color)', cursor: 'pointer', color: "white", }}
                                >
                                    <i className="pi pi-camera"></i> Alterar imagem
                                </label>}
                        </div>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.nome} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                style={{ width: '100%', borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                options={[
                                    { label: 'Pessoa Física', value: 'CPF' },
                                    { label: 'Pessoa Jurídica', value: 'CNPJ' },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.tipoDocumento} />
                        </div>
                        {this.state.pessoaUsuario.tipoDocumento === "CPF" &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento} />
                            </div>}
                        {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento} />
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                disabled
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.emailPlataformaLirida} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        telefonePlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.telefonePlataformaLirida} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: 'var(--primary-color)' }} /> Endereço
                </h3>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ width: '100%' }}>
                        <label>CEP</label><br />
                        <div className="p-inputgroup">
                            <InputText
                                value={this.state.endereco.cep || ''}
                                type="tel"
                                style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                                onChange={pEvento => this.setState({
                                    endereco: {
                                        ...this.state.endereco,
                                        cep: pEvento.target.value
                                    }
                                })}
                            />
                            <Button
                                disabled={this.state.enderecoCarregando}
                                loading={this.state.enderecoCarregando}
                                icon="pi pi-search"
                                type="button"
                            />
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Logradouro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    logradouro: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.logradouro || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Número</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    numero: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.numero || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Complemento</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    complemento: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.complemento || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Bairro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    bairro: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.bairro || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Cidade</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    cidade: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.cidade || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Estado</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.estado && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    estado: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.estado || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>UF</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    uf: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.uf || ''} />
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'baseline', gap: 20 }}>
                    <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                        <i className="pi pi-dollar" style={{ color: 'var(--primary-color)' }} /> Dados bancários
                    </h3>

                    {this.state.pessoaUsuario.contaS2pCodigo && <p style={{ marginTop: 10 }}>
                        <i className="pi pi-check" style={{ color: 'green' }} /> Conta cadastrada
                    </p>}
                </div>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 10 }}>
                    <div style={{ width: '100%' }}>
                        <label>Nome do responsável</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.responsavelNome && 'red' }}
                            onChange={pEvento => this.setState({
                                dadosBancariosAlterado: true,
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaResponsavelNome: pEvento.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.contaResponsavelNome} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>CPF do responsável</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.responsavelDocumento && 'red' }}
                            onChange={pEvento => this.setState({
                                dadosBancariosAlterado: true,
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaResponsavelCpf: pEvento.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.contaResponsavelCpf} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Banco</label><br />
                        <Dropdown
                            style={{ width: '100%', borderColor: this.state.inputError.banco && 'red' }}
                            optionLabel="_nome"
                            onChange={pEvento => this.setState({
                                dadosBancariosAlterado: true,
                                banco: pEvento.value,
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaCodigoBanco: pEvento.value._seqBanco
                                }
                            })}
                            options={this.state.listaBanco}
                            value={this.state.banco} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Agência</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.agencia && 'red' }}
                            onChange={pEvento => this.setState({
                                dadosBancariosAlterado: true,
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaAgencia: pEvento.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.contaAgencia} />
                    </div>
                    <div style={{ width: '100%', borderColor: this.state.inputError.digitoAgencia && 'red' }}>
                        <label>Dígito da agência</label><br />
                        <InputText
                            style={{ width: '100%' }}
                            onChange={pEvento => this.setState({
                                dadosBancariosAlterado: true,
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaDigitoAgencia: pEvento.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.contaDigitoAgencia} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Conta</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.conta && 'red' }}
                            onChange={pEvento => this.setState({
                                dadosBancariosAlterado: true,
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaNumeroConta: pEvento.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.contaNumeroConta} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Dígito da conta</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.digitoConta && 'red' }}
                            onChange={pEvento => this.setState({
                                dadosBancariosAlterado: true,
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    contaDigitoConta: pEvento.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.contaDigitoConta} />
                    </div>
                </div>
            </main >
        </div >
    }
}