import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import logo from '../images/logo.png';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import HeaderHotsite from "../components/HeaderHotsite";
import FooterHotsite from "../components/FooterHotsite";
import Constante from "../utils/Constante";
import Util from "../utils/Util";


export default class Login extends Component {

    mensagens = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        email: '',
        senha: '',
        inputError: {
            email: false,
            senha: false,
        },
        carregando: false,
    }

    async validarAcesso() {
        let listaPesquisa = [];
        let listaPessoa = [];
        let pessoa = new Pessoa();
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.email) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(this.state.email)) {
            inputError.email = true;
        }

        if (!this.state.senha) {
            inputError.senha = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.email_plataforma_lirida";
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND pessoa.seq_pessoa_perfil in ('" + this.constante.seqPessoaPerfilAdministrador + "', '" + this.constante.seqPessoaPerfilParceiro + "' )"
        pesquisa.conteudo = this.constante.seqPessoaPerfilParceiro;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoa = retorno.objeto;

        pessoa = listaPessoa[0];
        
        if (!pessoa) {
            this.mensagens.current.show({
                severity: "error", summary: "Email inválido"
            });
            return;
        }

        if (this.state.senha !== pessoa.senha) {
            this.mensagens.current.show({
                severity: "error", summary: "Senha inválida"
            });
            return;
        }

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);

        if (pessoa._seqPessoa === this.constante.seqPessoaAdministrador) {
            window.location.href = '/administrativo/ordem_de_servico';
            return;
        }

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilParceiro) {
            window.location.href = '/parceiro/ordem_de_servico';
            return;
        }
    }

    render() {
        return <div>
            <HeaderHotsite />

            <main style={{ width: '95%', maxWidth: 1000, margin: "0 auto", padding: '100px 0', display: 'flex', alignItems: 'center', gap: 45 }}>
                <div style={{ width: '50%', height: '20%' }}>
                    <img 
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        src={logo}
                        alt="OneWork" />

                </div>

                <form
                    onSubmit={e => { e.preventDefault(); this.validarAcesso() }}
                    style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>

                    <Messages ref={this.mensagens} />

                    <h1>Login</h1>

                    <div>
                        <label>Email</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                            type="email"
                            onChange={pEvento => this.setState({
                                email: pEvento.target.value
                            })}
                            value={this.state.email} />
                        {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                    </div>

                    <div>
                        <label>Senha</label><br />
                        <InputText
                            style={{ width: '100%', borderColor: this.state.inputError.senha && 'red' }}
                            type='password'
                            onChange={pEvento => this.setState({
                                senha: pEvento.target.value
                            })}
                            value={this.state.senha} />
                        {this.state.inputError.senha && <small style={{ color: 'red' }}>Senha inválido</small>}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button
                            label="Recuperar senha"
                            type="button"
                            className="p-button-link"
                            style={{ padding: 0 }}
                            onClick={() => window.location.href = '/recuperar_senha'} />
                        <Button
                            label="Entrar"
                            type="submit"
                            loading={this.state.carregando} />
                    </div>
                </form>
            </main>

            <FooterHotsite />
        </div>
    }
}