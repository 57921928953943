import { Button } from "primereact/button";
import { Component } from "react";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { InputText } from "primereact/inputtext";
import TabelaPreco from "lirida-back-service/Servicos/TabelaPreco/TabelaPreco";
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import { Dropdown } from "primereact/dropdown";
import { Steps } from 'primereact/steps';
import { Calendar } from "primereact/calendar";

export default class Parceiro extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.urlBase);
    cepService = new CepService(this.constante.token, this.constante.urlBase);

    state = {
        pessoa: new Pessoa(),
        endereco: new Endereco(),
        tabelaPreco: new TabelaPreco(),
        listaPessoa: [],
        listaTabelaPreco: [],
        carregando: false,
        salvarCarregando: false,
        enderecoCarregando: false,
        dialogNovoParceiroVisivel: false,
        indexStepAtivo: 0,
        inputError: {
            nome: false,
            apelido: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
            tabelaPreco: false,
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            estado: false,
            uf: false,
        },
        conteudoNome: '',
        conteudoEmail: '',
        dataCadastroInicial: undefined,
        dataCadastroFinal: undefined,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let retorno = await this.tabelaPrecoService.listar(this.constante.seqUsuario);
        let listaTabelaPreco = retorno.objeto;

        this.setState({
            listaTabelaPreco: listaTabelaPreco,
        })
    }

    async listar() {
        let listaPesquisa = [];
        let listaPessoa = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilParceiro;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        if (this.state.conteudoNome) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.nome';
            pesquisa.conteudo = this.state.conteudoNome;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.conteudoEmail) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.email_plataforma_lirida';
            pesquisa.conteudo = this.state.conteudoEmail;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroInicial.toLocaleDateString('pt-br') + 'T00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.dataCadastroFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'pessoa.data_cadastro';
            pesquisa.conteudo = this.state.dataCadastroFinal.toLocaleDateString('pt-br') + 'T00:00:00';
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoa = retorno.objeto;

        for (let i = 0; i < listaPessoa.length; i++) {
            listaPessoa[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaPessoa[i].dataCadastro);
        }

        this.setState({
            carregando: false,
            listaPessoa: listaPessoa,
        })
    }

    async buscarCep() {
        let endereco = this.state.endereco;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!endereco.cep) {
            inputError.cep = true;
        }

        if (!this.util.validarCep(endereco.cep)) {
            inputError.cep = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ enderecoCarregando: true });

        let retorno = await this.cepService.buscarCep(this.state.endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        this.setState({
            endereco: endereco,
            enderecoCarregando: false,
        });
    }

    async salvar() {
        let pessoa = this.state.pessoa;
        let pessoaAdministrador = new Pessoa();
        let endereco = this.state.endereco;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (this.state.indexStepAtivo === 0) {
            if (!pessoa.nome) {
                inputError.nome = true;
            }

            if (!pessoa.apelido) {
                inputError.apelido = true;
            }

            if (!pessoa.tipoDocumento) {
                inputError.tipoDocumento = true;
            }

            if (!pessoa.documento) {
                inputError.documento = true;
            } else if (pessoa.tipoDocumento === 'CPF' && pessoa.documento.length !== 14) {
                inputError.documento = true;
            } else if (pessoa.tipoDocumento === 'CNPJ' && pessoa.documento.length !== 18) {
                inputError.documento = true;
            }

            if (!pessoa.emailPlataformaLirida) {
                inputError.email = true;
            }

            if (!this.util.validarEmail(pessoa.emailPlataformaLirida)) {
                inputError.email = true;
            }

            if (!pessoa.telefonePlataformaLirida) {
                inputError.telefone = true;
            }

            if (!this.util.validarTelefone(pessoa.telefonePlataformaLirida)) {
                inputError.telefone = true;
            }

            if (!pessoa.seqTabelaPreco) {
                inputError.tabelaPreco = true;
            }
        }

        if (this.state.indexStepAtivo === 1) {
            if (!endereco.cep) {
                inputError.cep = true;
            }

            if (!endereco.logradouro) {
                inputError.logradouro = true;
            }

            if (!endereco.numero) {
                inputError.numero = true;
            }

            if (!endereco.bairro) {
                inputError.bairro = true;
            }

            if (!endereco.cidade) {
                inputError.cidade = true;
            }

            if (!endereco.estado) {
                inputError.estado = true;
            }

            if (!endereco.uf) {
                inputError.uf = true;
            }
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        if (this.state.indexStepAtivo === 0) {
            this.setState({ indexStepAtivo: 1 });
            return;
        }

        this.setState({ salvarCarregando: true });

        let retorno = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador, this.constante.seqUsuario);
        pessoaAdministrador = retorno.objeto;

        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilParceiro;
        pessoa.tipoTaxaBoleto = pessoaAdministrador.tipoTaxaBoleto;
        pessoa.tipoTaxaCredito = pessoaAdministrador.tipoTaxaCredito;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;
        pessoa.tipoTaxaPix = pessoaAdministrador.tipoTaxaPix;

        retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        

        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = pessoa._seqPessoa;
        endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;

        retorno = await this.pessoaService.salvarEndereco(endereco);
        endereco = retorno.objeto;

        pessoa.seqEnderecoPrincipal = endereco._seqEndereco;

        retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        this.util.enviarEmailAcesso(pessoa);

        window.open('/administrativo/parceiro_detalhe?id=' + pessoa._seqPessoa);

        this.setState({
            dialogNovoParceiroVisivel: false,
            pessoa: new Pessoa(),
            endereco: new Endereco(),
            salvarCarregando: false,
        });
    }

    async exportarExcel() {
        let lista = [];
        let listaPessoa = this.state.listaPessoa;

        for (let i = 0; i < listaPessoa.length; i++) {
            let item = {};

            item["Nome do Parceiro"] = listaPessoa[i].nome;
            item["Email do Parceiro"] = listaPessoa[i].emailPlataformaLirida;
            item["Documento do Parceiro"] = listaPessoa[i].documento;
            item["Data de Cadastro"] = listaPessoa[i].dataCadastro;

            lista.push(item);
        }

        this.util.exportarExcel(lista, "Lista de Parceiros ( OneWork )")
    }

    render() {
        return <div>
            <Dialog
                header="Novo Parceiro"
                onHide={() => this.setState({
                    dialogNovoParceiroVisivel: false,
                })}
                visible={this.state.dialogNovoParceiroVisivel}>

                <Steps
                    model={[{ label: 'Parceiro' }, { label: 'Local de Serviço' }]}
                    activeIndex={this.state.indexStepAtivo} />
                <form
                    style={{ maxWidth: 1000 }}
                    onSubmit={pEvento => { pEvento.preventDefault(); this.salvar(); }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10 }}>
                        {this.state.indexStepAtivo === 0 && <>
                            <div style={{ width: '100%' }}>
                                <label>Nome</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.nome && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            nome: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoa.nome} />
                                {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Nome Fantasia</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.apelido && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            apelido: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoa.apelido} />
                                {this.state.inputError.apelido && <small style={{ color: 'red' }}>Nome fantasia inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Tipo de pessoa</label><br />
                                <Dropdown
                                    style={{ width: '100%', borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                    options={[
                                        { label: 'Pessoa Física', value: 'CPF' },
                                        { label: 'Pessoa Jurídica', value: 'CNPJ' },
                                    ]}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            tipoDocumento: pEvento.value
                                        }
                                    })}
                                    value={this.state.pessoa.tipoDocumento} />
                                {this.state.inputError.tipoDocumento && <small style={{ color: 'red' }}>Tipo de documento inválido</small>}
                            </div>
                            {this.state.pessoa.tipoDocumento === "CPF" &&
                                <div style={{ width: '100%' }}>
                                    <label>CPF</label><br />
                                    <InputText
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                documento: this.util.formatarCPF(pEvento.target.value)
                                            }
                                        })}
                                        value={this.state.pessoa.documento} />
                                    {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                                </div>}
                            {this.state.pessoa.tipoDocumento === "CNPJ" &&
                                <div style={{ width: '100%' }}>
                                    <label>CNPJ</label><br />
                                    <InputText
                                        style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                        onChange={pEvento => this.setState({
                                            pessoa: {
                                                ...this.state.pessoa,
                                                documento: this.util.formatarCNPJ(pEvento.target.value)
                                            }
                                        })}
                                        value={this.state.pessoa.documento} />
                                    {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                                </div>}
                            <div style={{ width: '100%' }}>
                                <label>Email</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            emailPlataformaLirida: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoa.emailPlataformaLirida} />
                                {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Telefone</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.telefonePlataformaLirida} />
                                {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Tabela de preços</label><br />
                                <Dropdown
                                    style={{ width: '100%', borderColor: this.state.inputError.tabelaPreco && 'red' }}
                                    options={this.state.listaTabelaPreco}
                                    optionLabel="nome"
                                    onChange={pEvento => this.setState({
                                        tabelaPreco: pEvento.value,
                                        pessoa: {
                                            ...this.state.pessoa,
                                            seqTabelaPreco: pEvento.value._seqTabelaPreco
                                        }
                                    })}
                                    value={this.state.tabelaPreco} />
                                {this.state.inputError.tabelaPreco && <small style={{ color: 'red' }}>Tabela de preço inválida</small>}
                            </div>
                        </>}

                        {this.state.indexStepAtivo === 1 && <>
                            <div style={{ width: '100%' }}>
                                <label>CEP</label><br />
                                <div className="p-inputgroup">
                                    <InputText
                                        onChange={pEvento => this.setState({
                                            endereco: {
                                                ...this.state.endereco,
                                                cep: this.util.formatarCEP(pEvento.target.value),
                                            }
                                        })}
                                        value={this.state.endereco.cep || ''}
                                        type="tel"
                                        style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                                    />
                                    <Button
                                        disabeld={this.state.enderecoCarregando}
                                        loading={this.state.enderecoCarregando}
                                        icon="pi pi-search"
                                        type="button"
                                        onClick={() => this.buscarCep()}
                                    />
                                </div>
                                {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Logradouro</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                                    onChange={pEvento => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            logradouro: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.endereco.logradouro || ''} />
                                {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Número</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                                    onChange={pEvento => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            numero: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.endereco.numero || ''} />
                                {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Complemento</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.complemento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            complemento: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.endereco.complemento || ''} />
                                {this.state.inputError.complemento && <small style={{ color: 'red' }}>Complemento inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Bairro</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                                    onChange={pEvento => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            bairro: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.endereco.bairro || ''} />
                                {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Cidade</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                                    onChange={pEvento => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            cidade: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.endereco.cidade || ''} />
                                {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Estado</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.estado && 'red' }}
                                    onChange={pEvento => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            estado: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.endereco.estado || ''} />
                                {this.state.inputError.estado && <small style={{ color: 'red' }}>Estado inválido</small>}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>UF</label><br />
                                <InputText
                                    style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                                    onChange={pEvento => this.setState({
                                        endereco: {
                                            ...this.state.endereco,
                                            uf: pEvento.target.value,
                                        }
                                    })}
                                    value={this.state.endereco.uf || ''} />
                                {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválido</small>}
                            </div>
                        </>}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button
                            disabled={this.state.salvarCarregando}
                            loading={this.state.salvarCarregando}
                            type="submit"
                            label={this.state.indexStepAtivo === 1 ? 'Salvar' : 'Avançar'} />
                    </div>
                </form>
            </Dialog>

            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Parceiros
                    </h1>

                    <Button
                        label="Novo parceiro"
                        onClick={() => this.setState({
                            dialogNovoParceiroVisivel: true,
                            pessoa: new Pessoa(),
                            endereco: new Endereco(),
                            indexStepAtivo: 0,
                        })} />
                </div>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: 'var(--primary-color)' }} /> Filtros
                        </h3>

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 20 }}>
                            <div style={{ width: "100%" }}>
                                <label>Nome</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudoNome: pEvento.target.value
                                    })}
                                    value={this.state.conteudoNome} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Email</label><br />
                                <InputText
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        conteudoEmail: pEvento.target.value
                                    })}
                                    value={this.state.conteudoEmail} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro inicial</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroInicial: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroInicial} />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Data de cadastro final</label><br />
                                <Calendar
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        dataCadastroFinal: pEvento.value,
                                    })}
                                    value={this.state.dataCadastroFinal} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            label="Buscar"
                            onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    selectionMode="single"
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    value={this.state.listaPessoa}
                    header={
                        <div>
                            <Button
                                type="button"
                                icon="pi pi-file-excel"
                                onClick={() => this.exportarExcel()}
                                className="p-button-success mr-2"
                                data-pr-tooltip="XLS" />
                        </div>
                    }
                    onSelectionChange={pEvento => window.open('/administrativo/parceiro_detalhe?id=' + pEvento.value._seqPessoa)}>
                    <Column
                        header="Parceiro"
                        field="nome"
                        sortable />
                    <Column
                        header="Email"
                        field="emailPlataformaLirida"
                        sortable />
                    <Column
                        header="Documento"
                        field="documento"
                        sortable />
                    <Column
                        header="Perfil"
                        field="_perfilNome"
                        sortable />
                    <Column
                        header="Data de cadastro"
                        field="dataCadastro"
                        body={pPessoa => <p>
                            {pPessoa.dataCadastro?.toLocaleDateString("pt-br")}
                        </p>}
                        sortable />
                </DataTable>
            </main>
        </div >
    }
}