import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import logo from '../images/logo.png';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import HeaderHotsite from "../components/HeaderHotsite";
import FooterHotsite from "../components/FooterHotsite";
import Constante from "../utils/Constante";
import Util from "../utils/Util";


export default class RecuperarSenha extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    mensagens = createRef();
    mensagens2 = createRef();

    state = {
        email: '',
        senha: '',
        inputError: {
            email: false,
            senha: false,
        },
        carregando: false,
    }

    async recuperar() {
        let listaPesquisa = [];
        let listaPessoa = [];
        let pessoa = new Pessoa();
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.email) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(this.state.email)) {
            inputError.email = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.email_plataforma_lirida";
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND pessoa.seq_pessoa_perfil in ('" + this.constante.seqPessoaPerfilAdministrador + "', '" + this.constante.seqPessoaPerfilParceiro + "' )"
        pesquisa.conteudo = this.constante.seqPessoaPerfilParceiro;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        listaPessoa = retorno.objeto;

        pessoa = listaPessoa[0];

        if (!pessoa) {
            this.mensagens.current.show({
                severity: "error", summary: "Email inválido"
            });
            return;
        }

        this.util.enviarEmailAcesso(pessoa);

        this.mensagens2.current.show({
            severity: 'success', summary: "Enviamos um email com as suas credênciais de acesso! Redirecionando em 5 segundos...", sticky: true
        });

        setTimeout(() => window.location.href = '/login', 5000);
    }

    render() {
        return <div>
            <HeaderHotsite />

            <main style={{ width: '95%', maxWidth: 1000, margin: "0 auto", padding: '100px 0', }}>
                <Messages ref={this.mensagens2} />

                <div style={{ display: 'flex', alignItems: 'center', gap: 45 }}>
                    <div style={{ width: '50%', height: '20%' }}>
                        <img 
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            src={logo}
                            alt="OneWork" />

                    </div>

                    <form
                        onSubmit={e => { e.preventDefault(); this.recuperar() }}
                        style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>

                        <Messages ref={this.mensagens} />

                        <h1>Recuperar Senha</h1>

                        <div>
                            <label>Email</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                type="email"
                                onChange={pEvento => this.setState({
                                    email: pEvento.target.value
                                })}
                                value={this.state.email} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button
                                label="Voltar para tela de Login"
                                type="button"
                                className="p-button-link"
                                style={{ padding: 0 }}
                                onClick={() => window.location.href = '/login'} />
                            <Button
                                label="Recuperar"
                                type="submit"
                                loading={this.state.carregando} />
                        </div>
                    </form>
                </div>
            </main>

            <FooterHotsite />
        </div>
    }
}