import { Component, createRef } from "react";
import HeaderSistema from "../../components/HeaderSistema";
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Dropdown } from "primereact/dropdown";
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import TabelaPrecoItem from 'lirida-back-service/Servicos/TabelaPrecoItem/TabelaPrecoItem';
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";

export default class OrdemServicoDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        permitirAlterar: true,
        urlBase: '',
        documento: new Documento(),
        documentoProposta: new Documento(),
        pessoa: new Pessoa(),
        pessoaUsuario: new Pessoa(),
        enderecoCobranca: new Endereco(),
        enderecoEntrega: new Endereco(),
        tabelaPrecoItem: new TabelaPrecoItem(),
        documentoItem: new DocumentoItem(),
        status_proposta: null,
        listaDocumentoRelacionamento: [],
        listaDocumentoItem: [],
        listaDocumentoProposta: [],
        listaDocumentoItemProposta: [],
        listaDocumentoItemRelacionamentoProposta: [],
        listaTabelaPrecoItem: [],
        listaArquivo: [],
        dialogDocumentoPropostaDetalheVisivel: false,
        dialogDocumentoItemPropostaDetalheVisivel: false,
        dialogEscolherPropostaVisivel: false,
        dialogEnviarPropostaVisivel: false,
        dialogCancelarOrdemDeServico: false,
        dialogConfirmarCancelarOrdemDeServico: false,
        carregando: false,
        enderecoCobrancaCarregando: false,
        enderecoEntregaCarregando: false,
        salvarItemCarregando: false,
        enviarParaReceberPropostaCarregando: false,
        deletarCarregando: false,
        escolherPropostaCarregando: false,
        clonarEstaOrdemDeServicoCarregando: false,
        cancelarOrdemDeServicoCarregando: false,
        inputError: {
            nome: false,
            documento: false,
            email: false,
            telefone: false,
            enderecoCobrancaCep: false,
            enderecoCobrancaLogradouro: false,
            enderecoCobrancaNumero: false,
            enderecoCobrancaBairro: false,
            enderecoCobrancaCidade: false,
            enderecoCobrancaEstado: false,
            enderecoCobrancaUf: false,
            numeroContrato: false,
            horaEvento: false,
            dataEvento: false,
            numeroConvidado: false,
            observacao: false,
            enderecoEntregaCep: false,
            enderecoEntregaLogradouro: false,
            enderecoEntregaNumero: false,
            enderecoEntregaBairro: false,
            enderecoEntregaCidade: false,
            enderecoEntregaEstado: false,
            enderecoEntregaUf: false,
        },
        inputErrorAdicionar: {
            itens: false,
            quantidade: false,
        },
        inputErrorItem: {
            item: false,
            quantidade: false,
        },
        inputErrorCancelarOrdemServico: {
            observacao: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let documento = new Documento();
        let pessoaUsuario = new Pessoa();
        let pessoa = new Pessoa();
        let pessoaParceiro = new Pessoa();
        let enderecoCobranca = new Endereco();
        let enderecoEntrega = new Endereco();
        let listaEndereco = [];
        let listaPesquisa = [];
        let permitirAlterar = true;

        let parametro = this.util.buscarParametrosUrl();
        let urlBase = await this.util.buscarUrlBaseArquivo();
        pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento';
        pesquisa.conteudo = parametro.id;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        documento = retorno.objeto[0];

        documento.dataProgramada = this.util.formatarDataBancoParaObjetoData(documento.dataProgramada);

        if (pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrador) {
            retorno = await this.pessoaService.buscarPorSeqPessoa(documento.seqUsuarioVendedor, this.constante.seqUsuario);
            pessoaParceiro = retorno.objeto;
        } else {
            pessoaParceiro = pessoaUsuario;
        }

        await this.listarItem(documento);

        retorno = await this.pessoaService.buscarPorSeqPessoa(documento.seqPessoa, this.constante.seqUsuario);
        pessoa = retorno.objeto;

        retorno = await this.tabelaPrecoService.listarItem(pessoaParceiro.seqTabelaPreco, '', 'S', '');
        let listaTabelaPrecoItem = retorno.objeto;

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                enderecoCobranca = listaEndereco[i];
            }
        }

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
                enderecoEntrega = listaEndereco[i];
            }
        }

        await this.listarDocumentoProposta(documento);

        retorno = await this.documentoService.listarArquivo(documento);
        let listaArquivo = retorno.objeto;

        retorno = await this.documentoService.listarRelacionamento(documento);
        let listaDocumentoRelacionamento = retorno.objeto;

        if (
            documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoCancelada ||
            documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoArquivados ||
            documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoFinalizada ||
            documento.tagd0
        ) {
            permitirAlterar = false;
        }

        // if (){}else

        // permitirAlterar = true;

        this.setState({
            urlBase: urlBase,
            documento: documento,
            pessoa: pessoa,
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            listaDocumentoRelacionamento: listaDocumentoRelacionamento,
            listaArquivo: listaArquivo,
            listaTabelaPrecoItem: listaTabelaPrecoItem,
            pessoaUsuario: pessoaUsuario,
            permitirAlterar: permitirAlterar,
        })

    }

    async listarDocumentoProposta(pDocumento) {
        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento_origem';
        pesquisa.conteudo = pDocumento._seqDocumento;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_tipo_documento';
        pesquisa.conteudo = this.constante.seqTipoDocumentoProposta;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = ' AND documento.status in (2, 3, 4, 5)';
        pesquisa.conteudo = 2;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        let listaDocumentoProposta = retorno.objeto;

        this.setState({
            listaDocumentoProposta: listaDocumentoProposta,
        })
    }

    async salvar(pDocumento) {
        let pessoa = this.state.pessoa;
        let documento = this.state.documento;
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;

        if (pDocumento) {
            documento = pDocumento;
        }

        if (!await this.validarInputError()) {
            return;
        };

        this.setState({ carregando: true });

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        retorno = await this.pessoaService.salvarEndereco(enderecoCobranca);
        enderecoCobranca = retorno.objeto;

        retorno = await this.pessoaService.salvarEndereco(enderecoEntrega);
        enderecoEntrega = retorno.objeto;

        documento.dataProgramada = this.util.formatarObjetoDataParaDataBanco(documento.dataProgramada);

        retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        documento.dataProgramada = this.util.formatarDataBancoParaObjetoData(documento.dataProgramada);

        if (documento.seqTipoDocumentoProcesso !== this.constante.seqTipoDocumentoProcessoDigitada) {
            console.log(documento.tags7);
            await this.salvarDocumentoProposta(documento);
        }

        if (!pDocumento) {
            this.toast.current.show({
                severity: 'success', summary: "Informações armazenadas com sucesso!"
            })
        }

        this.setState({
            carregando: false,
            pessoa: pessoa,
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            documento: documento,
        });

        return documento;
    }

    async enviarParaReceberProposta() {
        let documento = this.state.documento;
        let listaDocumentoItem = this.state.listaDocumentoItem;

        if (!await this.validarInputError()) {
            return;
        }

        this.setState({ enviarParaReceberPropostaCarregando: true });

        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoRecebendoProposta;
        documento.tags3 = 'S';
        documento.tagd1 = new Date().toLocaleDateString("pt-br").split("/").join("-") + 'T00:00:00';

        documento = await this.salvar(documento);

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            listaDocumentoItem[i].seqTipoDocumentoProcesso = documento.seqTipoDocumentoProcesso;

            await this.documentoService.salvarItem(listaDocumentoItem[i]);
        }

        this.toast.current.show({
            severity: 'success', summary: "Informações armazenadas com sucesso!"
        })

        this.setState({ enviarParaReceberPropostaCarregando: false });
    }

    async selecionarProposta(pDocumentoProposta) {
        let retorno = await this.documentoService.listarDocumentoItem(pDocumentoProposta);
        let listaDocumentoItemProposta = retorno.objeto;

        this.setState({
            documentoProposta: pDocumentoProposta,
            listaDocumentoItemProposta: listaDocumentoItemProposta,
            dialogDocumentoPropostaDetalheVisivel: true,
        });
    }

    async selecionarItemProposta(pDocumentoItemProposta) {
        let retorno = await this.documentoService.listarRelacionamentoItem(pDocumentoItemProposta);
        let listaDocumentoItemRelacionamentoProposta = retorno.objeto;

        this.setState({
            listaDocumentoItemRelacionamentoProposta: listaDocumentoItemRelacionamentoProposta,
            dialogDocumentoItemPropostaDetalheVisivel: true,
        });
    }

    async buscarCep(pEndereco) {
        let endereco = new Endereco();
        let enderecoCobranca = this.state.enderecoCobranca;
        let enderecoEntrega = this.state.enderecoEntrega;
        let inputError = this.state.inputError;
        let inputsOK = true;

        endereco = pEndereco;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!endereco.cep) {
            inputError.cep = true;
        }

        if (!this.util.validarCep(endereco.cep)) {
            inputError.cep = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            this.setState({ enderecoCobrancaCarregando: true });
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            this.setState({ enderecoEntregaCarregando: true });
        }

        let retorno = await this.cepService.buscarCep(endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
            enderecoCobranca = endereco;
        }

        if (endereco.seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
            enderecoEntrega = endereco;
        }

        this.setState({
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            enderecoCobrancaCarregando: false,
            enderecoEntregaCarregando: false,
        });
    }

    async adicionar() {
        let documento = this.state.documento;
        let documentoItem = this.state.documentoItem;
        let listaDocumentoItem = [];
        let inputErrorAdicionar = this.state.inputErrorAdicionar
        let inputsOK = true

        this.setState({ salvarItemCarregando: true });

        Object.keys(inputErrorAdicionar).forEach(pKey => {
            inputErrorAdicionar[pKey] = false;
        })

        if (!documentoItem.seqItem) {
            inputErrorAdicionar.itens = true;
        }

        if (!documentoItem.quantidade) {
            inputErrorAdicionar.quantidade = true;
        }

        Object.keys(inputErrorAdicionar).forEach(pKey => {
            if (inputErrorAdicionar[pKey]) {
                inputsOK = false
            }
        })

        this.setState({ inputErrorAdicionar: inputErrorAdicionar })

        if (!inputsOK) {
            this.setState({ salvarItemCarregando: false });
            return
        }

        documentoItem.seqUsuario = this.constante.seqUsuario;
        documentoItem.seqDocumento = this.state.documento._seqDocumento;
        documentoItem.seqTipoDocumentoProcesso = this.state.documento.seqTipoDocumentoProcesso;
        documentoItem.valorTotal = documentoItem.quantidade * documentoItem.precoTabelaMaximo;

        let retorno = await this.documentoService.salvarItem(documentoItem);
        documentoItem = retorno.objeto;

        listaDocumentoItem = await this.listarItem(this.state.documento);

        let valorOS = 0;
        let valorLider = 0;
        let quantidadeTotalProfissional = 0;

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            valorOS = valorOS + (listaDocumentoItem[i].quantidade * listaDocumentoItem[i].precoTabelaMaximo);
            valorLider += (listaDocumentoItem[i].precoTabela || 0) * listaDocumentoItem[i].quantidade;
            quantidadeTotalProfissional = quantidadeTotalProfissional + listaDocumentoItem[i].quantidade;
        }

        documento.valorLiquido = valorOS;
        documento.valorTotal = valorOS;
        documento.tagn1 = valorLider;
        documento.tagn0 = quantidadeTotalProfissional;
        documento.dataProgramada = this.util.formatarObjetoDataParaDataBanco(documento.dataProgramada);

        retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        documento.dataProgramada = this.util.formatarDataBancoParaObjetoData(documento.dataProgramada);

        if (documento.seqTipoDocumentoProcesso !== this.constante.seqTipoDocumentoProcessoDigitada) {
            let listaPesquisa = [];
            let listaDocumentoProposta = [];

            let pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_usuario';
            pesquisa.conteudo = this.constante.seqUsuario;
            pesquisa.operacao = '';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);

            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_documento_origem';
            pesquisa.conteudo = documento._seqDocumento;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);

            let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
            listaDocumentoProposta = retorno.objeto;

            for (let i = 0; i < listaDocumentoProposta.length; i++) {
                let documentoItemProposta = new DocumentoItem();

                documentoItemProposta = { ...documentoItem };

                delete documentoItemProposta._seqDocumentoItem;
                documentoItemProposta.seqDocumento = listaDocumentoProposta[i]._seqDocumento;
                documentoItemProposta.status = listaDocumentoProposta[i].status;
                documentoItemProposta.tags5 = listaDocumentoProposta[i].tags5;

                await this.documentoService.salvarItem(documentoItemProposta);
            }

            await this.salvarDocumentoProposta(documento);
        }

        this.toast.current.show({
            severity: 'success', summary: "Informações armazenadas com sucesso!"
        })

        this.setState({
            tabelaPrecoItem: new TabelaPrecoItem(),
            documentoItem: new DocumentoItem(),
            documento: documento,
            salvarItemCarregando: false,
        })
    }

    async listarItem(pDocumento) {
        let listaDocumentoItem = [];

        let retorno = await this.documentoService.listarDocumentoItem(pDocumento);
        listaDocumentoItem = retorno.objeto;

        
        

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            listaDocumentoItem[i].dataCadastro = this.util.formatarDataBancoParaObjetoData(listaDocumentoItem[i].dataCadastro);
        }

        

        this.setState({
            listaDocumentoItem: listaDocumentoItem
        })

        return listaDocumentoItem;
    }

    async deletarItem(pDocumentoItem) {
        let listaDocumentoItem = [];
        let documento = this.state.documento;
        let documentoItem = new DocumentoItem();

        documentoItem = { ...pDocumentoItem };

        this.setState({ deletarCarregando: true });

        documentoItem.dataCadastro = this.util.formatarObjetoDataParaDataBanco(documentoItem.dataCadastro);

        await this.documentoService.deletarItem(documentoItem);

        listaDocumentoItem = await this.listarItem(this.state.documento);

        let valorOS = 0;
        let valorLider = 0;
        let quantidadeTotalProfissional = 0;

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            valorOS = valorOS + (listaDocumentoItem[i].quantidade * listaDocumentoItem[i].precoTabelaMaximo);
            valorLider += (listaDocumentoItem[i].precoTabela || 0) * listaDocumentoItem[i].quantidade;
            quantidadeTotalProfissional = quantidadeTotalProfissional + listaDocumentoItem[i].quantidade;
        }

        documento.valorLiquido = valorOS;
        documento.valorTotal = valorOS + valorLider;
        documento.tagn1 = valorLider;
        documento.tagn0 = quantidadeTotalProfissional;
        documento.dataProgramada = this.util.formatarObjetoDataParaDataBanco(documento.dataProgramada);

        let retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        documento.dataProgramada = this.util.formatarDataBancoParaObjetoData(documento.dataProgramada);

        if (documento.seqTipoDocumentoProcesso !== this.constante.seqTipoDocumentoProcessoDigitada) {
            let listaPesquisa = [];
            let listaDocumentoProposta = [];

            let pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_usuario';
            pesquisa.conteudo = this.constante.seqUsuario;
            pesquisa.operacao = '';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);

            pesquisa = new Pesquisa();
            pesquisa.campo = 'documento.seq_documento_origem';
            pesquisa.conteudo = documento._seqDocumento;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);

            let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
            listaDocumentoProposta = retorno.objeto;

            for (let i = 0; i < listaDocumentoProposta.length; i++) {
                let listaDocumentoItemProposta = [];

                let retorno = await this.documentoService.listarDocumentoItem(listaDocumentoProposta[i]);
                listaDocumentoItemProposta = retorno.objeto;

                for (let j = 0; j < listaDocumentoItemProposta.length; j++) {
                    if (
                        listaDocumentoItemProposta[j].seqItem === documentoItem.seqItem &&
                        listaDocumentoItemProposta[j].quantidade === documentoItem.quantidade
                    ) {
                        let listaDocumentoItemRelacionamentoProposta = [];

                        let retorno = await this.documentoService.listarRelacionamentoItem(listaDocumentoItemProposta[j]);
                        listaDocumentoItemRelacionamentoProposta = retorno.objeto;

                        for (let k = 0; k < listaDocumentoItemRelacionamentoProposta.length; k++) {
                            await this.documentoService.deletarRelacionamentoItem(listaDocumentoItemRelacionamentoProposta[k]);
                        }

                        await this.documentoService.deletarItem(listaDocumentoItemProposta[j]);
                        break;
                    }
                }
            }

            await this.salvarDocumentoProposta(documento);
        }

        this.setState({
            documento: documento,
            deletarCarregando: false,
        });
    }

    async escolherProposta() {
        let documento = this.state.documento;
        let listaDocumentoItemProposta = this.state.listaDocumentoItemProposta;
        let listaDocumentoProposta = this.state.listaDocumentoProposta;

        if (!await this.validarInputError()) {
            return;
        }

        this.setState({
            escolherPropostaCarregando: true,
            dialogEscolherPropostaVisivel: false,
        });

        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAguardandoAceiteCliente;
        // documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoFinalizada;
        documento = await this.salvar(documento);

        let documentoProposta = this.state.documentoProposta;

        documentoProposta.status = 3;

        let retorno = await this.documentoService.salvar(documentoProposta);
        documentoProposta = retorno.objeto;

        for (let i = 0; i < listaDocumentoItemProposta.length; i++) {
            listaDocumentoItemProposta[i].status = 5;
            listaDocumentoItemProposta[i].tags4 = documento._vendedorNome;
            listaDocumentoItemProposta[i].tags1 = documento.tags1;
            listaDocumentoItemProposta[i].tags6 = documento._codigo;
            listaDocumentoItemProposta[i].dataProgramada = this.util.formatarObjetoDataParaDataBanco(documento.dataProgramada);

            await this.documentoService.salvarItem(listaDocumentoItemProposta[i]);
        }

        for (let i = 0; i < listaDocumentoProposta.length; i++) {
            if (listaDocumentoProposta[i]._seqDocumento !== documentoProposta._seqDocumento) {
                listaDocumentoProposta[i].status = 4;
                listaDocumentoProposta[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoPropostaReprovada;

                await this.documentoService.salvar(listaDocumentoProposta[i]);
            }
        }

        this.listarDocumentoProposta(documento);

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        });

        this.setState({
            documento: documento,
            escolherPropostaCarregando: false,
            dialogDocumentoPropostaDetalheVisivel: false,
        })
    }

    async salvarDocumentoProposta(pDocumento) {
        /**********************************/
        // OLHO VIVO NESSA BAGAÇA
        /**********************************/

        // A PROPOSTA É UM DOCUMENTO ORIGINADO DA ORDEM DE SERVIÇO.
        // A MAIORIA DOS CAMPOS DA PROPOSTA DEVEM SER IGUAIS AOS DA ORDEM DE SERVIÇO.

        // LISTAGEM DAS PROPOSTAS
        let listaPesquisa = [];
        let listaDocumentoProposta = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'documento.seq_documento_origem';
        pesquisa.conteudo = pDocumento._seqDocumento;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        listaDocumentoProposta = retorno.objeto;

        // PARA QUE CADA CAMPO NÃO SEJA ALTERADO 1 A 1, A ORDEM DE SERVIÇO É CLONADA NOVAMENTE +
        // E É ALTERADO OS CAMPOS QUE A ORDEM DE SERVIÇO TEM QUE ALTERAR E É MANTIDO OS CAMPOS +
        // QUE A PROPOSTA TEM QUE TER DIFERENTE DA ORDEM DE SERVIÇO.
        // ESTE MÉTODO É PARECIDO COM O QUE É FEITO QUANDO O LÍDER ACEITA A ORDEM DE SERVIÇO NO APLICATIVO

        /******************************************************/
        // SE NÃO ENTENDEU, PARA PENSA E RACIOCINA
        // NÃO MEXER ANTES DE ENTENDER
        /******************************************************/

        for (let i = 0; i < listaDocumentoProposta.length; i++) {
            let documentoProposta = new Documento();

            documentoProposta = { ...pDocumento };

            delete documentoProposta._seqDocumento;

            documentoProposta._codigo = listaDocumentoProposta[i]._codigo;
            documentoProposta._seqDocumento = listaDocumentoProposta[i]._seqDocumento;
            documentoProposta.seqTipoDocumento = this.constante.seqTipoDocumentoProposta;
            documentoProposta.seqPessoa = listaDocumentoProposta[i].seqPessoa;
            documentoProposta.status = listaDocumentoProposta[i].status;
            documentoProposta.tags5 = pDocumento.seqPessoa;
            documentoProposta.tags6 = pDocumento._codigo;
            documentoProposta.seqDocumentoOrigem = pDocumento._seqDocumento;
            documentoProposta.dataProgramada = this.util.formatarObjetoDataParaDataBanco(pDocumento.dataProgramada);

            let retorno = await this.documentoService.salvar(documentoProposta);
            documentoProposta = retorno.objeto;

            if (documentoProposta._seqDocumento === this.state.documentoProposta._seqDocumento) {
                await new Promise(resolve => this.setState({
                    documentoProposta: documentoProposta,
                }, resolve));
            }
        }
    }

    enviarEmailAceiteCliente() {
        this.util.enviarEmailAceiteCliente(this.state.documento);

        this.toast.current.show({
            severity: 'success', summary: 'Proposta enviada com sucesso!'
        });
    }

    async enviarEmailPropostaAprovacao() {
        let doc_info = this.state.documentoProposta
        doc_info.email_aprovador = this.state.pessoaUsuario.tags1;

        console.log(doc_info);

        await this.util.enviarEmailAprovacaoProposta(doc_info);

        this.toast.current.show({
            severity: 'success', summary: 'Proposta enviada com sucesso!'
        });
        this.setState({
            dialogEnviarPropostaVisivel: false,
        })
    }

    async finalizar() {
        let documento = this.state.documento;
        let listaDocumentoItem = this.state.listaDocumentoItem;

        this.setState({ finalizarCarregando: true });

        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoFinalizada;

        await this.documentoService.salvar(documento);

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            listaDocumentoItem[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoFinalizada;
            await this.documentoService.salvarItem(listaDocumentoItem[i]);
        }

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        })

        this.setState({ finalizarCarregando: false });
    }

    async validarInputError() {
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.pessoa.nome) {
            inputError.nome = true;
        }

        if (!this.state.pessoa.documento) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CPF' && this.state.pessoa.documento.length < 14) {
            inputError.documento = true;
        } else if (this.state.pessoa.tipoDocumento === 'CNPJ' && this.state.pessoa.documento.length < 18) {
            inputError.documento = true;
        } else if (!await this.util.validarDocumento(this.state.pessoa.documento)) {
            inputError.documento = true;
        }

        if (!this.state.pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(this.state.pessoa.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!this.state.pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.state.enderecoCobranca.cep) {
            inputError.enderecoCobrancaCep = true;
        }

        if (!this.util.validarCep(this.state.enderecoCobranca.cep)) {
            inputError.enderecoCobrancaCep = true;
        }

        if (!this.state.enderecoCobranca.logradouro) {
            inputError.enderecoCobrancaLogradouro = true;
        }

        if (!this.state.enderecoCobranca.numero) {
            inputError.enderecoCobrancaNumero = true;
        }

        if (!this.state.enderecoCobranca.bairro) {
            inputError.enderecoCobrancaBairro = true;
        }

        if (!this.state.enderecoCobranca.cidade) {
            inputError.enderecoCobrancaCidade = true;
        }

        if (!this.state.enderecoCobranca.estado) {
            inputError.enderecoCobrancaEstado = true;
        }

        if (!this.state.enderecoCobranca.uf) {
            inputError.enderecoCobrancaUf = true;
        }

        if (!this.state.documento.tags0) {
            inputError.numeroContrato = true;
        }

        if (!this.state.documento.dataProgramada) {
            inputError.dataEvento = true;
        }

        if (!this.state.documento.tags1) {
            inputError.horaEvento = true;
        }

        if (!this.state.documento.tags2) {
            inputError.numeroConvidado = true;
        }

        // if (!this.state.documento.tags5) {
        //     inputError.observacao = true;
        // }

        if (!this.state.enderecoEntrega.cep) {
            inputError.enderecoEntregaCep = true;
        }

        if (!this.util.validarCep(this.state.enderecoEntrega.cep)) {
            inputError.enderecoEntregaCep = true;
        }

        if (!this.state.enderecoEntrega.logradouro) {
            inputError.enderecoEntregaLogradouro = true;
        }

        if (!this.state.enderecoEntrega.numero) {
            inputError.enderecoEntregaNumero = true;
        }

        if (!this.state.enderecoEntrega.bairro) {
            inputError.enderecoEntregaBairro = true;
        }

        if (!this.state.enderecoEntrega.cidade) {
            inputError.enderecoEntregaCidade = true;
        }

        if (!this.state.enderecoEntrega.estado) {
            inputError.enderecoEntregaEstado = true;
        }

        if (!this.state.enderecoEntrega.uf) {
            inputError.enderecoEntregaUf = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({
            inputError: inputError
        });

        if (!inputsOK) {
            this.toast.current.show({
                severity: 'error', summary: 'Informe todos os campos corretamente', sticky: true
            });
        }

        return inputsOK;
    }

    async clonarEstaOrdemDeServico() {
        let pessoa = new Pessoa();
        let enderecoCobranca = new Endereco();
        let enderecoEntrega = new Endereco();
        let documento = new Documento();
        let listaDocumentoItem = [];

        pessoa = { ...this.state.pessoa };
        documento = { ...this.state.documento };
        listaDocumentoItem = [...this.state.listaDocumentoItem];
        enderecoCobranca = { ...this.state.enderecoCobranca };
        enderecoEntrega = { ...this.state.enderecoEntrega };

        if (!await this.validarInputError()) {
            return;
        }

        this.setState({
            clonarEstaOrdemDeServicoCarregando: true,
        });

        delete pessoa._seqPessoa;

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        delete enderecoCobranca._seqEndereco;
        enderecoCobranca.seqPessoa = pessoa._seqPessoa;

        await this.pessoaService.salvarEndereco(enderecoCobranca);

        delete enderecoEntrega._seqEndereco;
        enderecoEntrega.seqPessoa = pessoa._seqPessoa;

        await this.pessoaService.salvarEndereco(enderecoEntrega);

        delete documento._seqDocumento;
        delete documento.tags3;
        documento.seqPessoa = pessoa._seqPessoa;
        documento.dataProgramada = this.util.formatarObjetoDataParaDataBanco(documento.dataProgramada);
        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoDigitada;

        retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        for (let i = 0; i < listaDocumentoItem.length; i++) {
            let documentoItem = new DocumentoItem();

            documentoItem = { ...listaDocumentoItem[i] };

            delete documentoItem._seqDocumentoItem;
            delete documentoItem.seqDocumento;
            documentoItem.seqDocumento = documento._seqDocumento;
            documentoItem.dataCadastro = this.util.formatarObjetoDataParaDataBanco(documentoItem.dataCadastro);
            documentoItem.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoDigitada;

            await this.documentoService.salvarItem(documentoItem);
        }

        window.open('/parceiro/ordem_de_servico_detalhe?id=' + documento._seqDocumento);

        this.setState({
            clonarEstaOrdemDeServicoCarregando: false,
        });
    }

    async cancelarOrdemDeServico() {
        let documento = new Documento();
        let inputsOk = true;
        let inputErrorCancelarOrdemServico = this.state.inputErrorCancelarOrdemServico;

        documento = { ...this.state.documento };

        Object.keys(inputErrorCancelarOrdemServico).forEach(pKey => {
            inputErrorCancelarOrdemServico[pKey] = false;
        });

        if (!documento.tags8) {
            inputErrorCancelarOrdemServico.observacao = true;
        }

        Object.keys(inputErrorCancelarOrdemServico).forEach(pKey => {
            if (inputErrorCancelarOrdemServico[pKey]) {
                inputsOk = false;
            }
        });

        this.setState({
            inputErrorCancelarOrdemServico: inputErrorCancelarOrdemServico,
        });

        if (!inputsOk) {
            return;
        }

        this.setState({
            cancelarOrdemDeServicoCarregando: true,
            dialogConfirmarCancelarOrdemDeServico: false,
        })

        documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoCancelada;
        documento.dataProgramada = this.util.formatarObjetoDataParaDataBanco(documento.dataProgramada);

        let retorno = await this.documentoService.salvar(documento);
        documento = retorno.objeto;

        documento.dataProgramada = this.util.formatarDataBancoParaObjetoData(documento.dataProgramada);

        if (documento.seqTipoDocumentoProcesso !== this.constante.seqTipoDocumentoProcessoDigitada) {
            await this.salvarDocumentoProposta(documento);
        }

        this.setState({
            cancelarOrdemDeServicoCarregando: false,
            dialogCancelarOrdemDeServico: false,
            documento: documento,
            permitirAlterar: false,
        })
    }

    render() {
        return <div style={{ paddingBottom: 100 }}>
            <Toast ref={this.toast} />

            <Dialog
                header="Alerta"
                visible={this.state.dialogConfirmarCancelarOrdemDeServico}
                onHide={() => this.setState({
                    dialogConfirmarCancelarOrdemDeServico: false,
                })}>
                <h3 style={{ fontSize: 20, fontWeight: 'normal' }}>Confirma o cancelamento da Ordem de Serviço?</h3>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: 20, marginTop: 20 }}>
                    <Button
                        label="Sim"
                        onClick={() => this.cancelarOrdemDeServico()} />
                    <Button
                        label="Não"
                        onClick={() => this.setState({
                            dialogConfirmarCancelarOrdemDeServico: false,
                        })} />
                </div>
            </Dialog>

            <Dialog
                header="Cancelar Ordem de Serviço"
                visible={this.state.dialogCancelarOrdemDeServico}
                onHide={() => this.setState({
                    dialogCancelarOrdemDeServico: false
                })}>
                <h3 style={{ fontSize: 20, fontWeight: 'normal' }}>
                    Deseja realmente CANCELAR esta Ordem de Serviço?
                </h3>

                <div style={{ width: '100%', marginTop: 20 }}>
                    <label>Motivo do Cancelamento ({this.state.documento.tags8?.length || '0'}/100)</label><br />
                    <InputText
                        maxLength={100}
                        style={{ width: "100%", borderColor: this.state.inputErrorCancelarOrdemServico.observacao && 'red' }}
                        onChange={pEvento => this.setState({
                            documento: {
                                ...this.state.documento,
                                tags8: pEvento.target.value
                            }
                        })}
                        value={this.state.documento.tags8 || ''} />
                </div>
                {this.state.inputErrorCancelarOrdemServico.observacao && <small style={{ color: 'red' }}>Motivo do Cancelamento inválido</small>}

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: 20 }}>
                    <Button
                        loading={this.state.cancelarOrdemDeServicoCarregando}
                        disabled={this.state.cancelarOrdemDeServicoCarregando}
                        className="p-button-danger"
                        label="Cancelar Ordem de Serviço"
                        onClick={() => this.setState({
                            dialogConfirmarCancelarOrdemDeServico: true
                        })} />
                </div>
            </Dialog>

            <Dialog
                header="Alerta"
                visible={this.state.dialogEscolherPropostaVisivel}
                onHide={() => this.setState({
                    dialogEscolherPropostaVisivel: false,
                })}>
                <h3 style={{ fontSize: 20, fontWeight: 'normal' }}>Deseja realmente escolher esta proposta?</h3>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: 20, marginTop: 20 }}>
                    <Button
                        label="Sim"
                        onClick={() => this.escolherProposta()} />
                    <Button
                        label="Não"
                        onClick={() => this.setState({
                            dialogEscolherPropostaVisivel: false,
                        })} />
                </div>
            </Dialog>

            <Dialog
                header="Alerta"
                visible={this.state.dialogEnviarPropostaVisivel}
                onHide={() => this.setState({
                    dialogEnviarPropostaVisivel: false,
                })}>
                <h3 style={{ fontSize: 20, fontWeight: 'normal' }}>Deseja realmente enviar esta proposta para aprovação?</h3>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: 20, marginTop: 20 }}>
                    <Button
                        label="Sim"
                        onClick={() => this.enviarEmailPropostaAprovacao()} />
                    <Button
                        label="Não"
                        onClick={() => this.setState({
                            dialogEscolherPropostaVisivel: false,
                        })} />
                </div>
            </Dialog>

            <Dialog
                header="Detalhe da proposta"
                visible={this.state.dialogDocumentoPropostaDetalheVisivel}
                onHide={() => this.setState({
                    dialogDocumentoPropostaDetalheVisivel: false,
                })}>

                <div style={{ display: 'flex', justifyContent: 'right', gap: 20 }}>
                    <Button
                        label="Imprimir ficha"
                        onClick={() => window.open('/imprimir_ficha_profissional?id=' + this.state.documentoProposta._seqDocumento)} />
                    <Button
                        label="Avaliar"
                        onClick={() => window.open('/avaliacao?id=' + this.state.documentoProposta._seqDocumento)} />
                    <Button
                        // disabled={this.state.escolherPropostaCarregando || this.state.documento.seqTipoDocumentoProcesso !== this.constante.seqTipoDocumentoProcessoRecebendoProposta}
                        // visible={false}
                        visible={false}
                        loading={this.state.escolherPropostaCarregando}
                        label="Escolher proposta"
                        onClick={() => this.setState({ dialogEscolherPropostaVisivel: true })} />
                    <Button
                        // disabled={this.state.escolherPropostaCarregando || this.state.documento.seqTipoDocumentoProcesso !== this.constante.seqTipoDocumentoProcessoRecebendoProposta}
                        visible={this.state.documentoProposta.status < 3}
                        // visible={true}  
                        loading={false}
                        label="Enviar para aprovação"
                        onClick={() => this.setState({ dialogEnviarPropostaVisivel: true })} />
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    showGridlines
                    removableSort
                    responsiveLayout="scroll"
                    value={this.state.listaDocumentoItemProposta}>
                    <Column
                        header="Nome"
                        field="_itemNome" />
                    <Column
                        header="Solicitado"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {pDocumentoItemProposta.quantidade}
                        </p>} />
                    <Column
                        header="Selecionado"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {pDocumentoItemProposta.quantidadeSaldo}
                        </p>} />
                    <Column
                        header="Valor unitário"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {this.util.formatarValor(pDocumentoItemProposta.precoTabelaMaximo)}
                        </p>} />
                    <Column
                        header="Valor total do item"
                        body={pDocumentoItemProposta => <p style={{ textAlign: 'right' }}>
                            {this.util.formatarValor(pDocumentoItemProposta.quantidade * pDocumentoItemProposta.precoTabelaMaximo)}
                        </p>} />
                    <Column
                        header="Visualizar"
                        body={DocumentoItemProposta =>
                            <Button
                                className="p-button-rounded"
                                icon="pi pi-eye"
                                onClick={() => this.selecionarItemProposta(DocumentoItemProposta)} />} />
                </DataTable>
            </Dialog>

            <Dialog
                header="Profissionais"
                visible={this.state.dialogDocumentoItemPropostaDetalheVisivel}
                onHide={() => this.setState({
                    dialogDocumentoItemPropostaDetalheVisivel: false,
                })}>
                <DataTable
                    showGridlines
                    removableSort
                    responsiveLayout="scroll"
                    value={this.state.listaDocumentoItemRelacionamentoProposta}>
                    <Column
                        style={{ display: "flex", justifyContent: "center" }}
                        header="Foto"
                        body={pDocumentoItemRelacionamentoProposta =>
                            <img alt="" style={{ width: 50, height: 50, borderRadius: 500, objectFit: "contain" }}
                                src={this.state.urlBase + pDocumentoItemRelacionamentoProposta._pessoaRelacionadaFoto} />}
                    />
                    <Column
                        field="_pessoaRelacionadaNome"
                        header="Nome"
                    />
                    <Column
                        header="Check In"
                        body={pDocumentoItemRelacionamentoProposta => pDocumentoItemRelacionamentoProposta.tagd0 ? this.util.formatarData(pDocumentoItemRelacionamentoProposta.tagd0) + " às " + pDocumentoItemRelacionamentoProposta.tags0 : "Não informado"}
                    />
                    <Column
                        header="Check Out"
                        body={pDocumentoItemRelacionamentoProposta => pDocumentoItemRelacionamentoProposta.tagd1 ? this.util.formatarData(pDocumentoItemRelacionamentoProposta.tagd1) + " às " + pDocumentoItemRelacionamentoProposta.tags1 : "Não informado"}
                    />
                </DataTable>
            </Dialog>

            <Toast ref={this.toast} />

            {this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrador ?
                <HeaderSistemaAdmin /> :
                <HeaderSistema />}

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ fontSize: 20 }}>
                                Detalhe da Ordem de Serviço
                            </h1>
                            <h2 style={{ fontSize: 20, fontWeight: 'normal', color: 'gray', marginTop: 5 }}>
                                Nº {this.state.documento._codigo}
                            </h2>
                            <Button
                                style={{ padding: 0, textDecorationLine: 'underline', textDecorationColor: 'var(--primary-color)' }}
                                disabled={!this.state.documento._seqDocumento || this.state.enviarParaReceberPropostaCarregando || this.state.clonarEstaOrdemDeServicoCarregando || this.state.carregando}
                                loading={this.state.clonarEstaOrdemDeServicoCarregando}
                                className="p-button-link"
                                icon="pi pi-external-link"
                                iconPos="right"
                                label="Clonar"
                                onClick={() => this.clonarEstaOrdemDeServico()} />
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <Button
                                disabled={!this.state.documento._seqDocumento || this.state.carregando || this.state.enviarParaReceberPropostaCarregando || !this.state.permitirAlterar}
                                loading={this.state.carregando}
                                label="Salvar"
                                onClick={() => this.salvar()} />
                            <Button
                                disabled={!this.state.documento._seqDocumento || this.state.enviarParaReceberPropostaCarregando || this.state.carregando || this.state.documento.tags3 === 'S' || !this.state.permitirAlterar}
                                loading={this.state.enviarParaReceberPropostaCarregando}
                                label="Enviar para receber propostas"
                                onClick={() => this.enviarParaReceberProposta()} />
                            {/* <Button
                                disabled={!this.state.documento._seqDocumento || this.state.documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoFinalizada || this.state.finalizarCarregando}
                                loading={this.state.finalizarCarregando}
                                label="Finalizar"
                                onClick={() => this.finalizar()} /> */}
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: 'var(--primary-color)' }} /> Informações do cliente
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <h4 style={{ fontSize: 16, }}>
                        <span style={{ color: 'var(--primary-color)' }}>•</span> Dados pessoais
                    </h4>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                disabled={!this.state.permitirAlterar}
                                options={[
                                    { label: 'Pessoa física', value: 'CPF' },
                                    { label: 'Pessoa jurídica', value: 'CNPJ' },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                style={{ width: "100%" }}
                                value={this.state.pessoa.tipoDocumento || ''} />
                        </div>
                        {this.state.pessoa.tipoDocumento === 'CPF' &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    disabled={!this.state.permitirAlterar}
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCPF(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                            </div>}
                        {this.state.pessoa.tipoDocumento === 'CNPJ' &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    disabled={!this.state.permitirAlterar}
                                    style={{ width: "100%" }}
                                    onChange={pEvento => this.setState({
                                        pessoa: {
                                            ...this.state.pessoa,
                                            documento: this.util.formatarCNPJ(pEvento.target.value)
                                        }
                                    })}
                                    value={this.state.pessoa.documento || ''} />
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida || ''} />
                        </div>
                    </div>

                    <h4 style={{ fontSize: 16, marginTop: 10, }}>
                        <span style={{ color: 'var(--primary-color)' }}>•</span> Endereço
                    </h4>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>CEP</label><br />
                            <div className="p-inputgroup">
                                <InputText
                                    disabled={!this.state.permitirAlterar}
                                    value={this.state.enderecoCobranca.cep || ''}
                                    type="tel"
                                    style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoCobranca: {
                                            ...this.state.enderecoCobranca,
                                            cep: pEvento.target.value
                                        }
                                    })}
                                />
                                <Button
                                    disabled={this.state.enderecoCobrancaCarregando || !this.state.permitirAlterar}
                                    loading={this.state.enderecoCobrancaCarregando}
                                    icon="pi pi-search"
                                    type="button"
                                    onClick={() => this.buscarCep(this.state.enderecoCobranca)}
                                />
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Logradouro</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        logradouro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.logradouro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        numero: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.numero || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Complemento</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        complemento: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.complemento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Bairro</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        bairro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.bairro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Cidade</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        cidade: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.cidade || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Estado</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        estado: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.estado || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>UF</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoCobranca: {
                                        ...this.state.enderecoCobranca,
                                        uf: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoCobranca.uf || ''} />
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-book" style={{ color: 'var(--primary-color)' }} /> Informações do evento
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Parceiro</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            disabled
                            value={this.state.documento._vendedorNome || ''} />
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Número do contrato</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    documento: {
                                        ...this.state.documento,
                                        tags0: pEvento.target.value
                                    }
                                })}
                                value={this.state.documento.tags0 || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Data do evento</label><br />
                            <Calendar
                                disabled={!this.state.permitirAlterar}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                inputStyle={{ borderColor: this.state.inputError.dataEvento && 'red' }}
                                style={{ width: "100%" }}
                                onChange={pEvento => {
                                    this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            dataProgramada: pEvento.value
                                        }
                                    })
                                }}
                                value={this.state.documento.dataProgramada} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Horário do evento</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    documento: {
                                        ...this.state.documento,
                                        tags1: this.util.formatarHora(pEvento.target.value)
                                    }
                                })}
                                value={this.state.documento.tags1 || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número de convidados</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    documento: {
                                        ...this.state.documento,
                                        tags2: pEvento.target.value
                                    }
                                })}
                                value={this.state.documento.tags2 || ''} />
                        </div>
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Observação do Evento ({this.state.documento.tags5?.length || '0'}/100)</label><br />
                        <InputText
                            maxLength={100}
                            disabled={!this.state.permitirAlterar}
                            style={{ width: "100%" }}
                            onChange={pEvento => this.setState({
                                documento: {
                                    ...this.state.documento,
                                    tags5: pEvento.target.value
                                }
                            })}
                            value={this.state.documento.tags5 || ''} />
                    </div>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <label>Observação para o Líder ({this.state.documento.tags7?.length || '0'}/100)</label><br />
                        <InputText
                            maxLength={100}
                            disabled={!this.state.permitirAlterar}
                            style={{ width: "100%" }}
                            onChange={pEvento => this.setState({
                                documento: {
                                    ...this.state.documento,
                                    tags7: pEvento.target.value
                                }
                            })}
                            value={this.state.documento.tags7 || ''} />
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: 'var(--primary-color)' }} /> Local do evento
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 10 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>CEP</label><br />
                            <div className="p-inputgroup">
                                <InputText
                                    disabled={!this.state.permitirAlterar}
                                    value={this.state.enderecoEntrega.cep || ''}
                                    type="tel"
                                    style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                                    onChange={pEvento => this.setState({
                                        enderecoEntrega: {
                                            ...this.state.enderecoEntrega,
                                            cep: pEvento.target.value
                                        }
                                    })}
                                />
                                <Button
                                    disabled={this.state.enderecoEntregaCarregando || !this.state.permitirAlterar}
                                    loading={this.state.enderecoEntregaCarregando}
                                    icon="pi pi-search"
                                    type="button"
                                    onClick={() => this.buscarCep(this.state.enderecoEntrega)}
                                />
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Logradouro</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        logradouro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.logradouro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Número</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        numero: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.numero || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Complemento</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        complemento: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.complemento || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Bairro</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        bairro: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.bairro || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Cidade</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        cidade: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.cidade || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Estado</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        estado: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.estado || ''} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>UF</label><br />
                            <InputText
                                disabled={!this.state.permitirAlterar}
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    enderecoEntrega: {
                                        ...this.state.enderecoEntrega,
                                        uf: pEvento.target.value
                                    }
                                })}
                                value={this.state.enderecoEntrega.uf || ''} />
                        </div>
                    </div>
                </div>

                <TabView style={{ marginTop: 20 }}>
                    <TabPanel header="Itens">
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10 }}>
                            <div style={{ width: "100%" }}>
                                <label>Itens</label><br />
                                <Dropdown
                                    disabled={!this.state.permitirAlterar}
                                    filter
                                    style={{ width: "100%", borderColor: this.state.inputErrorAdicionar.itens && 'red' }}
                                    options={this.state.listaTabelaPrecoItem}
                                    optionLabel={pTabelaPrecoItem => `${pTabelaPrecoItem._itemNome} - ${this.util.formatarValor(pTabelaPrecoItem.precoMaximo)}`}
                                    onChange={pEvento => this.setState({
                                        tabelaPrecoItem: pEvento.value,
                                        documentoItem: {
                                            ...this.state.documentoItem,
                                            precoTabelaMinimo: pEvento.value.precoMinimo,
                                            precoTabela: pEvento.value.preco,
                                            precoTabelaMaximo: pEvento.value.precoMaximo,
                                            seqItem: pEvento.value.seqItem,
                                        }
                                    })}
                                    value={this.state.tabelaPrecoItem} />
                                {this.state.inputErrorAdicionar.itens && (
                                    <small style={{ color: 'red' }}>Item obrigatório</small>
                                )}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Quantidade</label><br />
                                <InputText
                                    disabled={!this.state.permitirAlterar}
                                    style={{ width: "100%", borderColor: this.state.inputErrorAdicionar.quantidade && 'red' }}
                                    onChange={pEvento => this.setState({
                                        documentoItem: {
                                            ...this.state.documentoItem,
                                            quantidade: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.documentoItem.quantidade || ''} />
                                {this.state.inputErrorAdicionar.quantidade && (
                                    <small style={{ color: 'red' }}>Quantidade obrigatória</small>
                                )}
                            </div>
                            <div style={{ width: '100%' }}>
                                <label>Valor total</label><br />
                                <InputText
                                    disabled
                                    style={{ width: "100%" }}
                                    value={this.util.formatarValor(this.state.documentoItem.quantidade * this.state.documentoItem.precoTabelaMaximo)} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Button
                                    disabled={this.state.salvarItemCarregando || !this.state.permitirAlterar}
                                    loading={this.state.salvarItemCarregando}
                                    label="Adicionar"
                                    onClick={() => this.adicionar()} />
                            </div>
                        </div>

                        <DataTable
                            style={{ marginTop: 20 }}
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoItem}>
                            <Column
                                header="Nome"
                                field="_itemNome" />
                            <Column
                                header="Quantidade"
                                body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                                    {pDocumentoItem.quantidade}
                                </p>} />
                            <Column
                                header="Valor unitário"
                                body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                                    {this.util.formatarValor(pDocumentoItem.precoTabelaMaximo)}
                                </p>} />
                            <Column
                                header="Valor total do item"
                                body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                                    {this.util.formatarValor(pDocumentoItem.quantidade * pDocumentoItem.precoTabelaMaximo)}
                                </p>} />
                            <Column
                                header="Data de Cadastro"
                                body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                                    {pDocumentoItem.dataCadastro?.toLocaleString('pt-br')}
                                </p>} />
                            <Column
                                header="Deletar"
                                body={pDocumentoItem => <Button
                                    disabled={this.state.deletarCarregando || !this.state.permitirAlterar}
                                    loading={this.state.deletarCarregando}
                                    icon="pi pi-trash"
                                    style={{ backgroundColor: 'red' }}
                                    onClick={() => this.deletarItem(pDocumentoItem)} />} />
                        </DataTable>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', gap: 20, marginTop: 10 }}>
                            <p style={{ fontSize: 20, textAlign: 'right' }}>
                                Quantidade total de profissionais: <span style={{ fontWeight: 'bold', }}>{this.state.documento.tagn0}</span>
                            </p>
                            <p style={{ fontSize: 20, textAlign: 'right' }}>
                                Valor total: <span style={{ fontWeight: 'bold', }}>{this.util.formatarValor(this.state.documento.valorLiquido)}</span>
                            </p>
                        </div>
                    </TabPanel>
                    <TabPanel header="Propostas">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoProposta}>
                            <Column
                                header="Foto"
                                body={pDocumentoProposta =>
                                    <img alt=""
                                        src={this.state.urlBase + pDocumentoProposta._pessoaFoto}
                                        style={{ width: 50, height: 50, borderRadius: 500, objectFit: 'contain' }} />} />
                            <Column
                                header="Nome"
                                field="_pessoaNome" />
                            <Column
                                header="Email"
                                field="_pessoaEmail" />
                            <Column
                                header="Status"
                                body={pDocumentoProposta =>
                                    pDocumentoProposta.status === 2 ?
                                        <p style={{ color: 'orange' }}>Análise</p> :
                                        pDocumentoProposta.status === 3 ?
                                            <p style={{ color: 'green' }}>Aprovado</p> :
                                            pDocumentoProposta.status === 4 ?
                                                <p style={{ color: 'red' }}>Reprovado</p> :
                                                pDocumentoProposta.status === 5 ?
                                                    <p style={{ color: 'var(--primary-color)' }}>Finalizado</p> : ''
                                } />
                            <Column
                                header="Opções"
                                body={pDocumentoProposta => <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                    <Button
                                        icon="pi pi-eye"
                                        onClick={() => this.selecionarProposta(pDocumentoProposta)} />
                                    <Button
                                        disabled={pDocumentoProposta.status !== 3}
                                        icon="pi pi-send"
                                        onClick={() => this.enviarEmailAceiteCliente()} />
                                </div>} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Arquivos">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaArquivo}>
                            <Column
                                field="arquivoOriginal"
                                header="Arquivo"
                                body={pArquivo =>
                                    <a
                                        href={this.state.urlBase + pArquivo.arquivo}
                                        target='_blank'
                                        rel="noreferrer">
                                        {pArquivo.arquivoOriginal}
                                    </a>} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Relacionamento">
                        <DataTable
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoRelacionamento}>
                            <Column
                                header="Nome"
                                field="_pessoaRelacionadaNome" />
                            <Column
                                header="Tipo de relacionamento"
                                field="_tipoRelacionamentoNome" />
                            <Column
                                header="Data de cadastro"
                                field="dataCadastro"
                                body={pDocumentoRelacionamento => <p>
                                    {this.util.formatarData(pDocumentoRelacionamento.dataCadastro)}
                                </p>} />
                        </DataTable>
                    </TabPanel>
                </TabView>

                <div style={{ padding: '50px 0' }}>
                    <hr />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 20 }}>
                    <Button
                        disabled={this.state.documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoCancelada || this.state.documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoFinalizada || this.state.documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoArquivados || !this.state.permitirAlterar}
                        onClick={() => this.setState({
                            dialogCancelarOrdemDeServico: true
                        })}
                        style={{ padding: 0, color: 'red' }}
                        className="p-button-link"
                        icon="pi pi-trash"
                        label="Cancelar Ordem de Serviço" />

                    {this.state.documento.seqTipoDocumentoProcesso === this.constante.seqTipoDocumentoProcessoCancelada && <div>
                        <p style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                            Motivo do cancelamento:
                        </p>
                        <p style={{ textAlign: 'center', marginTop: 10 }}>
                            {this.state.documento.tags8}
                        </p>
                    </div>}
                </div>
            </main>
        </div>
    }
}