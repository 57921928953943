import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";

function Botao({ children }) {
  return (
    <button className="px-20 py-2 bg-[--primary] text-[--dark-purple] font-bold rounded-full">
      {children}
    </button>
  )
}

export default function Hotsite() {
  const PERGUNTAS_FREQUENTES = [
    {
      pergunta: 'Preciso pagar alguma mensalidade para usar a plataforma?',
      resposta: 'Não, para utilizar nossa plataforma e ter acesso aos recursos é totalmente livre de mensalidade.',
      aberto: false,
    },
    {
      pergunta: 'Depois que eu criei a conta, posso editar minhas informações?',
      resposta: 'Sim, suas informações ficam disponíveis para edição quando você quiser.',
      aberto: false,
    },
    {
      pergunta: 'Como faço para me cadastrar na plataforma?',
      resposta: 'Entre em contato conosco no email atendimento@onework.app.br',
      aberto: false,
    },
    {
      pergunta: 'Eu posso cancelar ou excluir minha conta?',
      resposta: 'Sim, você tem total autonomia e controle sobre sua conta A qualquer momento você exclui sua sem custo nenhum.',
      aberto: false,
    },
    {
      pergunta: 'Preciso passar por alguma análise?',
      resposta: 'Sim, buscamos levar o melhor atendimento para os clientes, assim para manter a segurança e qualidade dos serviços é preciso que o profissional passe por uma análise.',
      aberto: false,
    },
  ]

  const [perguntasFrequentes, setPerguntasFrequentes] = useState(PERGUNTAS_FREQUENTES);
  const [sidebarAberta, setSidebarAberta] = useState(false);
  const [headerBackgroundVisivel, setHeaderBackgroundVisivel] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 50) {
        setHeaderBackgroundVisivel(true);
      } else {
        setHeaderBackgroundVisivel(false);
      }
    })
  }, [])

  const alternarSummary = index => {
    const pf = [...perguntasFrequentes];

    pf[index].aberto = !pf[index].aberto;

    setPerguntasFrequentes(pf);
  }

  const alternarSidebar = () => {
    setSidebarAberta(value => !value);
  }

  return (
    <div className="w-full h-full bg-[--dark-purple] text-white">
      <Sidebar
        position="right"
        visible={sidebarAberta}
        onHide={alternarSidebar}
        className="bg-[--dark-purple]"
      >
        <div className="flex flex-col gap-10 py-4">
          <a className="text-white" href="#como-funciona" onClick={alternarSidebar}>Como funciona</a>
          <a className="text-white" href="#quem-somos" onClick={alternarSidebar}>Quem somos</a>
          <a className="py-2 px-4 bg-[--primary] text-[--dark-purple] font-bold rounded-full text-center" href="/login">
            Login
          </a>
        </div>
      </Sidebar>

      <video autoPlay muted loop className="w-full h-[100vh] absolute top-0 object-cover">
        <source src="/original.mp4" type="video/mp4"></source>
      </video>

      <header className="w-full h-[100vh] relative">
        <div className="w-full break-words flex flex-col gap-10 absolute top-[50%] translate-y-[-50%] p-4 xl:max-w-[1280px] xl:left-[50%] xl:translate-x-[-50%]">
          <h1 className="font-semibold text-7xl xl:text-8xl">Conectando pessoas à novas oportunidades</h1>
          {/* <h2 className="text-lg text-gray-200 font-light xl:w-1/2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</h2> */}
          <div className="flex flex-col items-start gap-4 xl:flex-row">
            <img src={"/disponivel_play_store.svg"} alt="Google Play" width={240.19} height={70.46} />
            <img src={"/disponivel_app_store.svg"} alt="App Store" width={240.19} height={70.46} />
          </div>
        </div>

        <div className={`w-full fixed top-0 transition ease-in-out delay-75 z-50 ${headerBackgroundVisivel ? 'bg-[--dark-purple]' : 'bg-transparent'}`}>
          <div className="p-4 flex items-center justify-between xl:max-w-[1280px] xl:mx-auto">
            <img src={"/logo_onework.svg"} alt="OneWork" width={120} height={40} />

            <div className="hidden xl:flex items-center gap-10">
              <a className="text-white" href="#como-funciona">Como funciona</a>
              <a className="text-white" href="#quem-somos">Quem somos</a>
              <a className="py-2 px-4 bg-[--primary] text-[--dark-purple] font-bold rounded-full" href="/login">
                Login
              </a>
            </div>
            <div className="block xl:hidden">
              <button
                className="py-2 px-4 hover:cursor-pointer"
                onClick={alternarSidebar}
              >
                <i className="pi pi-bars"></i>
              </button>
            </div>
          </div>
        </div>
      </header>

      <div id="como-funciona" className="px-4 py-10 flex flex-col gap-20 xl:max-w-[1280px] xl:mx-auto">
        <h1 className="text-3xl self-center">Como funciona?</h1>

        <div className="flex flex-col gap-20 xl:flex-row xl:items-center">
          <div className="flex flex-col items-start gap-10 xl:w-1/2">
            <div className="flex flex-col items-start gap-2">
              <h2 className="text-xl">Pra você <span className="text-[--secondary-light]">ORGANIZADOR</span></h2>
              <p className="text-md">Quando precisar de profissionais para sua festa(garçons, barmans, fotógrafo e cozinheiro), você acessa o One Work e pronto! Todos os profissionais estão te esperando. </p>
            </div>

            <div className="flex flex-col items-start gap-2">
              <h2 className="text-xl">Pra você <span className="text-[--secondary-light]">FREELANCER</span></h2>
              <p className="text-md">Você que tem habilidades em qualquer área do ramo de festas e show, é só se cadastrar na plataforma, preencher suas habilidades e esperar que os trabalhos apareçam quase todos os dias. </p>
            </div>

            <Botao>Quero começar</Botao>
          </div>

          <div className="flex flex-col gap-4 xl:flex-row xl:w-1/2 xl:max-h-[504px]">
            <div className="flex items-center gap-4 xl:flex-col xl:justify-between">
              <div className="w-1/2 h-auto xl:w-[220px] xl:h-[242px]">
                <img src={"/fotografo.webp"} className="w-full h-auto" alt="Fotógrafo" width={220} height={242} />
              </div>
              <div className="w-1/2 h-auto xl:w-[220px] xl:h-[242px]">
                <img src={"/garcom.webp"} className="w-full h-auto" alt="Fotógrafo" width={220} height={242} />
              </div>
            </div>

            <div className="rounded-tl-xl rounded-bl-xl overflow-hidden">
              <img src={"/dj.webp"} alt="DJ" className="w-full h-full object-cover" width={492} height={504} />
            </div>
          </div>
        </div>
      </div>

      <div id="quem-somos" className="px-4 py-10 flex flex-col gap-20 xl:flex-row xl:items-center xl:max-w-[1280px] xl:mx-auto">
        <div>
          <img src={"/dj_pessoas.webp"} className="w-full" alt="DJ" width={600} height={632} />
        </div>

        <div className="flex flex-col gap-10 items-start">
          <div className="flex flex-col gap-4">
            <h2 className="text-3xl">Quem somos</h2>
            <p className="text-md xl:w-[450px]">A One Work é um hub de serviços completo voltado para o ramo das Casas de festas, casa de show e grandes eventos. Ela surgiu com o objetivo de facilitar as grandes casa de festa e organizadores de eventos contratarem os profissionais que precisam, e que profissionais desse ramo encontrem serviços</p>
          </div>

          <Botao>Quero começar</Botao>
        </div>
      </div>

      <div className="px-4 py-10 flex flex-col gap-8 relative xl:max-w-[1280px] xl:mx-auto">
        <img src={"/simbolo-onework.svg"} alt="OneWork" className="absolute top-[-50px] right-0" width={371.92} height={256.32} />

        <h1 className="text-3xl z-10">Na One Work você:</h1>

        <p className="text-xl leading-[74px] z-10 font-semibold xl:text-2xl xl:flex xl:flex-wrap xl:gap-6"><span className="px-6 py-4 bg-[--secondary] rounded-full">Encontra os melhores profissionais</span> <span className="px-6 py-4 bg-[--primary-vibrant] text-[--dark-purple] rounded-full">Tem controle das suas finanças</span> <span className="px-6 py-4 bg-[--secondary] rounded-full">Trabalha em diversos eventos</span></p>
      </div>

      <div className="px-4 py-10 flex flex-col gap-8 xl:max-w-[1280px] xl:mx-auto">
        <div className="flex flex-col gap-4 items-center">
          <h1 className="text-3xl">Testemunhos</h1>
          <h2>Veja o que estão dizendo sobre nós</h2>
        </div>

        <div className="flex flex-col gap-10 xl:grid xl:grid-cols-3">
          <div className="flex flex-col gap-4 p-4 bg-[--secondary] rounded-lg">
            <div className="flex items-center gap-4">
              <img src={'/sergio.png'} alt="Sérgio" className="rounded-full" width={46} height={45.53} />
              <p className="text-lg">Sérgio Cardoso</p>
            </div>

            <p>“A One Work facilitou muito a minha vida, agora eu não tenho mais dor de cabeça para contratar profissionais para minha casa de show.”</p>
          </div>

          <div className="flex flex-col gap-4 p-4 bg-[--secondary] rounded-lg">
            <div className="flex items-center gap-4">
              <img src={'/marcelo.webp'} alt="Marcelo" className="rounded-full" width={46} height={45.53} />
              <p className="text-lg">Marcelo de Souza</p>
            </div>

            <p>“Depois que comecei a usar a One Work nunca mais tive dificuldade para trabalhar. Sempre tem trabalhos como garçom, segurança e motorista.”</p>
          </div>

          <div className="flex flex-col gap-4 p-4 bg-[--secondary] rounded-lg">
            <div className="flex items-center gap-4">
              <img src={'/luciana.jpeg'} alt="Luciana" className="rounded-full" width={46} height={45.53} />
              <p className="text-lg">Luciana Ramos</p>
            </div>

            <p>“A One Work me deu a segurança de poder contratar profissionais difíceis de achar como vallet, DJ e chef de cozinha. Todos capacitados e na palma da minha mão.”</p>
          </div>
        </div>
      </div>

      <div className="px-4 py-10 pb-20 flex flex-col gap-8 items-center xl:max-w-[1280px] xl:mx-auto">
        <h1 className="text-3xl">Perguntas frequentes</h1>

        <div className="w-full border-2 border-[--secondary-light] rounded-md">
          {perguntasFrequentes.map((item, index) => (
            <details key={index} className="w-full border-b-2 border-b-[--secondary-light] cursor-pointer last:border-none p-4">
              <summary className="text-md list-none" onClick={() => alternarSummary(index)}>
                <div className="flex items-center justify-between">
                  <p>{item.pergunta}</p>

                  {item.aberto ? (
                    <i className="pi pi-angle-up text-[--secondary-light]"></i>
                  ) : (
                    <i className="pi pi-angle-down text-[--secondary-light]"></i>
                  )}
                </div>
              </summary>

              <p className="text-gray-300 mt-4">{item.resposta}</p>
            </details>
          ))}
        </div>
      </div>

      <footer className="flex flex-col gap-8 p-10 bg-[--secondary]">
        <div className="w-full px-4 max-w-screen-xl mx-auto flex flex-col items-start justify-between gap-8 xl:flex-row">
          <a href="#como-funciona">Como funciona</a>
          <a href="#quem-somos">Quem somos</a>
          <a href="#testemunhos">Testemunhos</a>
          <a href="#perguntas-frequentes">Perguntas frequentes</a>
          <a href="/">Política de Privacidade</a>
          <a href="/">Termos de Uso</a>
        </div>
        <div className="w-full px-4 max-w-screen-xl mx-auto flex items-center gap-4 border-b-2 border-[--secondary-light-opaque] pb-10 xl:justify-center">
          <i className="pi pi-instagram  text-xl"></i>
          <i className="pi pi-facebook  text-xl"></i>
        </div>

        <img src={'/logo_onework.svg'} alt="OneWork" className="self-center" width={120} height={40} />
      </footer>
    </div>
  )
}