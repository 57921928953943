import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import HeaderSistema from "../../components/HeaderSistema";
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import CepService from "lirida-back-service/Servicos/Cep/CepService";

export default class Perfil extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);
    cepService = new CepService(this.constante.token, this.constante.urlBase);

    toast = createRef();

    state = {
        urlBase: '',
        carregando: false,
        enderecoCarregando: false,
        pessoaUsuario: new Pessoa(),
        endereco: new Endereco(),
        senhaAtual: '',
        novaSenha: '',
        confirmarNovaSenha: '',
        inputError: {
            nome: false,
            tipoDocumento: false,
            documento: false,
            email: false,
            telefone: false,
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            estado: false,
            uf: false,
            senhaAtual: false,
            novaSenha: false,
            confirmarNovaSenha: false
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaEndereco = [];
        let endereco = new Endereco();

        let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let urlBase = await this.util.buscarUrlBaseArquivo();

        let retorno = await this.pessoaService.listarEndereco(pessoaUsuario);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                endereco = listaEndereco[i];
            }
        }

        this.setState({
            pessoaUsuario: pessoaUsuario,
            endereco: endereco,
            urlBase: urlBase,
        });
    }

    async salvar() {
        let pessoaUsuario = this.state.pessoaUsuario;
        let endereco = this.state.endereco;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!pessoaUsuario.nome) {
            inputError.nome = true;
        }

        if (!pessoaUsuario.tipoDocumento) {
            inputError.tipoDocumento = true;
        }

        if (!pessoaUsuario.documento) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CPF' && pessoaUsuario.documento.length !== 14) {
            inputError.documento = true;
        } else if (pessoaUsuario.tipoDocumento === 'CNPJ' && pessoaUsuario.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!this.util.validarEmail(pessoaUsuario.emailPlataformaLirida)) {
            inputError.email = true;
        }

        if (!pessoaUsuario.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!this.util.validarTelefone(pessoaUsuario.telefonePlataformaLirida)) {
            inputError.telefone = true;
        }

        if (!endereco.cep) {
            inputError.cep = true;
        }

        if (!this.util.validarCep(endereco.cep)) {
            inputError.cep = true;
        }

        if (!endereco.logradouro) {
            inputError.logradouro = true;
        }

        if (!endereco.numero) {
            inputError.numero = true;
        }

        if (!endereco.bairro) {
            inputError.bairro = true;
        }

        if (!endereco.cidade) {
            inputError.cidade = true;
        }

        if (!endereco.estado) {
            inputError.estado = true;
        }

        if (!endereco.uf) {
            inputError.uf = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ carregando: true });

        pessoaUsuario.seqUsuario = this.constante.seqUsuario;
        pessoaUsuario.seqPessoaPerfil = this.constante.seqPessoaPerfilParceiro;

        let retorno = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retorno.objeto;

        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = pessoaUsuario._seqPessoa;
        endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;

        retorno = await this.pessoaService.salvarEndereco(endereco);
        endereco = retorno.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.toast.current.show({
            severity: 'success', summary: 'Informações armazenadas com sucesso!'
        })

        this.setState({
            pessoaUsuario: pessoaUsuario,
            endereco: endereco,
            carregando: false,
        });
    }

    async buscarCep() {
        let endereco = this.state.endereco;
        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!endereco.cep) {
            inputError.cep = true;
        }

        if (!this.util.validarCep(endereco.cep)) {
            inputError.cep = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        this.setState({ enderecoCarregando: true });

        let retorno = await this.cepService.buscarCep(this.state.endereco.cep);

        endereco.logradouro = retorno.logradouro;
        endereco.complemento = retorno.complemento;
        endereco.bairro = retorno.bairro;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.uf = retorno.uf;

        this.setState({
            endereco: endereco,
            enderecoCarregando: false,
        });
    }

    async alterarImagem(pArquivo) {
        let pessoa = new Pessoa();
        let pessoaUsuario = this.state.pessoaUsuario;

        this.setState({ imagemCarregando: true });

        pessoa = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        pessoa.foto = await this.util.pegarBase64(pArquivo);

        let retorno = await this.pessoaService.salvar(pessoa);
        pessoa = retorno.objeto;

        pessoaUsuario.foto = pessoa.foto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            imagemCarregando: false
        });
    }

    async setAsyncState(state) {
        return new Promise(resolve => this.setState(state, resolve));
    }

    async validarErrosAlterarSenha() {
        const inputError = { ...this.state.inputError };
        let senhaAtualValida;
        let novaSenhaValida;
        let confirmarNovaSenhaValida;

        Object.keys(inputError).forEach(key => inputError[key] = false);

        await this.setAsyncState({ inputError });

        senhaAtualValida = this.state.senhaAtual.length > 0 && this.state.senhaAtual === this.state.pessoaUsuario.senha;
        novaSenhaValida = this.state.novaSenha.length > 0 && this.state.novaSenha === this.state.confirmarNovaSenha;
        confirmarNovaSenhaValida = this.state.confirmarNovaSenha.length > 0 && this.state.novaSenha === this.state.confirmarNovaSenha;

        if (!senhaAtualValida || !novaSenhaValida || !confirmarNovaSenhaValida) {
            await this.setAsyncState({
                inputError: {
                    ...this.state.inputError,
                    senhaAtual: !senhaAtualValida,
                    novaSenha: !novaSenhaValida,
                    confirmarNovaSenha: !confirmarNovaSenhaValida,
                }
            });

            return false;
        }

        return true;
    }

    async alterarSenha(e) {
        const pessoaUsuario = { ...this.state.pessoaUsuario };

        e.preventDefault();

        if (!await this.validarErrosAlterarSenha()) return;

        pessoaUsuario.senha = this.state.novaSenha;

        await this.pessoaService.salvar(pessoaUsuario);

        this.toast.current.show({
            severity: 'success', summary: "Sucesso!", detail: 'Informações armazenadas com sucesso!'
        });

        this.setState({
            senhaAtual: '',
            novaSenha: '',
            confirmarNovaSenha: ''
        });

    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <HeaderSistema />

            <main style={{ padding: 20, paddingBottom: 200 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Perfil
                    </h1>

                    <Button
                        disabled={this.state.carregando}
                        loading={this.state.carregando}
                        label="Salvar"
                        onClick={() => this.salvar()} />
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-user" style={{ color: 'var(--primary-color)' }} /> Dados Pessoais
                </h3>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <div>
                            <img alt=""
                                style={{ width: 150, height: 150, borderRadius: 500, objectFit: 'contain' }}
                                src={this.state.urlBase + this.state.pessoaUsuario.foto} />
                        </div>

                        <div>
                            <input
                                type="file" id="imagem_item" style={{ display: 'none' }}
                                onChange={pEvento => this.alterarImagem(pEvento.target.files[0])} />
                            {this.state.imagemCarregando ?
                                <Button
                                    disabled={this.state.imagemCarregando}
                                    loading={this.state.imagemCarregando}
                                    icon="pi pi-camera"
                                    label="Alterar imagem" />
                                :
                                <label
                                    htmlFor="imagem_item"
                                    style={{ padding: 10, borderRadius: 5, backgroundColor: 'var(--primary-color)', cursor: 'pointer', color: "white", }}
                                >
                                    <i className="pi pi-camera"></i> Alterar imagem
                                </label>}
                        </div>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.nome} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Tipo de pessoa</label><br />
                            <Dropdown
                                style={{ width: '100%', borderColor: this.state.inputError.tipoDocumento && 'red' }}
                                options={[
                                    { label: 'Pessoa Física', value: 'CPF' },
                                    { label: 'Pessoa Jurídica', value: 'CNPJ' },
                                ]}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        tipoDocumento: pEvento.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.tipoDocumento} />
                        </div>
                        {this.state.pessoaUsuario.tipoDocumento === "CPF" &&
                            <div style={{ width: '100%' }}>
                                <label>CPF</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                            <div style={{ width: '100%' }}>
                                <label>CNPJ</label><br />
                                <InputText
                                    style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                    onChange={pEvento => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            documento: pEvento.target.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.documento} />
                                {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                            </div>}
                        <div style={{ width: '100%' }}>
                            <label>Email</label><br />
                            <InputText
                                disabled
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.emailPlataformaLirida} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        telefonePlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.telefonePlataformaLirida} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                    </div>
                </div>

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                    <i className="pi pi-building" style={{ color: 'var(--primary-color)' }} /> Endereço
                </h3>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10, padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ width: '100%' }}>
                        <label>CEP</label><br />
                        <div className="p-inputgroup">
                            <InputText
                                value={this.state.endereco.cep || ''}
                                type="tel"
                                style={{ width: '100%', borderColor: this.state.inputError.cep && 'red' }}
                                onChange={pEvento => this.setState({
                                    endereco: {
                                        ...this.state.endereco,
                                        cep: this.util.formatarCEP(pEvento.target.value)
                                    }
                                })}
                            />
                            <Button
                                disabled={this.state.enderecoCarregando}
                                loading={this.state.enderecoCarregando}
                                icon="pi pi-search"
                                type="button"
                                onClick={() => this.buscarCep()}
                            />
                        </div>
                        {this.state.inputError.cep && <small style={{ color: 'red' }}>Cep inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Logradouro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.logradouro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    logradouro: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.logradouro || ''} />
                        {this.state.inputError.logradouro && <small style={{ color: 'red' }}>Logradouro inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Número</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.numero && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    numero: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.numero || ''} />
                        {this.state.inputError.numero && <small style={{ color: 'red' }}>Número inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Complemento</label><br />
                        <InputText
                            style={{ width: "100%" }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    complemento: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.complemento || ''} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Bairro</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.bairro && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    bairro: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.bairro || ''} />
                        {this.state.inputError.bairro && <small style={{ color: 'red' }}>Bairro inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Cidade</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.cidade && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    cidade: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.cidade || ''} />
                        {this.state.inputError.cidade && <small style={{ color: 'red' }}>Cidade inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>Estado</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.estado && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    estado: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.estado || ''} />
                        {this.state.inputError.estado && <small style={{ color: 'red' }}>Estado inválido</small>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <label>UF</label><br />
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.uf && 'red' }}
                            onChange={pEvento => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    uf: pEvento.target.value
                                }
                            })}
                            value={this.state.endereco.uf || ''} />
                        {this.state.inputError.uf && <small style={{ color: 'red' }}>Uf inválido</small>}
                    </div>
                </div>

                <hr style={{ marginTop: 20 }} />

                <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>Alterar Senha</h3>

                <form style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }} onSubmit={e => this.alterarSenha(e)}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10 }}>
                        <div style={{ width: '100%' }}>
                            <label>Senha atual</label><br />
                            <InputText
                                type="password"
                                style={{ width: "100%", borderColor: this.state.inputError.senhaAtual && 'red' }}
                                onChange={pEvento => this.setState({ senhaAtual: pEvento.target.value })}
                                value={this.state.senhaAtual || ''} />
                            {this.state.inputError.senhaAtual && <small style={{ color: 'red' }}>Senha atual inválida</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Nova senha</label><br />
                            <InputText
                                type="password"
                                style={{ width: "100%", borderColor: this.state.inputError.novaSenha && 'red' }}
                                onChange={pEvento => this.setState({ novaSenha: pEvento.target.value })}
                                value={this.state.novaSenha || ''} />
                            {this.state.inputError.novaSenha && <small style={{ color: 'red' }}>Nova senha inválida</small>}
                        </div>
                        <div style={{ width: '100%' }}>
                            <label>Confirmação de nova senha</label><br />
                            <InputText
                                type="password"
                                style={{ width: "100%", borderColor: this.state.inputError.confirmarNovaSenha && 'red' }}
                                onChange={pEvento => this.setState({ confirmarNovaSenha: pEvento.target.value })}
                                value={this.state.confirmarNovaSenha || ''} />
                            {this.state.inputError.confirmarNovaSenha && <small style={{ color: 'red' }}>Confirmação de nova senha inválida</small>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button label="Alterar Senha" type="submit" />
                    </div>
                </form>

            </main>
        </div>
    }
}