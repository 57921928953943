import { Component } from "react";
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Constante from "../../utils/Constante";
import Util from "../../utils/Util";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import HeaderSistemaAdmin from "../../components/HeaderSistemaAdmin";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default class CobrancaPg extends Component {
    constante = new Constante();
    util = new Util();
    financeiroService = new FinanceiroService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        pessoa: new Pessoa(),
        listaPessoa: [],
        listaCobranca: [],
        dataProgramadaInicial: undefined,
        dataProgramadaFinal: undefined,
        carregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilParceiro;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let listaPessoa = retorno.objeto;

        this.setState({
            listaPessoa: listaPessoa,
        });
    }

    async listar() {
        let listaPesquisa = [];
        let listaCobranca = [];

        this.setState({ carregando: true });

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'cobranca.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        // if (this.state.pessoa) {
        //     pesquisa = new Pesquisa();
        //     pesquisa.campo = 'pessoa.nome';
        //     pesquisa.conteudo = this.state.pessoa.nome;
        //     pesquisa.operacao = 'AND';
        //     pesquisa.tipo = 'TEXTO';
        //     listaPesquisa.push(pesquisa);
        // }

        if (this.state.dataProgramadaInicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'cobranca.data_programada';
            pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.dataProgramadaInicial);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }
        if (this.state.dataProgramadaFinal) {
            pesquisa = new Pesquisa();
            pesquisa.campo = 'cobranca.data_programada';
            pesquisa.conteudo = this.util.formatarObjetoDataParaDataBanco(this.state.dataProgramadaFinal);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.financeiroService.listarCobrancaComFiltro(listaPesquisa);
        listaCobranca = retorno.objeto;
        

        for (let i = 0; i < listaCobranca.length; i++) {
            listaCobranca[i].dataProgramada = this.util.formatarDataBancoParaObjetoData(listaCobranca[i].dataProgramada);
        }

        this.setState({
            carregando: false,
            listaCobranca: listaCobranca,
        });
    }

    render() {
        return <div>
            <HeaderSistemaAdmin />

            <main style={{ padding: 20 }}>
                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, }}>
                    <h1 style={{ fontSize: 20 }}>
                        Cobrança
                    </h1>
                </div>

                <div style={{ padding: 10, border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div>
                        <h3 style={{ fontSize: 18, marginTop: 20, textDecoration: 'underline', textDecorationColor: 'var(--primary-color)', textDecorationThickness: 2 }}>
                            <i className="pi pi-filter-fill" style={{ color: 'var(--primary-color)' }} /> Filtros
                        </h3>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, marginTop: 20 }}>
                        <div style={{ width: "100%" }}>
                            <label>Parceiro</label><br />
                            <Dropdown
                                filter
                                style={{ width: "100%" }}
                                options={[
                                    { ...new Pessoa(), nome: 'Todos' },
                                    ...this.state.listaPessoa
                                ]}
                                optionLabel="nome"
                                onChange={pEvento => this.setState({
                                    pessoa: pEvento.value
                                })}
                                value={this.state.pessoa} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <label>Data programada inicial</label><br />
                            <Calendar
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    dataProgramadaInicial: pEvento.value,
                                })}
                                value={this.state.dataProgramadaInicial} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <label>Data programada final</label><br />
                            <Calendar
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                style={{ width: "100%" }}
                                onChange={pEvento => this.setState({
                                    dataProgramadaFinal: pEvento.value,
                                })}
                                value={this.state.dataProgramadaFinal} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button
                            disabled={this.state.carregando}
                            loading={this.state.carregando}
                            label="Buscar"
                            onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={50}
                    rowsPerPageOptions={[50, 100]}
                    value={this.state.listaCobranca}
                    selectionMode="single"
                    onSelectionChange={pEvento => window.open('/parceiro/cobranca_detalhe?id=' + pEvento.value._seqCobranca)}>

                    <Column
                        sortable
                        header="Parceiro"
                        field=""
                    />

                    {/* <Column
                        sortable
                        header="fp"
                        field="seqFormaPagamento"
                    /> */}
                    <Column
                        sortable
                        header="Data programada"
                        field="dataProgramada"
                        body={pCobranca => <p>
                            {pCobranca.dataProgramada?.toLocaleDateString('pt-br')}
                        </p>}
                    />
                    <Column
                        sortable
                        header="Valor"
                        field="valor"
                        body={pCobranca => <p>
                            {this.util.formatarValor(pCobranca.valor)}
                        </p>}
                    />
                </DataTable>

            </main>
        </div>
    }
}