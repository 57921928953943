import { Component } from "react";
import logo from '../images/logo.png';
import Constante from "../utils/Constante";
import Util from "../utils/Util";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Financeiro from "lirida-back-service/Servicos/Financeiro/Financeiro";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Cobranca from "lirida-back-service/Servicos/Cobranca/Cobranca";
import QRCode from "react-qr-code";

export default class Fatura extends Component {
    constante = new Constante();
    util = new Util();
    financeiroService = new FinanceiroService(this.constante.token, this.constante.urlBase);
    documentoService = new DocumentoService(this.constante.token, this.constante.urlBase);
    pessoaService = new PessoaService(this.constante.token, this.constante.urlBase);

    state = {
        financeiro: new Financeiro(),
        financeiroFornecedor: new Financeiro(),
        cobranca: new Cobranca(),
        documento: new Documento(),
        pessoa: new Pessoa(),
        enderecoCobranca: new Endereco(),
        enderecoEntrega: new Endereco(),
        listaDocumentoItem: [],
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let financeiro = new Financeiro();
        let documento = new Documento();
        let pessoa = new Pessoa();
        let listaEndereco = [];
        let enderecoCobranca = new Endereco();
        let enderecoEntrega = new Endereco();
        let listaPesquisa = [];
        let listaFinanceiro = [];
        let parametro = this.util.buscarParametrosUrl();

        let retorno = await this.financeiroService.buscarPorSeq(this.constante.seqUsuario, parametro.id);
        financeiro = retorno.objeto;

        listaFinanceiro.push(financeiro);

        retorno = await this.documentoService.buscar(this.constante.seqUsuario, financeiro.seqDocumento);
        documento = retorno.objeto;

        retorno = await this.documentoService.listarDocumentoItem(documento);
        let listaDocumentoItem = retorno.objeto;

        retorno = await this.pessoaService.buscarPorSeqPessoa(documento.seqPessoa, this.constante.seqUsuario);
        pessoa = retorno.objeto;

        retorno = await this.pessoaService.listarEndereco(pessoa);
        listaEndereco = retorno.objeto;

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                enderecoCobranca = listaEndereco[i];
            }
        }

        for (let i = 0; i < listaEndereco.length; i++) {
            if (listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoEntrega) {
                enderecoEntrega = listaEndereco[i];
            }
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.seq_documento';
        pesquisa.conteudo = documento._seqDocumento;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.tags0';
        pesquisa.conteudo = documento._seqDocumento;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'movimento_financeiro.seq_pessoa_recebedor';
        pesquisa.conteudo = this.constante.seqPessoaFornecedor;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        retorno = await this.financeiroService.listarComFiltro(listaPesquisa);
        let financeiroFornecedor = retorno.objeto[0];

        
        

        listaFinanceiro.push(financeiroFornecedor);

        retorno = await this.financeiroService.gerarLoteCobranca(listaFinanceiro);
        let cobranca = retorno.objeto;

        

        this.setState({
            documento: documento,
            financeiro: financeiro,
            financeiroFornecedor: financeiroFornecedor,
            pessoa: pessoa,
            enderecoCobranca: enderecoCobranca,
            enderecoEntrega: enderecoEntrega,
            listaDocumentoItem: listaDocumentoItem,
            cobranca: cobranca,
        })
    }

    render() {
        return <div style={{ padding: 10, position: 'relative' }}>

            <div style={{ position: 'absolute', top: 11, right: 11, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 5 }}>
                <p style={{ fontSize: 12, }}>Pague com PIX</p>
                <div style={{ border: '5px solid white' }}>
                    <QRCode value={this.state.cobranca.codigoPix || ''} size={100} />
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: 10, border: '1px solid black', padding: 10 }}>
                <img alt=""

                    src={logo}
                    style={{ width: 150, objectFit: 'contain' }} />

                <div>
                    <small>OneWork Soluções Inteligentes</small><br />
                    <small>Endereco: Av euzebio de queiroz, 1450</small><br />
                    <small>CNPJ: 45.669.894/0001-45</small>
                </div>
            </div>

            <div style={{ border: '1px solid black', borderTop: 'none', padding: 10 }}>
                <p style={{ fontSize: 12 }}>Dados do pagamento</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Contrato</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags0}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Valor</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarValor(this.state.financeiro.valorProgramado)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Valor taxa</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarValor(this.state.financeiroFornecedor.valorProgramado)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Vencimento</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarData(this.state.documento.dataProgramada)}
                        </p>
                    </div>
                </div>

                <div style={{ borderBottom: '1px dashed black', margin: '10px 0' }}></div>

                <p style={{ fontSize: 12 }}>Dados do cliente</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Nome</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.pessoa.nome}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Documento</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.pessoa.documento}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Email</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.pessoa.emailPlataformaLirida}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Endereço</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.enderecoCobranca.logradouro}, {this.state.enderecoCobranca.numero}, {this.state.enderecoCobranca.complemento}, {this.state.enderecoCobranca.bairro}, {this.state.enderecoCobranca.cidade}, {this.state.enderecoCobranca.estado}, {this.state.enderecoCobranca.pais}, {this.state.enderecoCobranca.uf}
                        </p>
                    </div>
                </div>

                <div style={{ borderBottom: '1px dashed black', margin: '10px 0' }}></div>

                <p style={{ fontSize: 12 }}>Local de serviço</p>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20, marginTop: 10 }}>
                    <div>
                        <p style={{ fontSize: 10 }}>Endereco</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.enderecoEntrega.logradouro}, {this.state.enderecoEntrega.numero}, {this.state.enderecoEntrega.complemento}, {this.state.enderecoEntrega.bairro}, {this.state.enderecoEntrega.cidade}, {this.state.enderecoEntrega.estado}, {this.state.enderecoEntrega.pais}, {this.state.enderecoEntrega.uf}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Nº de convidados</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags2}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Data do evento</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.util.formatarData(this.state.documento.dataProgramada)}
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: 10 }}>Horário do evento</p>
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>
                            {this.state.documento.tags1}
                        </p>
                    </div>
                </div>
            </div>

            <DataTable
                style={{ fontSize: 10, fontWeight: 'bold', marginTop: 10 }}
                showGridlines
                removableSort
                value={this.state.listaDocumentoItem}>
                <Column
                    header="Nome"
                    field="_itemNome" />
                <Column
                    header="Quantidade"
                    body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                        {pDocumentoItem.quantidade}
                    </p>} />
                <Column
                    header="Valor unitário"
                    body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                        {this.util.formatarValor(pDocumentoItem.precoTabelaMaximo)}
                    </p>} />
                <Column
                    header="Valor total do item"
                    body={pDocumentoItem => <p style={{ textAlign: 'right' }}>
                        {this.util.formatarValor(pDocumentoItem.quantidade * pDocumentoItem.precoTabelaMaximo)}
                    </p>} />
            </DataTable>
        </div>
    }
}